import giveAPI from '@/api/give';

// Checkout default data
const getCheckoutDefault = () => ({
  motivos: null,
  flagUsuarioTaxa: false,
  salvarCartao: true,
  allowRecurrency: true,
  recorrenciaDias: 30,
});

export default {
  state: {
    data: {
      subjects: null,
    },
    checkout: getCheckoutDefault(),
    totalValue: 0,
  },

  getters: {
    giveSubjects: (state) => state.data.subjects,
    giveCheckoutData: (state) => state.checkout,
    giveGetTotalValue: (state) => state.totalValue,
  },

  actions: {
    async loadGiveSubjects({ commit, getters }) {
      try {
        const response = await giveAPI.getSubjects(getters.churchData.slug);

        commit('SET_GIVE_SUBJECTS', response.data);

        return Promise.resolve(response);
      } catch (error) {
        commit('SET_GIVE_SUBJECTS', null);

        return Promise.reject(error);
      }
    },

    setGiveCheckoutTotalValue({ commit }, payload) {
      commit('SET_GIVE_CHECKOUT_TOTAL_VALUE', payload);
    },

    setGiveCheckoutSubjects({ commit }, payload) {
      commit('SET_GIVE_CHECKOUT_SUBJECTS', payload);
    },

    setGiveCheckoutAllowRecurrency({ commit }, payload) {
      commit('SET_GIVE_CHECKOUT_RECURRENCY', payload);
    },

    setGiveCheckoutRecurrency({ commit }, payload) {
      commit('SET_GIVE_CHECKOUT_RECURRENCY_DAYS', payload);
    },

    resetGiveCheckout({ commit }) {
      commit('RESET_GIVE_CHECKOUT');
    },
  },

  mutations: {
    SET_GIVE_SUBJECTS(state, payload) {
      state.data.subjects = payload;
    },

    SET_GIVE_CHECKOUT_TOTAL_VALUE(state, payload) {
      state.totalValue = payload;
    },

    SET_GIVE_CHECKOUT_RECURRENCY(state, payload) {
      state.checkout.allowRecurrency = payload;
    },

    SET_GIVE_CHECKOUT_SUBJECTS(state, payload) {
      state.checkout.motivos = payload;
    },

    SET_GIVE_CHECKOUT_RECURRENCY_DAYS(state, payload) {
      state.checkout.recorrenciaDias = payload;
    },

    RESET_GIVE_CHECKOUT(state) {
      Object.assign(state.checkout, getCheckoutDefault());
      state.totalValue = 0;
    },
  },
};
