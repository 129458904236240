module.exports = {
  CONTENT: {
    ABOUT_US: {
      BUTTON: {
        DIRECTIONS: 'Cómo llegar allá',
      },
      TITLE: {
        INTERNAL: {
          ABOUT: 'Acerca de nosotros',
          CHURCH: 'La Iglesia',
          CONTACT: 'Contacto',
          LOCATION: 'Localización',
          SOCIAL: 'Medios de comunicación social',
        },
        PAGE: {
          HOME: 'Acerca de nosotros',
        },
      },
    },
    ACCESS: {
      BUTTON: {
        LOGIN: 'Acceder',
        NEXT: 'Próximo',
        RECOVER_PASSWORD: 'Esqueci minha senha',
        REGISTER: 'Registrarme',
      },
      MESSAGE: {
        ERROR: {
          INACTIVE_ACCOUNT: 'Você já possuí conta, mas não conseguimos enviar o email de ativação, favor tentar novamente.',
        },
        SUCCESS: {
          INACTIVE_ACCOUNT: 'Você já possuí conta, mas ela não está ativa. Estamos enviando um email de ativação para você.',
          RECOVER_PASSWORD: 'Enviamos um email de recuperação de senha para você.',
        },
      },
      MODAL: {
        ACTIVE_ACCOUNT: {
          ACTION: 'Entendí',
          DESCRIPTION: 'La activación de la cuenta se ha enviado a su correo electrónico registrado.',
          TITLE: 'Activa tu <span class="title__featured">cuenta InPeace</span>',
        },
        INPEACE_ACCOUNT: {
          ACTIVATED: {
            LINE_ONE: 'Verificamos que ya tiene una <span class="line__featured">cuenta InPeace.</span>',
          },
          AGREE: {
            ACTION: 'Entendí',
            MESSAGE: 'Inicie sesión o recupere su contraseña.',
          },
          ENTRY: 'Mira que guay',
          INFO: {
            LINE_ONE: 'La cuenta InPeace ahora es única.',
            LINE_TWO: 'Con ella tiene acceso a todas nuestras aplicaciones.',
          },
          UNACTIVATED: {
            LINE_ONE: 'Verificamos que ya tiene una <span class="line__featured">cuenta InPeace.</span>',
            LINE_TWO: 'Active su cuenta y complete el registro.',
          },
        },
        RECOVER_PASSWORD: {
          ACTION: 'Entendí',
          TEXT: 'La recuperación de la contraseña se envió a su correo electrónico registrado',
        },
      },
      SUBTITLE: 'Ingrese el correo electrónico para continuar',
      TITLE: {
        ACCESS: '¡Bienvenido!',
        CLIENT_REGISTER: 'Registro Completo',
        INPEACE_REGISTER: 'Registrar',
        PAGE: 'Acessar',
      },
    },
    ALERT_MESSAGE: {
      TITLE: 'Erro',
    },
    CELULAS: {
      INDEX: {
        CELULAS: {
          AVAILABLE: '{groups} disponibles',
          USER: '{groups} de las que soy parte',
        },
        EMPTY: {
          BUTTON: 'Regresso',
          SUBTITLE: 'Quando estiver disponível você poderá visualizar ou solicitar sua participação.',
          TITLE: 'Ainda não há {grupos} aqui',
        },
        FILTERS: {
          INPUT: 'Buscar por nombre de grupo',
          SEARCH: 'Haz tu búsqueda',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
            SEARCH: 'No hay resultados para esta búsqueda',
            TERM: '¿Qué tal probar con otro nombre o palabra clave?',
          },
        },
      },
      MEETINGS: {
        INDEX: {
          EMPTY: {
            SUBTITLE: 'Cuando se agrega una reunión, estará disponible para que la vea.',
            TITLE: 'No hay reuniones aquí todavía',
          },
          START_FINISH_TIME: '{start} hasta {finish}',
          BUTTON: 'MIRA ESTUDIO',
          TAG: 'VER TEMA',
          TITLE: 'Reuniones',
        },
        SINGLE: {
          START_FINISH_TIME: '{start} hasta {finish}',
          TITLE: 'Discusión',
        },
      },
      MODULE_NAME: 'Grupos',
      SINGLE: {
        ACTIONS: {
          BUTTON: 'Participar',
          BUTTONS: {
            CANCEL: 'Cancelar',
            CONFIRM: 'Confirmar',
            MEETINGS: 'Reuniones',
            YES: 'Sí',
          },
          PARTICIPATE: 'Para ver reuniones y tener acceso a otra información, descargue la aplicación',
          REQUEST: 'Su solicitud de participación será enviada al líder. ¿Quieres confirmar?',
          TEXT: 'Acesse a sua conta ou cadastre-se para solicitar sua participação.',
        },
        INFO: {
          ADDRESS: 'Habla a',
          BUTTON: '¿Como llegar?',
          FUNCTION: 'Líder de {group}',
          RESPONSIBLE: 'Responsable',
          REUNION: 'Reunión',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se encontró ninguna celda que coincida.',
            REQUEST: 'No pudimos enviar su solicitud.',
          },
          SUCCESS: 'Su solicitud ha sido enviada con éxito.',
        },
      },
      STUDIES: {
        MODULE_NAME: 'Estudios disponibles',
      },
      SUCCESS: {
        BUTTON: 'Regreso',
        SUBTITLE: 'Agradecemos su interés en participar.',
        TITLE: 'Su solicitud ha sido enviada con éxito.',
      },
    },
    CHAT: {
      BUTTON: {
        ACCESS_LOGIN: 'Entrar / registrarse',
        ACCESS_NO_LOGIN: 'Participar del chat',
        CALL_ACCESS_MODAL: 'Entre para participar',
        DELETE_ALL: 'Eliminar todos',
        DELETE_SINLGE: 'Eliminar',
        MODAL_DELETE_CANCEL: 'Cancelar',
        MODAL_DELETE_CONFIRM: 'Vale',
      },
      FORM: {
        BUTTON: {
          SEND_MESSAGE: 'Enviar',
        },
        INPUT: {
          NEW_MESSAGE: 'Envie un mensaje',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No fue posible enviar su mensaje en este momento.',
          },
        },
      },
      MESSAGES: {
        JOIN: 'Ingresó',
        MOMENT: {
          COMMIT_TO_JESUS: {
            BUTTON: {
              DISMISS: 'Ahora no',
              RAISE_HAND: 'Levantar la mano',
            },
            TEXT: {
              COMMITED: {
                SUBTITLE: 'Vamos a orar con usted.',
                TITLE: 'Hoy usted está comenzando una nueva vida con Jesús!',
              },
              CREATED: {
                SUBTITLE: 'Levante su mano y háganos saber',
                TITLE: 'Doy mi vida por Jesús',
              },
            },
          },
        },
      },
      TEXT: {
        ACCESS_MODAL: 'Debe introducir su nombre para acceder',
        CONNECTING: 'Conectando...',
        MODAL_DELETE_ALL_USER_MESSAGES: '¿Confirmas la eliminación de todos los mensajes del usuário {username}?',
        MODAL_DELETE_SINGLE_MESSAGE: '¿Confirmas la eliminación del mensaje del usuario {username}?',
        NO_MESSAGES: 'Aún no hay mensajes',
      },
      TITLE: {
        ACCESS_MODAL: 'Díganos su nombre',
        MODAL_DELETE_ALL_USER_MESSAGES: 'Eliminar mensajes',
        MODAL_DELETE_SINGLE_MESAGE: 'Eliminar mensaje',
      },
    },
    CHECKOUT: {
      BUTTON: {
        METHOD: {
          ACH: 'ACH',
          BOLETO: 'Factura de pago',
          CREDIT_CARD: 'Tarjeta de Crédito',
          MONEY: 'Efectivo o Transferencia',
          PAYPAL: 'PayPal',
          PICPAY: 'PicPay',
          PIX_MANUAL: 'Pix',
        },
      },
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Algo salió mal. Actualice la página e inténtelo de nuevo.',
        },
        INFO: {
          BR_OPTIONS: 'Los pagos mediante Pix y comprobante bancario solo están disponibles para direcciones en Brasil. Si se encuentra fuera de Brasil, utilice otro método de pago o actualice su dirección registrada.',
        },
      },
      TEXT: {
        CREDIT_CARD: {
          MODAL: {
            BUTTON: {
              CANCEL: 'N0',
              YES: 'SÍ',
            },
            SUBTITLE: 'Si necesita usar la tarjeta nuevamente, deberá volver a registrarla.',
            TITLE: '¿Realmente desea eliminar la tarjeta final {number} ?',
          },
        },
        INSTALLMENTS: {
          SELECT: {
            WITH_INTEREST: 'con interés',
            WITHOUT_INTEREST: 'sin intereses',
          },
          TITLE: 'Selectione el formulario de pago:',
        },
        PAYMENT: {
          PIX: {
            COPY_KEY: 'Copiar',
            EVENT: 'Si no se confirma el pago, no te preocupes. El pedido se cancelará automáticamente.',
            HELP: 'El código solo es válido hasta las {hour} del {day}.',
          },
          PIX_MANUAL: {
            COPY_KEY: 'Copiar',
            KEY_TYPE: {
              CNPJ: 'CNPJ',
              CPF: 'CPF',
              EMAIL: 'Email',
              PHONE: 'Teléfono móvil',
              RANDOM: 'Aleatorio',
            },
            PIX_KEY_TEXT: 'Tecla Pix',
          },
          PROCESSED_BY: 'Procesado por',
        },
        THANK_YOU: {
          DOWNLOAD_APP: '¿Todavía no tiene la aplicación {0}? ¡Descárguela ahora y siga todo lo relacionado con {0}!',
        },
      },
      TITLE: {
        PAGE: {
          HOME: 'Pago',
          THANK_YOU: 'Gracias',
        },
      },
    },
    DEVOTIONAL: {
      EMPTY: {
        BUTTON: 'Volver al principio',
        SUBTITLE: 'Cuando se publica contenido.',
        TITLE: 'No hay publicaciones aquí todavía',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filtrar por categoría',
          INPUT: 'Buscar por título o palabra clave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
            SEARCH_DESCRIPTION: '¿Qué tal probar con otro título o palabra clave?',
            SEARCH_TITLE: 'No hay resultados para esta busca',
          },
        },
      },
      MODULE_NAME: 'Devocionais',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Se produjo un error al recuperar los datos. Inténtalo de nuevo.',
            NOT_FOUND: 'El contenido no existe o no está disponible.',
          },
        },
      },
    },
    EBD: {
      EMPTY: {
        BUTTON: 'Volver al principio',
        SUBTITLE: 'Cuando se publica contenido.',
        TITLE: 'No hay publicaciones aquí todavía',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filtrar por categoría',
          INPUT: 'Buscar por título o palabra clave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
            SEARCH_DESCRIPTION: '¿Qué tal probar con otro título o palabra clave?',
            SEARCH_TITLE: 'No hay resultados para esta busca',
          },
        },
      },
      MODULE_NAME: 'Escuela bíblica dominical',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Se produjo un error al recuperar los datos. Inténtalo de nuevo.',
            NOT_FOUND: 'El contenido no existe o no está disponible.',
          },
        },
      },
    },
    ERROR_PAGE: {
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Se produjo un error al buscar los datos. Por favor, inténtelo de nuevo.',
        },
      },
    },
    EVENT: {
      ALL: {
        BUTTON: {
          BACK_HOME: 'Volver a la página de inicio',
        },
        EMPTY: {
          SUBTITLE: 'Podrá registrarse cuando se agregue un nuevo evento',
          TITLE: 'No hay eventos aquí todavía',
        },
        MESSAGE: {
          ERROR: {
            NO_EVENTS: '{church} no hay eventos por el momento.',
          },
        },
        TEXT: {
          EXCLUSIVE_CONTENT: 'Contenido exclusivo',
        },
        TITLE: {
          INTERNAL: {
            CHECK_OUR_EVENTS: 'Verifique todos nuestros eventos y cursos',
          },
          PAGE: 'Eventos',
        },
      },
      CHECKOUT: {
        BUTTON: {
          PAYMENT: {
            CONFIRM: 'Confirmar',
          },
          THANK_YOU: {
            DOWNLOAD_BOLETO: 'Ver factura de pago',
            OPEN_PICPAY: 'Abrir PicPay',
            SUBSCRIVE_AGAIN: '¿Quieres hacer otro registro?',
            VIEW_MORE: 'Ver más eventos/cursos',
          },
        },
        TEXT: {
          PAYMENT: {
            EXCLUSIVE_CONTENT: 'Ticket con contenido exclusivo',
            FEES_LABEL: '( con honorarios)',
            METHODS: {
              BOLETO: 'Usted recibirá la factura de pago por correo electrónico y su inscripción será efectiva después de que se confirme el pago.',
              DINHEIRO: {
                DAYS_TO_PAY: 'Tienes {days} cantidad de días para realizar el pago del boleto.',
                RULE_MESSAGE: 'Comuníquese con alguien responsable del evento para obtener más información.',
              },
              FREE: 'Este evento es gratuito, pero la entrada sigue siendo necesaria. Para más información, por favor contacte con la Iglesia.',
              PAYPAL: 'Na próxima tela você poderá acessar o PayPal e efetivar o pagamento. Seu ingresso só será válido após a confirmação do pagamento.',
              PICPAY: {
                RULE_MESSAGE: 'Vea más haciendo clic en el botón a continuación, descargue o acceda a la aplicación picpay y finalice su registro.',
              },
              PIX: {
                RULE_MESSAGE: 'Confirme usando el botón de abajo para proceder con su pago.',
              },
            },
            TOTAL_PRICE: 'Total:',
            TICKET_PRICE: 'Precio del boleto:',
            SERVICE_PRICE: 'Tarifa de servicio:',
            PROCESS_PRICE: 'Tarifa de procesamiento:',
            USER: 'Usuario',
          },
          THANK_YOU: {
            EXCLUSIVE_CONTENT: 'Ticket con contenido exclusivo',
            FINAL_MESSAGE: 'Usted puede seguir la información de registro por la aplicación {nombre}.',
            METHODS: {
              BOLETO: {
                em_analise: 'Usted puede acceder a la factura de pago en el enlace de abajo, pero también la recibirá por correo electrónico y su registro será efectivo después de la confirmación del pago.',
                pendente: 'Usted puede acceder a la factura de pago en el enlace de abajo, pero también la recibirá por correo electrónico y su registro será efectivo después de la confirmación del pago.',
              },
              CREDIT_CARD: {
                aprovado: 'Inscripción realizada con éxito.',
                em_aberto: 'Se está procesando el registro. Recibirás un email de confirmación y pronto tus entradas estarán disponibles en nuestra plataforma. ',
                em_analise: 'Se está procesando el registro. Recibirás un email de confirmación y pronto tus entradas estarán disponibles en nuestra plataforma. ',
                pendente: 'Se está procesando el registro. Recibirás un email de confirmación y pronto tus entradas estarán disponibles en nuestra plataforma. ',
              },
              DINHEIRO: {
                aguardando: 'Pagar la tarifa de entrada. Recibirás un email de confirmación y pronto tus entradas estarán disponibles en nuestra plataforma.',
              },
              FREE: {
                SUCCESS: 'Su inscripción fue realizada con éxito y su entrada fue enviada al correo electrónico de registro.',
              },
              PICPAY: {
                aguardando: 'Abrir picpay para finalizar la transacción',
              },
              PIX: {
                pendente: 'Abre la app de tu banco, escanea el Código QR o pega la clave para realizar el pago.',
              },
            },
            PIX: 'Luego de pagar la cuota de inscripción, recibirás un correo electrónico de confirmación y tus boletos estarán disponibles en la plataforma.',
            TICKET_DATA: {
              ADDRESS: 'Ubicación',
              BUYER: 'Comprador',
              DATE: 'Fecha',
              EVENT: 'Evento',
              GET_IN_TOUCH: 'Contáctenos para más información.',
              LOCATION: 'Local',
              TICKET: 'Entrada',
              TICKETS: 'Entradas',
              TIME: 'Hora',
              USER: 'Usuario',
            },
            TITLE: 'Gracias',
          },
        },
        TITLE: {
          INTERNAL: {
            PAYMENT: 'Pago',
            TICKETS: 'Entradas',
          },
        },
      },
      GLOBAL: {
        TEXT: {
          DATE: {
            DIFFERENT: '{start} {monthStart} hasta {end} {monthEnd}',
            SAME: '{start} {monthEnd}',
          },
        },
      },
      QUESTIONS: {
        BUTTON: {
          NEXT: 'Continuar',
        },
        TEXT: {
          STEP: 'Entrada <span class="numbers__number">{actual}/{quantity}</span>',
          TICKET_DATA: 'Introduce el nombre de la persona que usará la entrada.',
        },
        TITLE: {
          INTERNAL: {
            IMPORTANT_INFO: 'Información importante',
            TICKET_DATA: 'Datos de la Entrada',
            TICKET_TITLE: 'Entrada {name}',
          },
        },
      },
      SINGLE: {
        BUTTON: {
          STATUS: {
            CLOSED: 'Indisponible',
            ONLINE: 'Acceder',
            REGISTER: 'Registrar',
            REGISTER_AGAIN: 'Registrar de nuevo',
            UNAVAILABLE: 'Inscripciones no disponibles',
          },
          TICKET_HAS_CONTENT: {
            FALSE: 'Mis Suscripciónes',
            TRUE: 'Ver contenido',
          },
          VIEW_MORE: 'Ver más eventos/cursos',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Se produjo un error en la búsqueda del evento. Por favor, inténtelo de nuevo.',
            NOT_FOUND: 'Este evento no está disponible o la dirección es incorrecta.',
          },
        },
        TEXT: {
          AGREE: 'Estoy de acuerdo con estos términos',
          AVAILABLE_AT: 'Las entradas estarán disponibles a partir del {date}',
          DATE: {
            DIFFERENT: '{start} a {end}',
            SAME: '{start}',
          },
          EXCLUSIVE_CONTENT: 'Contenido exclusivo',
          EXCLUSIVE_CONTENT_INFO: 'Este evento tiene contenido exclusivo que estará disponible en línea.',
          INFO: {
            DATE: 'Fecha',
            LOCATION: 'Ubicación',
            SCHEDULE: 'Horario',
          },
          PAYMENT_METHODS: {
            BOLETO: 'Factura de Pago',
            CREDIT_CARD: 'Tarjeta de cédito',
            DINHEIRO: 'Efectivo o Transferencia',
            PAYPAL: 'PayPal',
            PICPAY: 'PicPay',
            PIX: 'Pix',
            PIX_MANUAL: 'Pix',
            TRANSFERENCIA: 'Transferencia Bancaria',
          },
          PRICE: {
            FREE: 'Gratuito',
            SINGLE: '{initial}',
            TWO: '{initial} a {final}',
          },
          SCHEDULE_TIME: '{start} a {end}',
          SHARE: '{0}',
        },
        TITLE: {
          CONTACT: 'Contáctenos en caso de duda:',
          INTERNAL: {
            CHECK_IT: 'Mira esto también',
            COMPLEMENT_INSTALLMENTS: '{installments} cantidad',
            INSTALLMENTS: 'Pagos de:',
            INSTALLMENTS_OF: '',
            PARTICIPATION_INFO: 'Información de participación',
            PAYMENT_METHODS: {
              TITLE_PLURAL: 'Formas de Pago',
              TITLE_SINGULAR: 'Forma de Pago',
            },
            TICKET_FROM: 'Entradas de',
          },
        },
      },
      TICKETS: {
        BUTTON: {
          NEXT: 'Continuar',
          READ_MORE: 'Ver más',
        },
        TEXT: {
          EXCLUSIVE_CONTENT: 'Ticket con contenido exclusivo',
          FEES: 'Tasas',
          FREE: 'Gratuito',
          PAYMENT_MESSAGE: 'Información de pago en efectivo o transferencia.',
          PRICE: 'Precio',
          QUANTITY: 'CTD',
          STATUS: {
            AVAILABLE_IN: 'Disponibles a partir del {date}',
            AVAILABLE_UNTIL: '(Disponible hasta {date} o agotar)',
            EXPIRED: 'Expirado',
            SOLD_OUT: 'Agotado',
            UNTIL: 'hasta {date}',
            USER_LIMIT: 'Usted ha comprado el máximo permitido para esta entrada.',
          },
          TOTAL_PRICE: 'Total:',
        },
        TITLE: {
          INTERNAL: {
            ATTENTION: 'Atención',
            CHOOSE_EVENT_TICKETS: 'Elija las entradas para el evento',
            CHOOSE_TICKETS: 'Elija las entradas',
            PAYMENT_INFO: 'Información de pago',
          },
          PAGE: 'Entradas',
        },
      },
    },
    FOOTER: {
      ADDRESS: 'Dirección',
      ADMIN_ACCESS: 'Acceso de admin',
      CONTACT: 'Contacto',
      DEVELOPED_BY: 'Desarrollado por',
      FOLLOW_US: 'Síguenos',
      LEGAL_NAME: 'Nombre',
      TERMS: '<a href="{terms}" rel="noreferrer" class="link" target="_blank"> Términos y Condiciones </a> and <a href="{privacy}" rel="noreferrer" class="link" target="_blank">Política de Privacidad</a>.',
    },
    FORM: {
      BUTTON: {
        CANCEL: 'Cancelar',
        DELETE: 'Eliminar',
        EDIT: 'Editar',
        SAVE: 'Guardar',
        SHOW_CARD_LIST: 'Ver mis tarjetas',
        SHOW_MANUAL_CARD: '+ usar otra tarjeta',
      },
      LABEL: {
        ACCOUNT_NUMBER: 'Cuenta bancaria',
        ACCOUNT_TYPE: 'Seleccione el tipo de cuenta',
        ADDRESS: 'Direccion',
        AMOUNT: 'Cantidad',
        ANOTHER_CARD: {
          EVENT: '¿Quieres pagar con otra tarjeta?',
          GIVE: '¿Quieres donar con otra tarjeta?',
        },
        BIRTHDATE: 'Fecha de nacimiento',
        BIRTHDATE_HOLDER: 'Fecha de nacimiento del titular',
        CONGREGATION: 'Congregación',
        CARD_HOLDER: 'Titular de la tarjeta',
        CHILDREN: 'Niños/as',
        CHECKING_ACCOUNT: 'Cuenta Corriente',
        CITY: 'Ciudad',
        COMMENTS: 'Comentarios',
        COMPLEMENT: 'Complemento',
        CONFIRM_PASSWORD: 'Confirmar seña',
        COUNTRY: 'Pais',
        CPF: 'Número de identificación fiscal',
        CPF_HOLDER: 'Número de identificación fiscal del titular',
        CREATE_ACCOUNT: 'Deseja criar uma conta?',
        CREDIT_CARD_NUMBER: 'Número de la tarjeta',
        CVV: 'CVV',
        DESCRIBE_HERE: '¿Qué te gustaría pedir?',
        DUE_DATE: 'Fecha de caducidad',
        EMAIL: 'Correo electrónico',
        FEMALE: 'Femenino',
        FREQUENCY_IN_DAYS: '¿Frecuencia de las transacciones?',
        IMAGE: 'Añadir una imagen',
        MALE: 'Masculino',
        MOTIVE: '¿Cuál es el motivo de su pedido?',
        NAME: 'Nombre',
        NEIGHBORHOOD: 'Vecindario',
        NO: 'NO',
        NO_LOWERCASE: 'No',
        NUMBER: 'Numero',
        NUMBER_OF_CHILDREN: 'Cantidad de Niños',
        PASSWORD: 'Contraseña',
        PHONE: 'Teléfono',
        PRAYER_NAME: '¿Cual es su nombre?',
        RECURRENCY: '¿Transacción recurrente?',
        ROUTING_NUMBER: 'Cuenta de enrutamiento',
        SAVE_CARD: '¿Guardar tarjeta?',
        SAVINGS_ACCOUNT: 'Cuenta de Ahorros',
        SELECT_CARD: 'Elige la tarjeta que quieres usar',
        SEX: 'Sexo',
        SURNAME: 'Apellido',
        STATE: 'Estado',
        STREET: 'Nombre de la calle',
        VALUE: 'Precio',
        YES: 'SÍ',
        YES_LOWERCASE: 'Sí',
        YOU_ARE_CARD_HOLDER: '¿Eres el titular de la tarjeta ?',
        MARITAL_STATUS: 'Estado civil',
        MARITAL_DATE: 'Fecha de boda',
        SEX_MEN: 'Masculino',
        SEX_WOMEN: 'Feminino',
        OCUPATION: 'Ocupación',
        ZIPCODE: 'Codigo Postal',
        ZIPCODE_FOR_ALL: 'Zipcode',
      },
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Se produjo un error al intentar enviar los datos. Inténtelo de nuevo.',
        },
      },
      PLACEHOLDER: {
        ACCEPTANCE_TERM_FIRST: 'Conozco y estoy de acuerdo con la',
        ACCEPTANCE_TERM_SECOND: 'y los',
        ACCOUNT_NUMBER: 'Número de cuenta bancaria',
        ACCOUNT_TYPE: 'Tipo de cuenta',
        ADDRESS: 'Informa tu calle',
        AMOUNT: 'Cantidad',
        BIRTHDATE: 'DD/MM/AAAA',
        BIRTHDATE_HOLDER: 'DD/MM/AAAA',
        COMMENTS: 'Alergias, cuidados especiales, tipo de sangre, etc.',
        CONGREGATION: 'Seleccione su congregación',
        CARD_HOLDER: 'Nombre impreso en tarjeta',
        CITY: 'Escriba su ciudad',
        COMPLEMENT: 'Bloque, Apt, punto de referencia',
        CONFIRM_PASSWORD: 'Ingresa la contraseña nuevamente',
        COUNTRY: 'Seleccione un pais',
        CPF: 'Número de identificación fiscal',
        CPF_HOLDER: 'Número de identificación fiscal del titular',
        CREATE_ACCOUNT: 'Deseja criar uma conta?',
        CREDIT_CARD_NUMBER: 'Ingrese el número de tarjeta',
        CVV: 'Código de seguridad',
        DESCRIBE_HERE: 'Ingrese el mensaje aqui',
        DUE_DATE: 'MM/AA',
        DUE_DATE_SIX_DIGITS: 'MM/AAAA',
        EMAIL: 'Ingresa tu correo electrónico',
        ENTER_NAME: 'Ingresa tu nombre',
        FEMALE: 'Femenino',
        FREQUENCY_IN_DAYS: 'Frecuencia en días',
        IMAGE: 'Agregar',
        MALE: 'Masculino',
        MOTIVE: 'Razón',
        NAME: 'por ej: María',
        NEIGHBORHOOD: 'Ingrese su vecindario',
        NO: 'No',
        NUMBER: 'Informa el numero',
        PASSWORD: 'Ingresa la contraseña',
        PHONE: 'Teléfono',
        PRIVACY_POLICY: 'política de privacidad',
        RECURRENCY: '¿Pagar de forma recurrente?',
        ROUTING_NUMBER: 'Número de ruta',
        SAVE_CARD: '¿Ahorre crédito?',
        SELECT_CARD: 'Elija la tarjeta',
        SEX: 'Sexo',
        STATE: 'Escriba su estado',
        SURNAME: 'por ej: Gonzales',
        TERMS_OF_SERVICE: 'términos de servicio',
        VALUE: 'Precio',
        YES: 'Sí',
        YOU_ARE_CARD_HOLDER: '¿Eres el titular de la tarjeta ?',
        MARITAL_STATUS: 'Seleccione su estado civil',
        OCUPATION: 'Seleccione su ocupación',
      },
      VALIDATION: {
        CARD_TOKEN_FAIL: 'Algunos datos de la tarjeta son incorrectos. Por favor, compruébelo.',
        CPF: 'Número de identificación fiscal no válido.',
        PHONE: 'He comprobado el número de teléfono.',
        REQUIRED: 'Este campo es obligatorio.',
        ZIPCODE: 'Código postal no válido',
      },
      MARITAL_STATUS: {
        MARIED: 'Casado(a)',
        CIVIL_MARIED: 'Casado civil',
        CIVIL_RELIGIOUS: 'Casados ​​civiles y religiosos',
        DIVORCED: 'Divorciado(a)',
        LIVE_TOGETHER: 'Vivir juntos',
        SINGLE: 'Único(a)',
        STABLE: 'Unión estable',
        WIDOWER: 'Viudo(a)',
      },
    },
    GIVE: {
      CHECKOUT: {
        BUTTON: {
          PAYMENT: {
            CONFIRM: 'Confirmar',
          },
          THANK_YOU: {
            DONATE_AGAIN: '¿Desea hacer otra donación?',
            DOWNLOAD_BOLETO: 'Ver factura de pago',
            OPEN_PICPAY: 'Abrir PicPay',
            VIEW_MORE: 'Ver más opciones de donación',
          },
        },
        FORM: {
          ACH: {
            TITLE: 'Añadir información bancaria:',
          },
          VALIDATION: {
            MIN_VALUE: 'El valor es obligatorio',
            PICPAY: {
              MAX_VALUE: 'El valor máximo con PicPay es de R$ 4.999,99.<br> Usted puede hacer varias donaciones con este límite.',
            },
          },
        },
        MESSAGE: {
          GENERIC_ERROR: 'Algo salió mal. Actualice la página e inténtelo de nuevo.',
        },
        TEXT: {
          PAYMENT: {
            CHOOSE_AMOUNT: '¿Con cuánto desea ayudar?',
            FEES: 'Colaborar con {0} de tasas de la transacción',
            METHODS: {
              BOLETO: 'Usted recibirá la factura de pago por correo electrónico y su donación será efectiva después de que se confirme el pago.',
              PICPAY: {
                RULE_MESSAGE: 'Confirme el botón a continuación, descargue o acceda a su aplicación PicPay y complete su donación.',
              },
              PIX: {
                RULE_MESSAGE: 'Confirme usando el botón de abajo para proceder con su pago.',
              },
              PIX_MANUAL: 'Copie la clave pix a continuación para donar a través de la aplicación de su banco.',
            },
          },
          THANK_YOU: {
            ACH: {
              aprovado: 'Su donación fue recibida con éxito por {0}',
              nao_aprovado: 'Su donación a {0} ha sido enviada y será confirmada por su banco. Recibirá un correo con mas información',
            },
            BOLETO: {
              em_analise: 'Usted puede acceder a la factura de pago en el enlace de abajo, pero también la recibirá por correo electrónico y su donación será efectiva después de la confirmación del pago.',
              pendente: 'Usted puede acceder a la factura de pago en el enlace de abajo, pero también la recibirá por correo electrónico y su donación será efectiva después de la confirmación del pago.',
            },
            CREDIT_CARD: {
              aprovado: 'Su donación fue recibida con éxito por {0}',
              em_aberto: 'Su donación hacia {0} está bajo análisis. Recibirá un correo electrónico para obtener más información. ',
              em_analise: 'Su donación hacia {0} está bajo análisis. Recibirá un correo electrónico para obtener más información. ',
              pendente: 'Su donación hacia {0} está bajo análisis. Recibirá un correo electrónico para obtener más información. ',
            },
            PICPAY: {
              aguardando: 'Haga clic abajo para abrir el PicPay y completar su donación.',
            },
            PIX: {
              pendente: 'Abre la app de tu banco, escanea la imagen o pega el Código QR para realizar el pago.',
            },
            TITLE: 'Gracias por su registro',
          },
        },
        TITLE: {
          INTERNAL: {
            CHECKOUT: 'Usted está ayudando {0} con {1}',
          },
        },
      },
      HOME: {
        BUTTON: {
          NEXT: 'Continuar',
          NEW_REASON: 'Otra donación',
          TOTAL: 'Total:',
        },
        MESSAGE: {
          ERROR: {
            GENERIC_ERROR: 'Algo salió mal. Actualice la página e inténtelo de nuevo.',
            NO_GIVE: 'No hay opciones de donación por el momento.',
            NO_MODULE: 'Por el momento esta iglesia no acepta donaciones a través de Internet.',
            MIN_VALUE: 'El monto de la donación debe ser mayor a {minValue}',
          },
        },
        TEXT: {
          CHOOSE_AMOUNT: '¿Cuánto quieres donar?',
          CHOOSE_METHOD: '¿Cuál es el motivo de la donación?',
          CHOOSE_OPTION: 'Selecciona el motivo',
          EDIT: 'Editar Valor',
        },
        TITLE: {
          INTERNAL: {
            GIVE: 'Donación',
          },
          PAGE: 'Donación',
        },
      },
      RECURRENCY: {
        BUTTON: {
          NEXT: 'Próximo',
        },
        TEXT: {
          DAYS: 'días',
          INFO: 'Se le cobrará en:',
          OBS1: '* Se permitirán pagos recurrentes para solo una opción. Si agrega más, se desactivará.',
          OBS2: '* La opción de  activar la recurrencia solo estará disponible por motivos elegidos por la iglesia.',
        },
        TITLE: {
          INTERNAL: {
            RECURRENCY: 'Recurrencia',
          },
          PAGE: 'Recurrencia de la Donación',
        },
      },
    },
    GLOBAL: {
      ERROR: {
        GENERIC_ERROR: 'Se produjo un error al buscar los datos. Por favor, inténtelo de nuevo.',
      },
      MODAL: {
        BUTTON: {
          CANCEL: 'Cancelar',
          OKAY: 'Ok',
        },
      },
      OFFLINE: {
        MESSAGE: 'Usted no está conectado a internet. Compruebe su conexión para seguir usando la aplicación',
      },
      TEXT: {
        COPY_RIGHTS: 'All Rights Reserved',
      },
    },
    HEADER: {
      ACCESS_TEXT: 'Hola, inicie sesión en su cuenta o regístrese.',
      LINKS: {
        MORE: 'Más',
      },
      LOGIN: 'Iniciar',
      MENU: {
        BACK: 'Regreso',
        HOME: 'Inicio',
        INPEACE_PLAY: 'Play',
        INSCRIPTIONS: 'Registros',
        LOGOUT: 'Fuera',
        PROFILE: 'Mi cuenta',
      },
      NOTIFICATIONS: {
        LAST_UPDATE: '{day} a las {hour}',
        TITLE: 'Notificaciones',
        MODAL: {
          TEXT: 'Para acceder más información, deberá cambiar la iglesia. ¿Quieres hacer el cambio ahora?',
          BUTTON: 'Cambiar',
        },
      },
      REGISTER: 'Registrar',
      SALUTATION: 'Hola',
    },
    HOME: {
      BANNER: {
        TITLE: 'Bienvenido',
      },
      CTA: {
        SUB_TITLE: 'Y llévanos a cualquier parte',
        TITLE: 'Descarga el app',
        TITLE_MOBILE: 'Descarga el app y llévanos a cualquier parte',
      },
      EVENTS: {
        BUTTON: 'Ver próximos eventos',
        DATE: {
          DAY: {
            DIFFERENT: '{start} {monthStart} hasta {end} {monthEnd}',
            SAME: '{start} {monthEnd}',
          },
        },
        SUBTITLE: 'Próximos',
        TEXT: 'Los eventos ayudan en la misión de fortalecer nuestra fe cada día y reconectarnos con Dios.',
        TITLE: 'Nuetros Eventos',
      },
      GIVING: {
        BUTTON: 'Haga una donación',
        TEXT: 'La donación es una forma de agradecer a Dios por todas las bendiciones en nuestras vidas. Y es también como plantamos la semilla en busca de alguna gracia específica.',
        TITLE: 'Donaciones',
      },
      MESSAGE: {
        ERROR: {
          GENERIC: 'Se produjo un error al buscar los datos. Por favor, actualice la página.',
        },
      },
      MODULES: {
        ABOUT: 'Acerca de nosotros',
        EVENTS: 'Eventos',
        GIVE: 'Donación',
        LIVE: 'Programación',
        SUBSCRIPTION: 'Play',
      },
      PRAYER: {
        BUTTON: 'Haga un pedido de oracion',
        TEXT: 'La oración sincera es la forma más pura de conversar con Dios. Abre tu corazón, comparte tu pedido de oración y ora por otros en nuestro muro virtual.',
        TITLE: 'Muro de Oraciones',
      },
      SCHEDULE: {
        BUTTON: 'Ver transmisiones en vivo',
        CARD: {
          VIEW_LESS: 'Ver menos',
          VIEW_MORE: 'Ver más',
        },
        DAYS: {
          FRIDAY: 'Viernes',
          MONDAY: 'Lunes',
          SATURDAY: 'Sábado',
          SUNDAY: 'Domingo',
          THURSDAY: 'Jueves',
          TUESDAY: 'Martes',
          WEDNESDAY: 'miércoles',
        },
        SUBTITLE: 'Programación',
        TEXT: 'Consulta nuestra agenda presencial y sigue también las transmisiones en vivo desde donde estés usando internet. Vea el horario a continuación:',
        TITLE: 'Contigo en cualquer lugar',
      },
      TITLE: {
        PAGE: {
          HOME: 'Inicio',
        },
      },
    },
    INPEACE_PLAY: {
      BROWSE: {
        MESSAGE: {
          NO_CONTENT: 'Aún no hay contenido para mostrar',
        },
      },
      BUTTON: {
        ADD_MY_LIST: 'Mi lista',
      },
      CHECKOUT: {
        BUTTON: {
          GO_TO_CONTENT: 'Ver los videos',
          PAYMENT: {
            CONFIRM: 'Confirmar',
          },
          THANK_YOU: {
            DOWNLOAD_BOLETO: 'Ver comprobante de pago',
            OPEN_PICPAY: 'Abrir PicPay',
            SUBSCRIVE_AGAIN: 'Deseja fazer outra inscrição?',
            VIEW_MORE: 'Ver más eventos / cursos',
          },
        },
        TEXT: {
          PAYMENT: {
            METHODS: {
              BOLETO: 'Recibirá el comprobante bancario por correo electrónico y su suscripción será efectiva después de la confirmación del pago.',
              FREE: 'Esta suscripción es gratuita, tendrás acceso a todos los contenidos incluidos en la misma sin coste alguno.',
              PICPAY: {
                RULE_MESSAGE: 'Confirme el botón a continuación, descargue o acceda a su aplicación PicPay y complete su donación.',
              },
            },
            PAYMENT: 'Pago',
            PLAN: 'Plan',
            PRICE: 'Precio',
            SUBSCRIPTION_FEE: '+ {value} (tarifa de contratación)',
            TOTAL: 'Total',
          },
          THANK_YOU: {
            DATA: {
              PLAN: 'Plan',
              STATUS: 'Situación',
              SUBSCRIBED: 'Firma',
            },
            METHODS: {
              BOLETO: {
                em_analise: 'Puede acceder al recibo bancario en el enlace a continuación, pero también lo recibirá por correo electrónico y su suscripción será efectiva después de la confirmación del pago.',
                pendente: 'Puede acceder al recibo bancario en el enlace a continuación, pero también lo recibirá por correo electrónico y su suscripción será efectiva después de la confirmación del pago.',
              },
              CREDIT_CARD: {
                aprovado: 'Su suscripción fue exitosa.',
                em_aberto: 'Su suscripción está en análisis. Recibirás un email de confirmación y pronto tus entradas estarán disponibles en nuestra plataforma. ',
                em_analise: 'Su suscripción está en análisis. Recibirás un email de confirmación y pronto tus entradas estarán disponibles en nuestra plataforma. ',
                pendente: 'Su suscripción está en análisis. Recibirás un email de confirmación y pronto tus entradas estarán disponibles en nuestra plataforma. ',
              },
              FREE: {
                SUCCESS: 'Su suscripción fue exitosa.',
              },
              PICPAY: {
                aguardando: 'Haga clic a continuación para abrir PicPay y completar su suscripción.',
              },
            },
            TITLE: 'Gracias por su donación',
          },
        },
      },
      COMMON: {
        COLLECTIONS: 'Colecciones',
        CONTENT_POSTER: {
          COMING_SOON: 'Pronto',
        },
        HOME: 'Comienzo',
        MY_LIST: 'Mi lista',
        QUERY: 'RESULTADO DE BÚSQUEDA:',
        RELATED_CONTENT: {
          TITLE: 'RELACIONADO',
        },
        TAG: 'Etiqueta: ',
        VIDEOS: 'Videos',
      },
      CONTENT_ACTIONS: {
        BUTTON: {
          SUBSCRIBE_NOW: 'Suscríbase ahora',
          WATCH: 'Reloj',
        },
      },
      HOME: {
        CLOSED_CONTENT: {
          BUTTON: {
            SUBSCRIBE_NOW: 'Suscríbase ahora',
          },
        },
      },
      MODULE_NAME: 'Play',
      PLANS: {
        MESSAGE: {
          ERROR: 'Hubo un error al obtener los datos',
          NO_CONTENT: 'No hay planes disponibles por el momento',
        },
        MY_PLANS: {
          PLAN: 'Plan',
          SUBSCRIBED_AT: 'Fecha de firma',
          SUBSCRIPTION_CODE: 'Código de firma',
          TITLE: 'Mi plan',
        },
        PLANS_LIST: {
          BUTTON_NEXT: 'Suscríbase ahora',
          FEES: 'Tarifa',
          PRICE: 'Precio',
          TITLE: 'Elige tu plan',
        },
        TITLE: 'Planes',
      },
      SEARCH: {
        MESSAGE: {
          INVALID_SEARCH: 'No pudimos encontrar ningún resultado para su búsqueda',
          NO_CONTENT: 'No pudimos encontrar ningún resultado para su búsqueda',
          REQUEST_ERROR: 'Hubo un error al obtener los datos.',
        },
      },
      SINGLE: {
        MESSAGE: {
          NO_COLLECTION_CONTENT: 'Aún no hay contenido para mostrar',
          NO_COLLECTION_ID: 'Hubo un error, por favor intente de nuevo',
          NO_CONTENT: 'No se encontró el contenido que buscaba',
          REQUEST_ERROR: 'Se produjo un error al intentar recuperar los datos. Vuelva a intentarlo',
        },
        TEXT: {
          TAGS: 'Etiquetas',
          VIDEOS: 'Videos',
        },
      },
      WATCH: {
        MESSAGE: {
          COMING_SOON: 'Contenido no disponible',
          NO_CONTENT: 'Contenido no encontrado o no disponible',
          REQUEST_ERROR: 'Se produjo un error al obtener los datos. Vuelve a intentarlo',
        },
      },
    },
    LIVE: {
      BUTTON: {
        BIBLE: 'Biblia',
        DONATE_NOW: 'Hacer una donación',
        GIVE: 'Donaciones',
        LIVE_CHAT: 'Chat',
        NOTE: 'Notas',
        UPCOMING_STREAMS: 'Próximas transmisiones',
      },
      MESSAGE: {
        ERROR: {
          GENERIC: 'Se produjo un error en la búsqueda del evento. Por favor, inténtelo de nuevo.',
          NO_MODULE: '{0} no hay transmisión en vivo',
        },
      },
      TEXT: {
        GIVE: {
          REDIRECT: 'Irás a la pantalla de donaciones',
          REDIRECT_MESSAGE: 'Para no interrumpir la transmisión, vaya a la opción de donación en la página de inicio en cualquier momento',
        },
        NO_WORSHIP_LIVE: 'Pronto tendremos nuestra primera transmision en vivo',
        NO_WORSHIP_SCHEDULE: 'Todavía no hay transmisiones programadas',
        NOT_BROADCASTED: '{0} no ha hecho todavía una transmisión',
        SHARE: {
          LIVE: '{0}',
          UPCOMING: '{0} - {1}',
        },
        TAG_STATUS: {
          LAST: 'Última transmisión',
          LIVE: 'En Vivo',
        },
      },
      TITLE: {
        PAGE: {
          HOME: 'Programación',
        },
      },
    },
    LOGIN: {
      BUTTON: {
        LOGIN: 'Iniciar sesión',
        LOST_PASSWORD: 'Olvidé mi contraseña',
        REGISTER: '¿TODAVÍA NO TIENE CUENTA? REGÍSTRESE.',
      },
      FORM: {
        MESSAGE: {
          ERROR: 'Nombre de usuario o contraseña inválidos.',
        },
      },
      SUBTITLE: 'Ingrese a su cuenta',
      TITLE: {
        INTERNAL: {
          LOGIN: 'Iniciar sesión',
          MUST_LOGIN: 'Usted debe estar conectado para continuar.',
        },
        PAGE: {
          HOME: 'Iniciar sesión',
        },
      },
    },
    MEMBER_CARD: {
      CARD: {
        AVATAR: 'Foto de {memberFullName}.',
        BAPTISM_DATE: 'Bautismo',
        BIRTH_DATE: 'Nacimiento',
        CONGREGATION: 'Congregación',
        CPF: 'CPF',
        FEMALE: 'Femenino',
        FOOTER: 'En caso de duda, contáctenos',
        FULL_NAME: '{firstName} {lastName}',
        GENDER: 'Sexo',
        LINKED: 'Vinculado a',
        LOGO: 'Logotipo de la iglesia {churchName}',
        MALE: 'Masculino',
        MARITAL_STATUS: 'Estado civil',
        NAME: 'Nombre',
        PHONE: 'Teléfono',
        POSITION: 'Actividad',
        QR_CODE: 'Código QR de {memberFullName}.',
        RESPONSIBLE: 'Responsable',
        WIDOWED: 'Viudo(a)',
      },
      PRINT: {
        ACTION: 'Imprimir',
        ERROR_MESSAGE: 'No se encontró ninguna tarjeta para este correo electrónico.',
        NOT_FOUND_CARD: 'No fue posible obtener los detalles de la tarjeta.',
      },
    },
    MINISTRIES: {
      CARD: {
        COORDINATION: 'Coordinación',
      },
      EMPTY: {
        BUTTON: 'Volver al principio',
        SUBTITLE: 'Cuando se cree un ministerio, podrá ver la información.',
        TITLE: 'No hay ministerios aquí todavía',
      },
      INDEX: {
        FILTERS: {
          INPUT: 'Buscar por título o palabra clave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
            SEARCH_DESCRIPTION: '¿Qué tal probar con otro título o palabra clave?',
            SEARCH_TITLE: 'No hay resultados para esta busca',
          },
        },
      },
      MODULE_NAME: 'Coordinación',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Se produjo un error al recuperar los datos. Inténtalo de nuevo.',
            NOT_FOUND: 'El contenido no existe o no está disponible.',
          },
        },
        INFO: {
          PROJECT: 'Proyectos sociales',
          RESPONSIBLE: 'Responsable',
        },
      },
    },
    NEWS: {
      EMPTY: {
        BUTTON: 'Volver al principio',
        SUBTITLE: 'Cuando se publica contenido, puede verlo aquí.',
        TITLE: 'No hay publicaciones aquí todavía',
      },
      INDEX: {
        FILTERS: {
          INPUT: 'Buscar por título o palabra clave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
            SEARCH_DESCRIPTION: '¿Qué tal probar con otro título o palabra clave?',
            SEARCH_TITLE: 'No hay resultados para esta busca',
          },
        },
      },
      MODULE_NAME: 'Notícias',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Se produjo un error al recuperar los datos. Inténtalo de nuevo.',
            NOT_FOUND: 'El contenido no existe o no está disponible.',
          },
        },
      },
    },
    ORGANIZATION_HEADER: {
      BAR: {
        CHANGE: 'Cambio',
      },
      MODAL: {
        CLIENT_INFO: {
          BACK: 'Cancelar',
          CONFIRM: 'Confirmar',
        },
        CLIENTS: {
          NO_CLIENTS: 'No pudimos ubicar iglesias en esa organización.',
          NO_RESULT: 'No hemos encontrado ninguna iglesia para su búsqueda',
          PLACEHOLDER: 'Buscar iglesia por nombre o dirección',
          SELECT_CLIENT: 'Seleccione una iglesia',
        },
        LOCATION: {
          ALLOW: 'Permitir ubicación',
          ENTRY: 'Permita que su ubicación encuentre las iglesias más cercanas a usted',
          NOT_NOW: 'Ahora no',
        },
      },
    },
    PLAYER: {
      AUDIO: {
        CONTROLS: {
          NEXT: 'Próximo',
          PAUSE: 'Detener',
          PLAY: 'Tocar',
          PREV: 'Anterior',
          REPEAT: 'Repetir',
          SHUFFLE: 'Aleatorio',
        },
      },
    },
    PODCAST: {
      EMPTY: {
        BUTTON: 'Volver al principio',
        SUBTITLE: 'Cuando se publica contenido.',
        TITLE: 'No hay podcasts aquí todavía',
      },
      INDEX: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
            SEARCH_DESCRIPTION: '¿Qué tal probar con otro título o palabra clave?',
            SEARCH_TITLE: 'No hay resultados para esta busca',
          },
          VIEW_MORE: 'Ver más',
        },
        TEXT: {
          EPISODES: 'Episodios',
          PUBLISHED: 'Publicado:',
          QUEUE: 'Fila',
          SHARE: 'CUOTA',
        },
      },
      MODULE_NAME: 'Podcast',
    },
    PRAISE: {
      CHORD: {
        TONE: 'Tono',
      },
      CONTROLS: {
        AUTOMATIC_SCROLLING: 'Desplazamiento automático',
        FONT: 'Fuente',
      },
      ERROR: {
        ERROR_MESSAGE: 'No se pudo obtener la información de esta canción.',
      },
      SCORE: {
        PAGE: 'Pagina {pageNumber} de la partitura',
      },
      SHOW_TYPES: {
        CIPHER: 'Cifrar',
        LYRIC: 'Carta',
        SCORE: 'Partitura',
      },
    },
    PRAYER: {
      DELETE: {
        BUTTON: 'Eliminar solicitud',
        CANCEL: 'Volver al principio',
        SUBTITLE: 'La eliminación de una solicitud no se puede revertir.',
        SUCCESS: {
          BUTTON: 'Haga un pedido de oracion',
          BUTTON_RETURN: 'Volver al principio',
          SUBTITLE: '¿Quieres hacer un nuevo pedido?',
          TITLE: 'Su pedido ha sido eliminado con éxito',
        },
        TITLE: '¿Realmente desea eliminar su solicitud?',
      },
      INDEX: {
        BUTTONS: {
          REQUEST: 'Hacer mi pedido',
          SEND: 'Confirmar',
          SEND_MODAL: 'Enviar',
        },
        EMPTY: {
          BUTTON: 'Hacer un pedido',
          SUBTITLE: 'No hay solicitudes publicadas',
          TEXT: 'Cuando se publique un pedido en el muro, aparecerá aquí.',
          TITLE: 'Muro de Oraciones',
        },
        FILTERS: {
          DROPDOWN: 'Filtrar por motivo',
          INPUT: 'Buscar por nombre del autor o mensaje',
        },
        MESSAGE: {
          BACK_TOP: 'Volver a la cima',
          EMPTY: 'Todavía no hay oraciones.',
          ERROR: {
            GENERIC: 'No se encontró contenido.',
            SEARCH: 'No hay resultados para esta búsqueda',
            SEARCH_MESSAGE: '¿Qué tal probar con otro nombre o palabra clave? ',
          },
          REQUEST: 'Haz tu petición de oración',
          SEND_MESSAGE: 'Deja su apoyo en este orden',
          VIEW_MORE: 'Ver más',
        },
        TITLE: 'Muro de Oraciones',
      },
      MODULE_NAME: 'Prayer',
      NEW: {
        ERROR: 'No fue posible llevar a cabo la solicitud de oración, inténtelo de nuevo.',
        PUBLISH: '¿Le gustaría que su solicitud fuera publicada en la pared?',
        CALL: '¿Quieres recibir una llamada de un miembro?',
        VISIT: '¿Quieres recibir la visita de un miembro?',
        SELECT: 'Elige el motivo',
        SUBTITLE: 'Complete los campos a continuación y envíenos su solicitud de oración',
        SUCCESS: {
          BUTTON: 'Hacer otro pedido',
          BUTTON_RETURN: 'Regresso',
          SUBTITLE: 'Su solicitud fue enviada con éxito',
          THANK_YOU: 'Gracias por enviar su pedido',
          TITLE: 'Hacer pedido',
        },
        TITLE: 'Petición',
      },
      SINGLE: {
        MESSAGE: {
          EMPTY: 'Aún no hay mensajes.',
          ERROR: {
            GENERIC: 'Se produjo un error al recuperar los datos. Inténtalo de nuevo.',
            NOT_FOUND: 'Ningún resultado encontrado.',
          },
          PRAY: 'Orando',
          SEND: 'Enviar Mensaje',
          TITLE: 'Mensajes',
        },
      },
    },
    PUBLICATION: {
      EMPTY: {
        BUTTON: 'Volver al principio',
        SUBTITLE: 'Cuando se publica contenido.',
        TITLE: 'No hay publicaciones aquí todavía',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filtrar por categoría',
          INPUT: 'Buscar por título o palabra clave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
            SEARCH_DESCRIPTION: '¿Qué tal probar con otro título o palabra clave?',
            SEARCH_TITLE: 'No hay resultados para esta busca',
          },
        },
        TEXT: {
          SELECT_TYPE: 'Seleccione el tipo de publicación a la que desea acceder:',
        },
      },
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Se produjo un error al recuperar los datos. Inténtalo de nuevo.',
            NOT_FOUND: 'El contenido no existe o no está disponible.',
          },
        },
      },
      MODULE_NAME: 'Publicaciones',
    },
    PUBLICATION_TYPE: {
      EMPTY: {
        BUTTON: 'Volver a Inicio',
        SUBTITLE: 'Cuando se agregan nuevos , podras verlos aquí.',
        TITLE: 'Aún no hay tipos de publicaciones registradas',
      },
    },
    RECAPTCHA: {
      TEXT: 'Captcha inválido. Inténtalo de nuevo.',
    },
    REGISTER: {
      BUTTON: {
        SEND: 'Confirmar Registro',
      },
      PASSWORD_MESSAGEM: {
        CONFIRMED: 'Las contraseñas deben ser las mismas',
      },
      PASSWORD_REQUIREMENTS: {
        REQUIREMENT_1: 'Mínimo 8 caracteres',
        REQUIREMENT_2: 'Letras mayúsculas y minúsculas',
        REQUIREMENT_3: 'Número',
        REQUIREMENT_4: 'Características especiales (Ej: !@#$%*)',
        TITLE: 'Requisitos de contraseña:',
      },
      SUBTITLE: 'Registrarse',
      TITLE: {
        INTERNAL: {
          FILL_INFO: 'Rellene la información de registro.',
          REGISTER: 'Registrar',
        },
        PAGE: {
          HOME: 'Registrar',
        },
      },
      TOOLTIP: {
        PASSWORD_HIDE: 'Oculta contraseña',
        PASSWORD_SHOW: 'Mostrar contraseña',
      },
    },
    REQUEST_LOGIN: {
      BUTTONS: {
        CANCEL: 'Cancelar',
        CONFIRM: 'Iniciar sesión / Registrarse',
      },
      MESSAGE: {
        LOGIN: 'Inicie sesión en su cuenta o regístrese para continuar.',
      },
    },
    RESET_PASSWORD: {
      BUTTON: {
        SEND: 'Recuperar',
      },
      FORM: {
        MESSAGE: {
          ERROR: {
            GENERIC_ERROR: 'Se produjo un error al intentar enviar los datos. Inténtelo de nuevo.',
            NOT_FOUND: 'Correo electrónico no encontrado.',
          },
          SUCCESS: 'Nueva contraseña enviada a su correo electrónico.',
        },
      },
      TITLE: {
        INTERNAL: {
          ENTER_EMAIL: 'Introduce su dirección de correo electrónico para recuperar su contraseña',
          RECOVER_PASSWORD: 'Recuperar Contraseña',
        },
        PAGE: {
          HOME: 'Recuperar Contraseña',
        },
      },
    },
    USER: {
      ACCOUNT: {
        PROFILE: {
          LINKS: {
            CREDIT_CARD: {
              SUBTITLE: 'Agregar o eliminar tarjetas',
              TITLE: 'Tarjetas',
            },
            DELETE_USER: 'Cancelar mi cuenta',
            EVENTS_INSCRIPTIONS: {
              SUBTITLE: 'Ver información de boletos',
              TITLE: 'Registros',
            },
            LOGOUT: 'Fuera',
            MY_ACCOUNT: {
              SUBTITLE: 'Confirma, añade o cambia tus datos registrados',
              TITLE: 'Datos de registro',
              LABEL_INCOMPLETE: 'INCOMPLETO',
            },
            NOTES: {
              SUBTITLE: 'Ver, crear o eliminar sus notas',
              TITLE: 'Notas',
            },
            PRAYER: {
              SUBTITLE: 'Ver, compartir o eliminar sus pedidos',
              TITLE: 'Órdenes de pared',
            },
            SUBSCRIPTIONS: {
              TITLE: 'Play',
            },
          },
          TITLE: {
            ENTRY: 'Mi cuenta',
            PAGE: 'Mi cuenta',
          },
        },
        UPDATE: {
          BUTTON: {
            SEND: 'Completar mi registro',
          },
          TITLE: {
            INTERNAL: {
              FINISH_REGISTRATION: 'Termine su registro para continuar.',
              UPDATE: 'Mi cuenta',
            },
            PAGE: 'Mi cuenta',
          },
        },
        UPDATE_PASSWORD: {
          BACK: 'Regreso',
          INPUTS: {
            LABEL: {
              NEW_PASSWORD: 'Nueva contraseña',
              PASSWORD: 'Contraseña actual',
              PASSWORD_CONFIRMATION: 'Confirmar nueva contraseña',
            },
            PLACEHOLDER: {
              NEW_PASSWORD: 'Introduzca una nueva contraseña',
              PASSWORD: 'Introduzca la contraseña actual',
              PASSWORD_CONFIRMATION: 'Confirma tu nueva contraseña',
            },
          },
          INVALID: 'Tu contraseña es incorrecta. Verifique la contraseña y vuelva a intentarlo.',
          SUCCESS: {
            SUBTITLE: 'Use su nueva contraseña para ingresar en su próximo acceso.',
            TITLE: 'Su contraseña se ha cambiado correctamente',
          },
          TITLE: 'Cambiar contraseña',
        },
        CHURCH: {
          CHANGE: 'Cambio',
        },
      },
      CREDIT_CARD: {
        DELETE: {
          BUTTON: 'Eliminar tarjeta',
          CANCEL: 'Cancelar',
          SUBTITLE: 'Para usar esta tarjeta, deberá agregar la tarjeta nuevamente. ',
          TITLE: '¿Desea eliminar la tarjeta que termina en {number} ?',
        },
        NEW_CARD: {
          BUTTON: 'Registrar',
          SUBTITLE: 'Información de la tarjeta',
          TITLE: 'Añadir tarjeta',
          ERROR: 'Por favor, proporcione un número de tarjeta de crédito válido para completar el pago. Asegúrese de que todos los detalles sean correctos y de que la tarjeta aún esté dentro de su fecha de vencimiento.',
        },
        NOT_FOUND: {
          BUTTON: 'Añadir tarjeta',
          SUBTITLE: 'Agregue una tarjeta para iniciar la transacción.',
          TITLE: 'No hay tarjetas aquí todavía',
        },
        TITLE: {
          INTERNAL: 'Consultar las tarjetas registradas',
          PAGE: 'Tarjetas',
        },
      },
      DELETE_USER: {
        BUTTON: 'Borrar mi cuenta',
        COMPLEMENT: 'Además, también perderá irreversiblemente su historial de datos.',
        CONFIRM: {
          BUTTON: 'Borrar mi cuenta',
          DESCRIPTION: 'Ingrese su contraseña para completar su solicitud de eliminación de cuenta:',
          ERROR: 'Tu contraseña es incorrecta. Verifique la contraseña y vuelva a intentarlo.',
          INPUT: 'Contraseña',
          INPUT_PLACEHOLDER: 'Escribe tu contraseña',
        },
        DESCRIPTION: 'Cuando elimine su cuenta, deberá crear una nueva si desea volver a utilizar todas las funciones de la aplicación.',
        MODULE_NAME: 'Borrar mi cuenta',
        SUCCESS: {
          BUTTON: 'Entendí',
          COMPLEMENT: 'Además, si desea volver a acceder a todas las funciones de la aplicación, simplemente regístrese nuevamente.',
          DESCRIPTION: 'Recibirá información sobre cómo eliminar su cuenta en su correo electrónico.',
          TITLE: 'Se ha solicitado la eliminación de su cuenta.',
        },
        TITLE: '¿Realmente quieres eliminar tu cuenta?',
      },
      EVENT: {
        SUBSCRIBED: {
          BTN: {
            VIEW_TICKET: 'Ver boleto',
          },
          NOT_FOUND: {
            BUTTON: 'Ver eventos',
            SUBTITLE: 'El boleto estará disponible después de registrarse en el evento',
            TITLE: 'No hay suscripción disponible',
          },
          TEXT: {
            PAGE_MESSAGE: 'Selecione uma inscrição para conferir os detalhes',
          },
          TITLE: {
            INTERNAL: 'Suscripciónes',
            PAGE: 'Mis Suscripciónes',
          },
        },
        TICKETS: {
          BTN: {
            COMING_SOON: 'Disponible pronto',
            EXPIRED: 'Contenido Caducado',
            VIEW_CONTENT: 'Ver contenido',
          },
          CONTENT: {
            ERROR: {
              NO_CONTENT: 'No se encontró contenido',
            },
            SINGLE: {
              TEXT: {
                CHAT: 'Chat',
                NOT_FOUND: 'Contenido no encontrado o no disponible actualmente.',
              },
            },
            TEXT: {
              CHAT: 'Chat',
              CHECK_THE_CONTENT: 'Ver información de contenido',
              NO_CONTENT: 'No hay contenido para mostrar en este momento.',
            },
            TITLE: {
              INTERNAL: 'Seleccione el contenido que desea ver',
              PAGE: 'Contenido',
            },
          },
          ERROR: {
            TICKETS_NOT_FOUND: 'No se encontraron entradas para este evento.',
          },
          TEXT: {
            EXCLUSIVE_CONTENT: 'Ticket con contenido exclusivo',
            PAGE_MESSAGE: 'Información de entradas',
            START_FINISH_TIME: '{start} al {finish}',
          },
          TICKET_FIELD: {
            BUYER: 'Comprador',
            DATE: 'Fecha',
            EVENT_NAME: 'Nombre del evento',
            TICKET: 'Boleto',
            TICKETS: 'Entradas',
            TIME: 'Calendario',
            USER: 'Nombre de usuario',
          },
          TITLE: {
            INTERNAL: 'Mis entradas',
            PAGE: 'Meus ingressos',
          },
        },
      },
      NOTES: {
        BUTTON: {
          BACK: 'Volver a la lista',
          BACK_TOP: 'Volver a la cima',
          CANCEL: 'Cancelar',
          DELETE: 'Borrar',
          DELETE_NOTE: 'Borrar nota',
          NEW: 'Nueva nota',
          SAVE: 'Guardar',
          SAVE_NOTE: 'Guardar nota',
          VIEW_MORE: 'Ver más',
        },
        DATE: {
          MESSAGE: 'Ultimo cambio realizado en {date} a las {hour}',
        },
        DELETE: {
          DESCRIPTION: 'Al optar por eliminar, esta acción será irreversible.',
          MODAL: {
            BUTTON_OK: 'OK',
            DESCRIPTION: 'Por favor, trate de nuevo.',
            TITLE: 'Se ha producido un error al intentar eliminar esta anotación',
          },
          TITLE: '¿Estás seguro que quieres eliminar esta nota?',
        },
        EMPTY: {
          DESCRIPTION: 'Crea tu primera nota aquí',
          TITLE: 'Todavía no hay notas aquí',
        },
        LIVE: {
          BUTTON: 'Iniciar sesión / Registrarse',
          DESCRIPTION: 'Para tomar notas, conéctese a su cuenta o regístrese.',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No se ha podido cargar la información.',
          },
        },
        MODAL: {
          BUTTON_CANCEL: 'Cancelar',
          BUTTON_CONFIRM: 'Entendí',
          BUTTON_CONTINUE: 'Continuar editando',
          BUTTON_DELETE: 'Borrar nota',
          BUTTON_NEW: 'Nueva nota',
          BUTTON_OK: 'OK',
          DESCRIPTION_NOT_CONTENT: 'Cuando salga, su nota se eliminará ya que no tiene contenido.',
          DESCRIPTION_NOT_TITLE: 'Para guardar tus notas es necessario crear un título.',
          DESCRIPTION_SAVE_NOTE: 'Ahora puede elegir crear una nueva nota o continuar editando esta nota',
          TITLE_NOT_CONTENT: '¿De verdad quieres abandonar la nota?',
          TITLE_NOT_TITLE: 'Nota sin titulo',
          TITLE_SAVE_NOTE: 'Su nota se ha guardado correctamente',
        },
        PLACEHOLDER: {
          TEXT: 'Empezar a escribir',
          TITLE: 'Título de la nota',
        },
        TITLE: {
          PAGE: 'Notas',
          TEXT: 'Revisa todas las notas que ya has hecho',
        },
      },
      PAYMENT: {
        STATUS: {
          BUTTON: {
            BACK_HOME: 'Volver a la página de inicio',
          },
          TEXT: {
            STATUS: {
              CANCELED: 'Su pago de <span class="text--featured">{value}</span> a <span class="text--featured">{church}</span> ha sido cancelado.',
              NOT_FOUND: 'No hay ningún registro de pago para el código introducido.',
              REFUND: 'Su pago de <span class="text--featured">{value}</span> a <span class="text--featured">{church}</span> ha sido reembolsado.',
              REVIEW: 'Su pago de <span class="text--featured">{value}</span> a <span class="text--featured">{church}</span> está bajo análisis.',
              SUCCESS: '<span class="text--featured">{church}</span> ha recibido con éxito el valor de <span class="text--featured">{value}</span> destinado a <span class="text--featured">{destination}</span>.',
              WAITING: 'Su pago de <span class="text--featured">{value}</span> a <span class="text--featured">{church}</span> está en espera de pago.',
            },
            THANK_YOU: 'Gracias',
          },
          TITLE: {
            PAGE: 'Estado del pago',
          },
        },
      },
      PRAYER: {
        INDEX: {
          EMPTY: {
            BUTTON: 'Hacer un pedido',
            SUBTITLE: 'Aún no hay solicitud de oracion',
            TEXT: 'Cuando hagas una solicitud en el muro aparecerá aquí',
          },
          MESSAGE: {
            BACK_TOP: 'Volver a la cima',
            EMPTY: 'Todavía no hay oraciones.',
            ERROR: {
              GENERIC: 'No se encontró contenido.',
              SEARCH: 'No hay resultados para esta búsqueda',
              SEARCH_MESSAGE: '¿Qué tal probar con otro nombre o palabra clave? ',
            },
            REQUEST: 'Haz tu petición de oración',
            SEND_MESSAGE: 'Deja su apoyo en este orden',
            VIEW_MORE: 'Ver más',
          },
        },
        TITLE: {
          INTERNAL: 'Consulta aquí todos tus pedidos',
          PAGE: 'Muro de oración',
        },
      },
      USER_DATA: {
        BUTTON: {
          EDIT: 'Editar',
          NEXT: 'Registrar cambios',
          ADD: 'Añadir',
        },
        MESSAGE: {
          ERROR: 'No fue posible actualizar la información, intente nuevamente.',
          SUCCESS: 'Sus datos se han actualizado correctamente.',
        },
        TITLE: {
          INTERNAL: 'SUS DATOS',
          PAGE: 'Actualizar datos de usuario',
        },
        CHANGE_CHURCH: {
          ALERT: '<b>ADVERTENCIA:</b> Su registro se vinculará a la iglesia seleccionada.',
          MODAL: {
            TITLE: 'Estás cambiando tu registro a ',
            SUBTITLE: 'Su cuenta será cancelada la nueva iglesia seleccionada. <p>Desea confirmar ?</p>',
            BACK: 'Ahora no',
          },
        },
        CHILDREN: {
          TITLE: 'Registro del niño/a',
        },
        ADDRESS: {
          TITLE: 'Registro de dirección',
        },
      },
    },
    VIDEOS: {
      INDEX: {
        EMPTY: {
          BUTTON: 'Volver al principio',
          SUBTITLE: 'Cuando se crea el contenido, puede verlo aquí.',
          TITLE: 'No hay {videos} aquí todavía',
        },
        FILTER: 'Buscar por nombre o fecha',
        MESSAGE: {
          DATE: 'Publicado en:',
          ERROR: {
            GENERIC: 'No se encontró contenido.',
            SEARCH: 'No hay resultados para esta busca',
            SEARCH_MESSAGE: '¿Qué tal probar con otro título o palabra clave?',
          },
        },
        TITLE: 'Videos',
      },
      MODULE_NAME: 'VIDEOS',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Se produjo un error al recuperar los datos. Inténtalo de nuevo.',
            NOT_FOUND: 'El contenido no existe o no está disponible.',
          },
        },
        PLAYLIST: 'Playlist',
      },
    },
    BIBLE: {
      MODULE_NAME: 'Biblia',
    },
    VOLUTS: {
      TITLE: 'Voluts',
      DESCRIPTION: 'Haga clic en el botón a continuación para acceder a Voluts',
      BUTTON: 'Acceder',
    },
    MY_KIDS: {
      TITLE: 'MyKids',
      DESCRIPTION: 'Haga clic en el botón a continuación para acceder a MyKids',
      BUTTON: 'Acceder',
    },
  },
  VALIDATION: {
    birthdate: 'Fecha de nacimiento invalida',
    cpf: 'Número de documento no válido',
    date: 'Formato de fecha inválido',
  },
};
