<template>
  <div id="app">
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <router-view :key="routerViewKey" />
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  // Mixins
  import clientBootstrap from '@/mixins/clientBootstrapMixin';
  import defineScreenSize from '@/mixins/defineScreenSizeMixin';
  import addPixelMixin from './mixins/addPixelMixin';

  export default {
    name: 'App',

    mixins: [
      clientBootstrap,
      defineScreenSize,
      addPixelMixin,
    ],

    computed: {
      ...mapGetters([
        'churchData',
        'churchVisual',
      ]),

      routerViewKey() {
        const matched = this.$route.matched.find((record) => record.meta.layout);

        return matched ? matched.meta.layout : 'DEFAULT';
      },
    },

    created(){
      // Setting church primary color
      if(this.churchVisual){
        const { style } = document.documentElement;

        style.setProperty('--primary', this.churchVisual.corApp, 'important');
        style.setProperty('--secondary', `${this.churchVisual.corApp}26`, 'important');
      }

      // Verify church code to add hortjar script
      if(this.churchData?.codigo === 'igrejadoamor'){
        this.appendHotjarScript();
      }

      //Verify if church has pixel code to add pixel script
      if(this.churchData?.facebookPixelCode){
        this.appendPixelScript();
      }
    },

    updated(){
      if(this.churchData?.facebookPixelCode){
        window.fbq('track', 'PageView');
      }
    },

    methods: {
      // Add hotjar script to track user behavior
      appendHotjarScript(){
        const hotjar = document.createElement('script');

        hotjar.innerHTML = `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3158917,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;

        document.head.appendChild(hotjar);
      },
    },

    metaInfo() {
      const description = () => this.churchData?.historia.slice(0, 160) || this.churchData?.descricao.slice(0, 160);
      const images = () => this.churchData.imagens ? this.churchData.imagens[0] : null;

      if (!this.churchData) {
        return {
          title: 'Loading...',
        };
      }

      return {
        title: this.churchData.nome,
        titleTemplate: `%s | ${this.churchData.nome}`,
        description: description(),
        htmlAttrs: {
          lang: this.churchData.idioma,
        },

        meta: [
          // General
          {
            vmid: 'description',
            name: 'description',
            content: description(),
          },

          {
            vmid: 'name',
            name: 'name',
            content: this.churchData.nome,
          },

          {
            vmid: 'title',
            name: 'title',
            content: this.churchData.nome,
          },

          {
            vmid: 'robots',
            name: 'robots',
            content: 'index, follow',
          },

          {
            vmid: 'googlebot',
            name: 'googlebot',
            content: 'index, follow',
          },

          {
            vmid: 'language',
            name: 'language',
            content: this.churchData.idioma,
          },

          {
            vmid: 'author',
            name: 'author',
            content: 'InPeace',
          },

          {
            vmid: 'copyright',
            name: 'copyright',
            content: 'InPeace',
          },

          {
            vmid: 'publisher',
            name: 'publisher',
            content: 'InPeace',
          },

          {
            vmid: 'distribution',
            name: 'distribution',
            content: 'Global',
          },

          {
            vmid: 'rating',
            name: 'rating',
            content: 'General',
          },

          {
            vmid: 'keywords',
            name: 'keywords',
            content: `
              ${this.churchData.nome} InPeace, InPeace Web, InPeaceApp,
              Church,Discover, Events, Live, Giving, Prayer request,
              Iglesia, Eventos, Culto en vivo, Donación, Muro de oraciones,
              Igreja, Culto aovivo, Doação, Mural de orações`,
          },

          // OpenGraph
          {
            vmid: 'og:image:url',
            property: 'og:image:url',
            content: images(),
          },

          {
            vmid: 'og:image',
            property: 'og:image',
            content: images(),
          },

          {
            vmid: 'og:description',
            property: 'og:description',
            content: description(),
          },

          {
            vmid: 'og:locale',
            property: 'og:locale',
            content: this.churchData.idioma,
          },

          {
            vmid: 'og:locale:alternate',
            property: 'og:locale:alternate',
            content: this.churchData.idioma,
          },

          {
            vmid: 'og:title',
            property: 'og:title',
            content: this.churchData.nome,
          },

          {
            vmid: 'og:site_name',
            property: 'og:site_name',
            content: this.churchData.nome,
          },

          {
            vmid: 'og:type',
            property: 'og:type',
            content: 'website',
          },

          {
            vmid: 'og:url',
            property: 'og:url',
            content: window.location.origin,
          },

          {
            vmid: 'twitter:image',
            name: 'twitter:image',
            content: images(),
          },

          {
            vmid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary_large_image',
          },

          {
            vmid: 'twitter:site',
            name: 'twitter:site',
            content: this.churchData.nome,
          },

          {
            vmid: 'twitter:creator',
            name: 'twitter:creator',
            content: 'InPeace',
          },

          {
            vmid: 'twitter:title',
            name: 'twitter:title',
            content: this.churchData.nome,
          },

          {
            vmid: 'twitter:description',
            name: 'twitter:description',
            content: description(),
          },

          {
            vmid: 'twitter:url',
            name: 'twitter:url',
            content: `${window.location.origin}`,
          },

          {
            vmid: 'twitter:image:alt',
            name: 'twitter:image:alt',
            content: this.churchData.nome,
          },

          {
            vmid: 'twitter:domain',
            name: 'twitter:domain',
            content: `${window.location.origin}`,
          },

          {
            vmid: 'twitter:locale',
            name: 'twitter:locale',
            content: this.churchData.idioma,
          },

          {
            vmid: 'twitter:locale:alternate',
            name: 'twitter:locale:alternate',
            content: this.churchData.idioma,
          },
        ],

        link: [
          {
            vmid: 'canonical',
            rel: 'canonical',
            href: window.location.origin,
          },
        ],
      };
    },
  };
</script>

<style lang="scss">
  // --- Default
  @import "@/assets/scss/base/_normalize.scss";
  @import "@/assets/scss/base/_fonts.scss";
  @import "@/assets/scss/base/_container.scss";
  @import "@/assets/scss/base/_colors.scss";

  // --- Common
  @import "@/assets/scss/common/_buttons.scss";
  @import "@/assets/scss/common/_form.scss";
  @import "@/assets/scss/common/_white-card.scss";
  @import "@/assets/scss/common/_card.scss";
  @import "@/assets/scss/common/_animation.scss";
  @import "@/assets/scss/common/_transitions.scss";
  @import "@/assets/scss/common/_image.scss";
  @import "@/assets/scss/common/carousel.scss";

  // Libs
  @import '~hooper/dist/hooper.css';

  html {
    width: 100%;
    overflow-x: hidden !important;
    scroll-behavior: smooth;

    body {
      width: 100%;
      font-family: 'Source Sans Pro', sans-serif;
      overflow-anchor: none;

      // --- Bot
      #chat-circle {
        z-index: 1;
        bottom: 6rem !important;
      }

      // Vue js modal
      .v--modal-overlay {
        background: rgba(0, 0, 0, 0.6);
      }

      &.v--modal-block-scroll {
        width: 100%;
      }
    }
  }
</style>
