import { inpeaceAPI } from '@/config/axios/index';

export default {
  praiseGet(praiseId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/louvor/${praiseId}/`,
      data: null,
    });
  },
};
