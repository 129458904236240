import { inpeaceAPI } from '@/config/axios/index';

export default {
  getPrayerList(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/oracao/${churchSlug}`,
    });
  },

  getPrayer(churchSlug, prayerId) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/oracao/${churchSlug}/${prayerId}`,
    });
  },

  getPrayerMotives(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/oracao-motivo/`,
    });
  },

  postPrayer(prayerData) {
    return inpeaceAPI({
      method: 'POST',
      url: `${process.env.VUE_APP_API_V1}/oracao/`,
      data: prayerData,
    });
  },

  postPrayerMessage(idOracao, idUsuario, mensagem){
    return inpeaceAPI({
      method: 'POST',
      url: `${process.env.VUE_APP_API_V1}/oracao/mensagem/`,
      data: {
        idUsuario, idOracao, mensagem,
      },
    });
  },
  postPray(idOracao, idUsuario){
    return inpeaceAPI({
      method: 'POST',
      url: `${process.env.VUE_APP_API_V1}/oracao/orar/`,
      data: {
        idUsuario, idOracao,
      },
    });
  },

  deletePrayer(idOracao) {
    return inpeaceAPI({
      method: 'DELETE',
      url: `${process.env.VUE_APP_API_V1}/oracao/${idOracao}`,
    });
  },
};
