// API
import inpcPlayAPI from '@/api/inpcPlay';

const checkoutDefaultData = () => ({
  planId: null,
  value: null,
});

const moduleDefault = () => ({
  moduleData: null,
  plans: null,
  homeContent: null,
  featuredContent: null,
  myListContent: null,
  userSubcriptions: null,
  checkout: {
    ...checkoutDefaultData(),
  },
});

export default {
  state: moduleDefault(),

  getters: {
    inpcPlayViewType: (state) => (
      state.moduleData ? state.moduleData.tipoVisibilidadeConteudo : null
    ),
    inpcPlayModuleData: (state) => state.moduleData,
    inpcPlayHomeContent: (state) => state.homeContent,
    inpcPlayFeaturedContent: (state) => state.featuredContent,
    /** List of registers from API */
    inpcPlayMyListItems: (state) => state.myListContent,
    /** Get a specific item from items list */
    inpcPlayMyListItem: (state) => (contentId) => state.myListContent
      ? state.myListContent.find(content => content.planoAssinaturaConteudo.id === contentId)
      : null,
    /** Get only the contents/cover, without my list extra data */
    inpcPlayMyListContents: (state) => state.myListContent
      ? state.myListContent.map(content => content.planoAssinaturaConteudo)
      : [],
    inpcPlayPlans: (status) => status.plans,
    inpcPlayCheckoutData: (status) => status.checkout,
    inpcPlaySubscriptions: (status) => status.userSubcriptions,
    inpcPlayValidSubscription: (state) => (state.userSubcriptions
      ? state.userSubcriptions.find((subscription) => subscription.status.situacao === 'ativo')
      : false),
  },

  actions: {
    async inpcPlayGetModuleData({ commit, getters }) {
      try {
        const response = await inpcPlayAPI.getModuleData(getters.churchData.slug);

        commit('INPCPLAY_SET_MODULE_DATA', response.data.planoAssinaturaConfiguracao);

        return Promise.resolve(response);
      } catch (error) {
        commit('INPCPLAY_SET_MODULE_DATA', null);

        return Promise.reject(error);
      }
    },

    async inpcPlayGetPlans({ commit, getters }) {
      try {
        const response = await inpcPlayAPI.getPlans(getters.churchData.slug);

        commit('INPCPLAY_SET_PLANS', response.data.planoAssinaturas);

        return Promise.resolve(response);
      } catch (error) {
        commit('INPCPLAY_SET_PLANS', null);

        return Promise.reject(error);
      }
    },

    async inpcPlayGetHomeContent({ commit, getters }) {
      try {
        const response = await inpcPlayAPI.getHomeContent(getters.churchData.slug);

        commit('INPCPLAY_SET_HOME_CONTENT', response.data.tags);

        return Promise.resolve(response);
      } catch (error) {
        commit('INPCPLAY_SET_HOME_CONTENT', null);

        return Promise.reject(error);
      }
    },

    async inpcPlayGetFeaturedContent({ commit, getters }) {
      try {
        const response = await inpcPlayAPI.getFeaturedContent(getters.churchData.id);

        commit('INPCPLAY_SET_FEATURED_CONTENT', response.data.planoAssinaturaConteudos);

        return Promise.resolve(response);
      } catch (error) {
        commit('INPCPLAY_SET_FEATURED_CONTENT', null);

        return Promise.reject(error);
      }
    },

    async inpcPlayGetUserSubscriptions({ commit, getters }) {
      try {
        const response = await inpcPlayAPI.getUserSubscriptions(getters.userInpeaceAccount.id, getters.churchData.id);

        commit('INPCPLAY_SET_USER_SUBSCRIPTIONS', response.data.usuarioPlanoAssinaturas);

        return Promise.resolve(response);
      } catch (error) {
        commit('INPCPLAY_SET_USER_SUBSCRIPTIONS', null);

        return Promise.reject(error);
      }
    },

    async inpcPlayGetUserMyListData({ commit, getters }) {
      try {
        const response = await inpcPlayAPI.getUserMyListContent(getters.churchId, getters.userLoggedId);

        commit('INPCPLAY_SET_USER_MY_LIST_CONTENT', response.data.usuarioPlanoAssinaturaConteudos);

        return Promise.resolve(response);
      } catch (error) {
        // Setting to empty array is has some error
        commit('INPCPLAY_SET_USER_MY_LIST_CONTENT', []);

        return Promise.reject(error);
      }
    },

    inpcPlayClearData({ commit }) {
      commit('INPCPLAY_CLEAR_DATA');
    },

    inpcPlaySetCheckoutData({ commit }, { planId, value }) {
      commit('INPCPLAY_SET_CHECKOUT_DATA', {
        planId,
        value,
      });
    },

    inpcPlayResetCheckoutData({ commit }) {
      commit('INPCPLAY_CLEAR_CHECKOUT');
    },
  },

  mutations: {
    INPCPLAY_SET_MODULE_DATA(state, data) {
      state.moduleData = data;
    },

    INPCPLAY_SET_HOME_CONTENT(state, data) {
      state.homeContent = data;
    },

    INPCPLAY_SET_FEATURED_CONTENT(state, data) {
      state.featuredContent = data;
    },

    INPCPLAY_SET_USER_SUBSCRIPTIONS(state, data) {
      state.userSubcriptions = data;
    },

    INPCPLAY_SET_USER_MY_LIST_CONTENT(state, data) {
      state.myListContent = data;
    },

    INPCPLAY_SET_PLANS(state, data) {
      state.plans = data;
    },

    INPCPLAY_SET_CHECKOUT_DATA(state, data) {
      state.checkout = data;
    },

    INPCPLAY_CLEAR_CHECKOUT(state) {
      state.checkout = { ...checkoutDefaultData() };
    },

    INPCPLAY_CLEAR_DATA(state) {
      Object.assign(state, moduleDefault());
    },
  },
};
