import userAPI from '@/api/user';
import { inpeaceAPI, inpeaceBFF } from '@/config/axios/index';

const getUserDefault = () => ({
  user: null,
  cards: null,
  cardData: null,
  cardRegistration: null,
  token: null,
  autoLoginData: null,
  events: {
    subscribed: null,
    ticketContent: {
      ticketId: null,
      content: null,
    },
  },
  // Children
  childEdit: null,
  userChildren: null,
  deletedChildren: [],
  updatedChildren: [],
  // Address
  userAddress: null,
  updatedAddress: null,
  deletedAddress: null,
  // --- InPeace 360
  credentials: null,
  inpeaceAccount: null,
  clientAccount: null,
  documents: null,
  roles: null,
  notifications: null,
  // ---
});

export default {
  state: getUserDefault(),

  getters: {
    /**
     * User data
     */
    userData: (state) => state.user,
    userCards: (state) => state.cards,
    userCardData: (state) => state.cardData,
    userAutoLoginData: (state) => state.autoLoginData,
    userNotifications: (state) => state.notifications,

    // Address
    userAddress: (state) => state.userAddress,
    userUpdatedAddress: (state) => state.updatedAddress,
    userDeletedAddress: (state) => state.deletedAddress,

    // Children
    userChildren: (state) => state.userChildren,
    userChildEdit: (state) => state.childEdit,
    userDeletedChildren: (state) => state.deletedChildren,
    userUpdatedChildren: (state) => state.updatedChildren,
    // --- InPeace 360
    userToken: (state) => state.credentials?.token, // TODO: Configurar para usar token salvo na session
    userRefreshToken: (state) => state.credentials?.refreshToken,
    userInpeaceAccount: (state) => state.inpeaceAccount,
    userClientAccount: (state) => state.clientAccount,
    userDocuments: (state) => state.documents,
    userRoles: (state) => state.roles,
    userLoggedId: (state, getters) => getters.userInpeaceAccount?.id,
    userLoggedIn: (state, getters) => !!(getters.userToken && getters.userInpeaceAccount && getters.userClientAccount),
    // ---
    /**
     * Event
     */
    userEventsSubscribed: (state) => state.events.subscribed,
    userEventSubscribedData: (state) => (eventId) => (state.events.subscribed
      ? state.events.subscribed.find((event) => event.id === Number(eventId))
      : null),
    userEventTicketContent: (state) => (ticketId) => (
      state.events.ticketContent.ticketId === Number(ticketId)
        ? state.events.ticketContent.content
        : null),
    userEventTicketContentSingle: (state) => (ticketId, contentId) => (
      state.events.ticketContent.ticketId === Number(ticketId)
        ? state.events.ticketContent.content.find((content) => content.id === Number(contentId))
        : null),
  },

  actions: {
    // --- InPeace 360
    async userGetCredentials({ dispatch, commit }, { username, password }) {
      try {
        const response = await userAPI.postUserLogin({
          username,
          password,
        });

        dispatch('userSetCredentials', response.data);

        return Promise.resolve(response);
      } catch (error) {

        commit('USER_SET_CREDENTIALS', null);

        return Promise.reject(error);
      }
    },

    async userGetInPeaceAccount({ commit }) {
      try {
        const response = await userAPI.getInPeaceAccount();

        commit('USER_SET_INPC_ACCOUNT', response.data.user);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_INPC_ACCOUNT', null);

        return Promise.reject(error);
      }
    },

    async userGetClientAccount({ commit, getters }) {
      try {
        const response = await userAPI.getClientAccount({
          userId: getters.userInpeaceAccount.id,
          client: getters.churchId,
        });

        if (!response.data.usuarios.length) {
          throw response;
        }

        commit('USER_SET_CLIENT_ACCOUNT', response.data.usuarios[0]);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_CLIENT_ACCOUNT', null);

        return Promise.reject(error);
      }
    },

    async user360GetClientAccount({ commit, getters }) {
      try {
        const response = await userAPI.getClient360Account({
          userId: getters.userInpeaceAccount.id,
          slug: getters.slugOrganizacao,
        });

        if (!response.data.usuarios.length) {
          throw response;
        }

        commit('USER_SET_CLIENT_ACCOUNT', response.data.usuarios[0]);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_CLIENT_ACCOUNT', null);

        return Promise.reject(error);
      }
    },

    async userGetDocuments({ commit, getters }, userId = null ) {
      try {
        const response = await userAPI.getUserDocuments({
          userId: userId ? userId : getters.userInpeaceAccount.id,
          client: getters.churchId,
        });

        commit('USER_SET_CLIENT_DOCUMENTS', response.data.documentos);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_CLIENT_DOCUMENTS', null);

        return Promise.reject(error);
      }
    },

    async user360GetDocuments({ commit, getters }, userId = null) {
      try {
        const response = await userAPI.getUser360Documents({
          userId: userId ? userId : getters.userInpeaceAccount.id,
          slug: getters.slugOrganizacao,
        });

        commit('USER_SET_CLIENT_DOCUMENTS', response.data.documentos);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_CLIENT_DOCUMENTS', null);

        return Promise.reject(error);
      }
    },

    async userGetRoles({ commit, getters }) {
      try {
        const response = await userAPI.getUserRole({
          userId: getters.userInpeaceAccount.id,
          client: getters.churchId,
        });

        commit('USER_SET_CLIENT_ROLES', response.data.userIgrejaRoles);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_CLIENT_ROLES', null);

        return Promise.reject(error);
      }
    },

    async user360GetRoles({ commit, getters }) {
      try {
        const response = await userAPI.getUser360Role({
          userId: getters.userInpeaceAccount.id,
          slug: getters.slugOrganizacao,
        });

        commit('USER_SET_CLIENT_ROLES', response.data.userIgrejaRoles);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_CLIENT_ROLES', null);

        return Promise.reject(error);
      }
    },

    setUserChildren({ commit }, payload) {
      commit('SET_USER_CHILDREN', payload);
    },

    setDeletedChildren({ commit }, payload) {
      commit('SET_USER_DELETED_CHILDREN', payload);
    },

    setUpdatedChildren({ commit }, payload) {
      commit('SET_USER_UPDATED_CHILDREN', payload);
    },

    setChildEdit({ commit }, payload) {
      commit('SET_CHILD_EDIT', payload);
    },

    setUserAddress({ commit }, payload) {
      commit('SET_USER_ADDRESS', payload);
    },

    setDeletedAddress({ commit }, payload) {
      commit('SET_USER_DELETED_ADDRESS', payload);
    },

    setUpdatedAddress({ commit }, payload) {
      commit('SET_USER_UPDATED_ADDRESS', payload);
    },


    userSetAutoLoginData({ commit, dispatch }, {
      refreshToken, token, origin,
    }) {
      commit('USER_SET_AUTO_LOGIN', {
        origin,
        token,
        refreshToken,
      });

      if (origin === 'SESSION' || origin === 'URL') {
        dispatch('userSetCredentials', {
          token: token,
          refreshToken: refreshToken,
        });
      }
    },

    userSetCredentials({ commit }, { token, refreshToken }) {
      // Setting the api token in axios instance
      inpeaceAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
      inpeaceBFF.defaults.headers.common.Authorization = `Bearer ${token}`;

      // Setting the api token in session
      window.sessionStorage.setItem('token', token);

      // Commiting the token
      commit('USER_SET_CREDENTIALS', {
        token: token,
        refreshToken: refreshToken,
      });
    },

    async userLogOut({ commit, dispatch }) {
      Promise.allSettled([
        // Reset user data
        commit('USER_RESET_ALL_DATA'),
        // Reset chat data
        dispatch('chatResetUser'),
        // Reset inpeace play data
        dispatch('inpcPlayClearData'),
      ]);

      // Remove session and local data
      window.localStorage.removeItem('refreshToken');
      window.sessionStorage.removeItem('token');

      // Removing authorization from api requests
      delete inpeaceAPI.defaults.headers.common.Authorization;
      delete inpeaceBFF.defaults.headers.common.Authorization;
    },
    // ---

    // async userLogin({ commit, dispatch }, { igreja, email, senha }) {
    //   try {
    //     const response = await userAPI.postLogin({
    //       igreja,
    //       email,
    //       senha,
    //     });

    //     // If there is some error the API return 200 with error inside the body (god help us all)
    //     // so in the next line I check if has error inside the body and reject.
    //     if ('status' in response.data && response.data.status === false) {
    //       throw response;
    //     }

    //     // Setting the refresh token and id in local storage
    //     window.localStorage.setItem('refreshToken', response.data.refreshToken);
    //     window.localStorage.setItem('userId', response.data.usuario.id);
    //     window.sessionStorage.setItem('userId', response.data.usuario.id);

    //     // Saving the user data on storage
    //     commit('USER_SET_DATA', response.data.usuario);

    //     // Dispatching the token
    //     dispatch('userSetCredentials', response.data.token);

    //     return Promise.resolve(response);
    //   } catch (error) {
    //     dispatch('userLogOut');

    //     return Promise.reject(error);
    //   }
    // },

    async userGetCards({ commit, getters }) {
      try {
        const response = await userAPI.getCards({
          churchSlug: getters.churchData.slug,
          userId: getters.userInpeaceAccount.id,
        });

        let cards = [];

        if (!('status' in response.data) && response.data.length) {
          cards = (response.data).reduce((acumulador, cardData) => {
            const group = acumulador.find(
              (gatewayGroup) => gatewayGroup.gatewayId === cardData.idGatewayPagamento,
            );

            if (group) {
              group.cards.push(cardData);
            } else {
              acumulador.push({
                gatewayId: cardData.idGatewayPagamento,
                cards: [
                  cardData,
                ],
              });
            }

            return acumulador;
          }, []);
        }

        commit('USER_SET_CARDS', cards);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_CARDS', []);

        return Promise.reject(error);
      }
    },

    userCreditCardData({ commit }, cardData) {
      commit('USER_SET_CARD_DATA', cardData);
    },

    async userPostCreditCard({ commit, getters }, cardData) {
      try {
        const response = await userAPI.postCreditCard(
          cardData,
          getters.churchData.id,
        );

        await commit('USER_SET_CARD_REGISTRATION', response.data);

        return Promise.resolve(response);
      } catch (error) {

        return Promise.reject(error.response);
      }
    },

    async userGetData({ commit, getters, dispatch }, userId) {
      try {
        const response = await userAPI.getData(getters.churchData.slug, userId);

        await commit('USER_SET_DATA', response.data);

        return Promise.resolve(response);
      } catch (error) {
        dispatch('userLogOut');

        return Promise.reject(error.response);
      }
    },

    userResetAutoLoginData({ commit }) {
      commit('USER_SET_AUTO_LOGIN', null);
    },

    userGetToken({ dispatch }, refreshToken) {
      return userAPI.getAuthToken({
        data: refreshToken,
      }).then((response) => {
        dispatch('userSetCredentials', response.data.token);

        return Promise.resolve(response);
      });
    },

    userGetEventsSubscribed({ commit, getters }) {
      return userAPI.getEventsSubscribed(
        {
          params: {
            userId: getters.userInpeaceAccount.id,
          },
        },
      ).then((response) => {
        commit('USER_SET_EVENT_SUBSCRIBED', response.data.eventos);

        return Promise.resolve(response);
      }).catch((error) => {
        commit('USER_SET_EVENT_SUBSCRIBED', []);

        return Promise.reject(error);
      });
    },

    userGetEventTicketContent({ commit, getters }, { eventId, ticketId }) {
      return userAPI.getEventTicketContent(
        {
          params: {
            userId: getters.userInpeaceAccount.id,
            eventId,
            ticketId,
          },
        },
      ).then((response) => {
        commit('USER_SET_EVENT_TICKET_CONTENT', {
          ticketId: Number(ticketId),
          content: response.data.conteudos,
        });

        return Promise.resolve(response);
      }).catch((error) => {
        commit('USER_SET_EVENT_TICKET_CONTENT', {
          ticketId: null,
          content: null,
        });

        return Promise.reject(error);
      });
    },

    async userGetNotifications({ commit, getters } , limit = 30) {
      try {
        const response = await userAPI.getNotifications(getters.churchData.slug, limit);

        // Cleaning notification data from the response
        const notifications = response.data.notificacoes.map((notification) => ({
          id: notification.id,
          corpo: notification.corpo,
          titulo: notification.titulo,
          dataCadastro: notification.dataCadastro,
          dataUltimaAlteracao: notification.dataUltimaAlteracao,
          tipo: notification.tipo,
          igreja: notification.igreja,
          idEntidadeRelacionada: notification.idEntidadeRelacionada,
          isRoute: getters.churchData.codigo === notification.igreja.codigo &&
            (notification.tipo.codigo === 'CULTO' || notification.tipo.codigo === 'EVENTO' || notification.tipo.codigo === 'EBD' ||
            notification.tipo.codigo === 'NOTICIA' || notification.tipo.codigo === 'DEVOCIONAL') ? true : false,
        }));

        commit('USER_SET_NOTIFICATIONS', notifications);

        return Promise.resolve(response);
      } catch (error) {
        commit('USER_SET_NOTIFICATIONS', null);

        return Promise.reject(error.response);

      }
    },
  },

  mutations: {
    // --- InPeace 360
    USER_SET_CREDENTIALS(state, credentials) {
      state.credentials = credentials;
    },

    USER_SET_INPC_ACCOUNT(state, user) {
      state.inpeaceAccount = user;
    },

    USER_SET_CLIENT_ACCOUNT(state, user){
      state.clientAccount = user;
    },

    USER_SET_CLIENT_DOCUMENTS(state, documents){
      state.documents = documents;
    },

    USER_SET_CLIENT_ROLES(state, roles){
      state.roles = roles;
    },
    // ---

    SET_USER_CHILDREN(state, payload) {
      state.userChildren = payload;
    },

    SET_USER_DELETED_CHILDREN(state, payload) {
      state.deletedChildren = payload;
    },

    SET_USER_UPDATED_CHILDREN(state, payload) {
      state.updatedChildren = payload;
    },

    SET_CHILD_EDIT(state, payload) {
      state.childEdit = payload;
    },

    SET_USER_ADDRESS(state, payload) {
      state.userAddress = payload;
    },

    SET_USER_DELETED_ADDRESS(state, payload) {
      state.deletedAddress = payload;
    },

    SET_USER_UPDATED_ADDRESS(state, payload) {
      state.updatedAddress = payload;
    },

    USER_SET_DATA(state, payload) {
      state.user = payload;
    },

    USER_SET_AUTO_LOGIN(state, payload) {
      state.autoLoginData = payload;
    },

    USER_SET_CARD_REGISTRATION(state, payload) {
      state.cardRegistration = payload;
    },

    USER_SET_CARDS(state, payload) {
      state.cards = payload;
    },

    USER_SET_CARD_DATA(state, payload) {
      state.cardData = payload;
    },

    USER_SET_EVENT_SUBSCRIBED(state, payload) {
      state.events.subscribed = payload;
    },

    USER_SET_EVENT_TICKET_CONTENT(state, payload) {
      state.events.ticketContent = payload;
    },

    USER_RESET_ALL_DATA(state) {
      Object.assign(state, getUserDefault());
    },

    USER_SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
  },
};
