import memberCardApi from '@/api/memberCard';

const moduleDefaultState = () => ({
  memberCard: null,
});

export default {
  state: moduleDefaultState(),

  getters: {
    memberCardData: ({ memberCard }) => (memberCard && memberCard.length ? memberCard[0] : null),
  },

  actions: {
    async memberCardGetData({ commit }, { memberCardEmail }) {
      try {
        const response = await memberCardApi.getMemberCardData(memberCardEmail);

        if (!response && response.data.usuarioCarteirinhas) {
          throw response;
        }

        const cardData = response.data.usuarioCarteirinhas;

        commit('MEMBER_CARD_SET_DATA', cardData);

        return Promise.resolve(response);
      } catch (error) {
        commit('MEMBER_CARD_SET_DATA', null);

        return Promise.reject(error);
      }
    },
  },

  mutations: {
    MEMBER_CARD_SET_DATA(state, payload) {
      state.memberCard = payload;
    },
  },
};
