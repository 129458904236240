
import api from '@/api/prayer';
import axios from 'axios';

export default {
  state: {
    motives: null,
    activeModule: null,
  },

  getters: {
    prayerGetMotives: (state) => state.motives,
    prayerReturnUrl: (state) => state.activeModule?.returnUrl,
    prayerModule: (state) => state.activeModule?.name,
  },

  actions: {
    async getPrayerMotives({ commit, getters }) {
      try {
        const res = await api.getPrayerMotives(getters.churchSlug);

        commit('PRAYER_SET_MOTIVES', res.data.oracaoMotivos);

        return Promise.resolve(res);
      } catch (e) {
        commit('PRAYER_SET_MOTIVES', null);

        return Promise.reject(e);
      }
    },

    setPrayerModule({ commit }, module){
      commit('PRAYER_SET_ACTIVE_MODULE', module);
    },


    async postPrayer({ getters } , data) {
      let formData = new FormData();

      formData.append('image', data.image);
      formData.append('idUsuario', data.idUsuario);
      formData.append('idIgreja', getters.churchId);
      formData.append('mensagem', data.mensagem);
      formData.append('nome', data.nome);
      formData.append('idOracaoMotivo', data.idOracaoMotivo);
      formData.append('flagPublicarMural', data.flagPublicarMural);
      formData.append('flagAtivo', data.flagAtivo);
      formData.append('flagReceberLigacao', data.flagReceberLigacao);
      formData.append('flagReceberVisita', data.flagReceberVisita);

      try {
        const res = axios.post(`${process.env.VUE_APP_API_V1}/oracao/`, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getters.userToken}`,
          },
        });

        return Promise.resolve(res);
      } catch (e) {

        return Promise.reject(e);
      }
    },
  },

  mutations: {
    PRAYER_SET_MOTIVES(state, data) {
      state.motives = data;
    },

    PRAYER_SET_ACTIVE_MODULE(state, payload) {
      state.activeModule = payload;
    },
  },
};
