import { inpeaceAPI } from '@/config/axios/index';

export default {
  getOrganization(slug) {
    return inpeaceAPI({
      method: 'GET',
      url: `/organizacao/${slug}`,
    });
  },

  getClients({ slug, position }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/cliente/?organizacao=${slug}${position ? `&latitude=${position.lat}&longitude=${position.long}` : ''}`,
    });
  },
};
