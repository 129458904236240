import { inpeaceAPI } from '@/config/axios/index';

export default {
  getMemberCardData(memberEmail) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario-carteirinha/?usuario_email=${memberEmail}`,
      data: null,
    });
  },
};
