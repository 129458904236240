export default {
  state: {
    project: null,
  },

  getters: {
   getProject: ( state ) => state.project,
  },

  actions: {
    setProject( { commit }, project ) {
      commit('SET_PROJECT', project);
    },
  },

  mutations: {
    SET_PROJECT(state, payload) {
      state.project = payload;
    },
  },
};
