// --- Imports
import Vue from 'vue'; // Vue
import '@/config/globalComponents/index'; // VeeValidate
import '@/config/veeValidate/index'; // VeeValidate
import '@/config/vueTelInput/index'; // Telephone Input
import '@/config/vmodal/index'; // Modal
import '@/config/vueTheMask/index'; // Vue mask
import '@/config/gtag/index'; // Tag Manager
import '@/config/sentry/index'; // Sentry
import '@/config/vueDompurify/index'; // DOMPurify
import '@/registerServiceWorker'; // Service Worker register
import { inpeaceAPI } from '@/config/axios/index'; // Axios
import { formSending, getNumberWithCurrency } from '@/helpers'; // Helper for formsending status
import i18n from '@/config/i18n/index'; // Locale i18n
import dayjs from '@/config/dayjs/index'; // DayJS
import VueSlickCarousel from '@/config/vueSlickCarousel/index'; // Slick Carousel
import store from './store/index'; // Store
import router from './router/index'; // Router
import App from './App.vue'; // App Entry

// --- Prototype
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$formSending = formSending;
Vue.prototype.$getNumberWithCurrency = getNumberWithCurrency;

// --- Environment
Vue.config.productionTip = false;

// --- Vue init
new Vue({
  name: 'VueMain',
  router,
  store,
  i18n,
  VueSlickCarousel,
  created() {
    /**
     * Reseting some Vuex State
     */
    this.$store.dispatch('appResetLoadingState');

    /**
     * Checking URL, Session and Local Storage for user saved data for automatic login
     */
    // Getting the URL search params
    const urlParams = new URLSearchParams(window.location.search.substring(1));

    if (urlParams.get('token')) {
      // If the URL has token and user save the data on vuex
      this.$store.dispatch('userSetAutoLoginData', {
        origin: 'URL',
        token: urlParams.get('token'),
      });
    } else if (sessionStorage.getItem('token')) {
      // If the session has token and user id save the data on vuex
      this.$store.dispatch('userSetAutoLoginData', {
        origin: 'SESSION',
        token: sessionStorage.getItem('token'),
      });
    } else if (localStorage.getItem('refreshToken')) {
      // If the local storage has refresh token and user id save the data on vuex
      this.$store.dispatch('userSetAutoLoginData', {
        origin: 'STORAGE',
        refreshToken: localStorage.getItem('refreshToken'),
      });
    }

    window.top.postMessage('3DS-authentication-complete');

    inpeaceAPI.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response) {
          /**
           * Checking if some request response has status code 401
           * if it's occurs then logout the user and reload page
          */
          if (error.response.status === 401 && (this.$route.name !== 'userAccess' && this.$route.name !== 'confirmDeleteUser' )) {

            // Logout the user
            await this.$store.dispatch('userLogOut');

            // Reset any query string params
            await this.$router.replace({ name: 'churchHome', query: {} }).catch(() => {});
            // Force reaload
            window.location.reload();
          }
        } else {
          await this.$router.replace({
            name: 'ErrorPage',
            query: {
              message: this.$t('HOME.MESSAGE.ERROR.GENERIC'),
            },
          }).catch(() => {});
        }

        return Promise.reject(error);
      },
    );
  },
  render: (h) => h(App),
}).$mount('#app');
