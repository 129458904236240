import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

// Components
Vue.component('AlertBox', () => import('@/components/common/message/AlertBox.vue'));
Vue.component('AlertMessage', () => import('@/components/common/message/AlertMessage.vue'));
Vue.component('SkeletonElement', () => import('@/components/common/skeleton/SkeletonElement.vue'));
Vue.component('SvgElement', () => import('@/components/common/svg/SvgElement.vue'));
Vue.component('ModalElement', () => import('@/components/common/ModalElement.vue'));
Vue.component('AvatarPlaceholder', () => import('vue-avatar'));
Vue.component('PageContentHeader', () => import('@/components/common/PageContentHeader.vue'));
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
