import Vue from 'vue';
import * as Sentry from '@sentry/vue';

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  release: process.env.VUE_APP_VERSION,
  attachProps: true,
});
