// API
import api from '@/api/videos';

export default {
  state: {
    videos: null,
  },

  getters: {
    videosGetList: (state) => state.videos,
    videosGetSingle: (state) => (videoId) => (
      state.videos.items.find(video =>
        video.contentDetails.videoId === videoId)
    ),
    videosGetSource: (state) => state.videos.source,
  },
  actions: {
    async getVideosList({ commit, getters }) {
      try {
        const res = await api.getVideosList(getters.churchData.slug);

        commit('VIDEOS_SET_LIST', res.data);

        return Promise.resolve(res);
      } catch (e) {
        commit('VIDEOS_SET_LIST', null);

        return Promise.reject(e);
      }
    },
  },
  mutations: {
    VIDEOS_SET_LIST(state, data) {
      state.videos = data;
    },
  },
};
