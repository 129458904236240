import { inpeaceAPI } from '@/config/axios/index';

export default {
  getNotesList(churchId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/nota-sermao?igreja=${churchId}&limit=999`,
    });
  },
  getNote(noteId) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/nota-sermao/${noteId}`,
    });
  },
  putNote(noteId, noteData) {
    return inpeaceAPI({
      method: 'PUT',
      url: `${process.env.VUE_APP_API_V1}/nota-sermao/${noteId}`,
      data: noteData,
    });
  },
  deleteNote(noteId) {
    return inpeaceAPI({
      method: 'DELETE',
      url: `${process.env.VUE_APP_API_V1}/nota-sermao/${noteId}`,
    });
  },
  postNote(churchId, noteData) {
    return inpeaceAPI({
      method: 'POST',
      url: `${process.env.VUE_APP_API_V1}/nota-sermao/?igreja=${churchId}`,
      data: noteData,
    });
  },
};
