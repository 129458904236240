import orgAPI from '@/api/organization';

export default {
  state: {
    clients: null,
    organization: null,
    position: null,
  },
  getters: {
    organizationClients: state => state.clients,
    churchOrganazation: state => state.organization,
    userPosition: state => state.position,
  },

  actions: {
    async getOrganizationClients({ commit, getters }, position) {
      try {
        const response = await orgAPI.getClients({
          slug: getters.churchData.slugOrganizacao,
          position,
        });

        commit('ORG_SET_CLIENTS_DATA', response.data.clientes);

        return Promise.resolve(response);
      } catch (error) {
        commit('ORG_SET_CLIENTS_DATA', null);

        return Promise.reject(error);
      }
    },

    async getChurchOrganization({ commit, getters }) {
      try {
        const response = await orgAPI.getOrganization(getters.churchData.slugOrganizacao);

        commit('SET_CHURCH_ORG', response.data.organizacao);

        return Promise.resolve(response);
      } catch (error) {
        commit('SET_CHURCH_ORG', null);

        return Promise.reject(error);
      }
    },

    async setUserLocation({ commit }, position) {
      commit('SET_USER_POSITION', position);

      return Promise.resolve(position);
    },
  },

  mutations: {
    ORG_SET_CLIENTS_DATA(state, payload) {
      state.clients = payload;
    },

    SET_CHURCH_ORG(state, payload) {
      state.organization = payload;
    },

    SET_USER_POSITION(state, payload) {
      state.position = payload;
    },
  },
};
