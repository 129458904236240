import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'churchBaseLoaded',
      'churchVisual',
      'churchData',
    ]),
  },

  async created() {
    this.appSetLoadingState({ context: 'GLOBAL', status: true });
    await this.bootstrapping();
  },

  mounted() {
    if (this.churchBaseLoaded) {
      this.appSetLoadingState({ context: 'GLOBAL', status: false });
    }
  },

  methods: {
    ...mapActions([
      'churchGetData',
      'appSetMessageBag',
      'churchGetModules',
      'churchGetVisual',
      'churchGetMenuItems',
      'appSetLoadingState',
      'churchGetPaymentMethods',
    ]),

    /**
     * Here is the requests that are requirement for the app loads
     * If any of these requests fails, then the users are redirected to the error page
     */
    async bootstrapping() {
      const { style } = document.documentElement;

      try {
        await this.churchGetData();

        if(!this.churchData.flagAtivo) {
          this.$router.replace({ name: 'ErrorPage' });

          return;
        }

        await Promise.all([
          this.churchGetModules(),
          this.churchGetVisual(),
          this.churchGetPaymentMethods(),
          this.churchGetMenuItems(),
        ]);

        // Setting church primary color
        style.setProperty('--primary', this.churchVisual.corApp, 'important');
        style.setProperty('--secondary', `${this.churchVisual.corApp}26`, 'important');

        this.appSetLoadingState({ context: 'GLOBAL', status: false });
      } catch (error) {
        this.appSetMessageBag({
          type: 'warning',
          message: this.$t('HOME.MESSAGE.ERROR.GENERIC'),
          context: 'ERROR_PAGE',
        });

        this.appSetLoadingState({ context: 'GLOBAL', status: false });
        await this.$router.replace({ name: 'ErrorPage' }).catch(() => {});
      }
    },
  },
};
