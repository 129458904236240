import noteApi from '@/api/notes';

export default {
  state: {
    note: null,
    newNote: null,
    notes: [],
    noteLive: null,
  },

  getters: {
    notesGetList: (state) => state.notes,
    notesGetSingle: (state) => state.note,
    notesGetNewSingle: (state) => state.newNote,
    notesLiveId: (state) => state.noteLive,
  },

  actions: {
    async fetchNotes({ commit, getters }) {
      try {

        const res = await noteApi.getNotesList(getters.churchData.id);

        commit('NOTES_SET_LIST', res.data.data);

        return Promise.resolve(res);
      } catch (e) {
        commit('NOTES_SET_LIST', null);

        return Promise.reject(e);
      }
    },

    async fetchNote({ commit }, noteId) {
      try {
        const res = await noteApi.getNote(noteId);

        commit('NOTES_SET_SINGLE', res.data);

        return Promise.resolve(res);
      } catch (e) {
        commit('NOTES_SET_SINGLE', null);

        return Promise.reject(e);
      }
    },

    setNote({ commit }, payload) {
      commit('NOTES_SET_SINGLE', payload);
    },

    clearNote({ commit }) {
      commit('NOTES_SET_SINGLE', null);
    },

    setNewNote({ commit }, payload) {
      commit('NOTES_SET_NEW_SINGLE', payload);
    },

    clearNewNote({ commit }) {
      commit('NOTES_SET_NEW_SINGLE', null);
    },

    setNoteLive({ commit }, payload) {
      commit('NOTES_SET_LIVE', payload);
    },

    clearNoteLive({ commit }) {
      commit('NOTES_SET_LIVE', null);
    },
  },

  mutations: {
    NOTES_SET_LIST(state, data) {
      state.notes = data;
    },

    NOTES_SET_SINGLE(state, data) {
      state.note = data;
    },

    NOTES_SET_NEW_SINGLE(state, data) {
      state.newNote = data;
    },

    NOTES_SET_LIVE(state, data) {
      state.noteLive = data;
    },
  },
};
