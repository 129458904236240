import { inpeaceAPI } from '@/config/axios/index';

export default {
  getModuleData(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${churchSlug}/plano-assinatura-configuracao/`,
      data: null,
    });
  },

  getPlans(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${churchSlug}/plano-assinatura/`,
      data: null,
    });
  },

  getHomeContent(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${churchSlug}/plano-assinatura-home/`,
      data: null,
    });
  },

  getFeaturedContent(churchId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/plano-assinatura-conteudo/?igreja=${churchId}&destaque=true`,
      data: null,
    });
  },

  getSingleData(contentId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/plano-assinatura-conteudo/${contentId}/`,
      data: null,
    });
  },

  getRelatedContent(churchId, tagId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/plano-assinatura-conteudo/?igreja=${churchId}&tag=${tagId}`,
      data: null,
    });
  },

  getSearchContent(churchId, searchString) {
    return inpeaceAPI({
      method: 'GET',
      url: `/plano-assinatura-conteudo/?igreja=${churchId}${searchString}`,
      data: null,
    });
  },

  getCollectionContent(churchId, collectionId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/plano-assinatura-conteudo/?igreja=${churchId}&coletanea=${collectionId}`,
      data: null,
    });
  },

  getContentData(contentId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/plano-assinatura-conteudo/${contentId}/`,
      data: null,
    });
  },

  postAddToMyList({ contentId, churchId, userId }) {
    return inpeaceAPI({
      method: 'POST',
      url:
        `/usuario-plano-assinatura-conteudo/?planoAssinaturaConteudo=${contentId}&igreja=${churchId}&usuario=${userId}`,
      data: null,
    });
  },

  deleteRemoveFromMyList({ contentId }) {
    return inpeaceAPI({
      method: 'DELETE',
      url:
        `/usuario-plano-assinatura-conteudo/${contentId}/`,
      data: null,
    });
  },

  getUserMyListContent(churchId, userId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario-plano-assinatura-conteudo/?usuario=${userId}&igreja=${churchId}`,
      data: null,
    });
  },

  getUserSubscriptions(userId, churchId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario/${userId}/usuario-plano-assinatura/?igreja=${churchId}`,
      data: null,
    });
  },
};
