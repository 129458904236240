import { inpeaceAPI } from '@/config/axios/index';

export default {
  getCelulasList(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/celula/`,
    });
  },

  getUserCelulasList(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/celula/?usuario=1`,
    });
  },

  getCelula(churchSlug, celulaId) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/celula/${celulaId}`,
    });
  },

  getMeetingsList(churchSlug, celulaId) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/celula/${celulaId}/reuniao-registro/`,
    });
  },

  getCelulaMeeting(churchSlug, celulaId, meetingId) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/celula/${celulaId}/reuniao-registro/${meetingId}`,
    });
  },

  postRequestCelula(churchSlug, celula) {
    return inpeaceAPI({
      method: 'POST',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/solicitacao-celula/admissao`,
      data: celula,
    });
  },


  getStudieSingle(churchSlug, studieId) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/estudo/${studieId}`,
    });
  },

  getStudiesList(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/estudo/`,
    });
  },

  getStudies(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/configuracao-estudo`,
    });
  },
};
