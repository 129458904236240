import churchApi from '../../api/church';

const moduleDefaultState = () => ({
  church: null,
  paymentMethods: null,
  menuItems: null,
  modules: null,
  visual: null,
  client: null,
  banners: null,
});

export default {
  state: moduleDefaultState(),

  getters: {
    churchData: (state) => state.church,
    churchId: (state) => state.church?.id,
    churchSlug: (state) => state.church?.slug,
    churchUuid: (state) => state.church?.uuid,
    churchPaymentMethods: (state) => state.paymentMethods,
    churchMenuItems: (state) => state.menuItems,
    churchModules: (state) => state.modules,
    churchVisual: (state) => state.visual,
    churchHasModule: (state, getters) => (moduleCode) => (getters.churchModules
      ? getters.churchModules.find((module) => module.codigo === moduleCode) : false),
    churchBaseLoaded: (state, getters) => !!getters.churchModules && !!getters.churchData,
    churchLanguage: (state, getters) => (getters.churchData
      ? getters.churchData.idioma.replace(/_/g, '-').toLowerCase() : 'en'),
    churchCode: (state) => state.church?.codigo,
    slugOrganizacao: (state) => state.church?.slugOrganizacao,
    clientData: (state) => state.client?.[0],
    churchBanners: (state) => state.banners,
  },

  actions: {
    churchGetData({ commit }) {
      const { hostname } = window.location;
      let request;

      /**
       * Creating the request based on hostname
       * if hostname has .inpeaceapp. uses the first part of url
       * otherwise sending entire hostname to api to search data
      */
      if (hostname.includes('.inpeaceapp.')) {
        request = churchApi.getChurchByCode(hostname.split('.')[0]);
      } else {
        request = churchApi.getChurchByHostname(hostname);
      }

      return request.then((response) => {
        commit('CHURCH_SET_DATA', response);

        return response;
      });
    },

    churchGetPaymentMethods({ commit, getters }) {
      return churchApi.getPaymentMethods(getters.churchSlug).then((response) => {

        commit('CHURCH_SET_PAYMENT_METHODS', response.data);

        return response;
      });
    },

    churchGetMenuItems({ commit, getters }) {
      return churchApi.getMenuItems(getters.churchData.id).then((response) => {
        commit('CHURCH_SET_MENU_ITENS', response.data.igrejaAppMenu);

        return response;
      });
    },

    churchGetModules({ commit, getters }) {
      return churchApi.getModules(getters.churchSlug).then((response) => {
        commit('CHURCH_SET_MODULES', response.data.modulos);

        return response;
      });
    },

    churchGetVisual({ commit, getters }) {
      return churchApi.getVisual(getters.churchSlug).then((response) => {
        commit('CHURCH_SET_VISUAL', response.data.igrejaVisual);

        return response;
      });
    },


    async churchGetBanners({ commit , getters }) {
      try {
        const response = await churchApi.getBanners(getters.churchData.id);

        commit('CHURCH_SET_BANNERS', response.data.banners);


        return Promise.resolve(response);
      } catch (error) {
        commit('CHURCH_SET_BANNERS', null);

        return Promise.reject(error);
      }
    },

    async churchGetClientByCode({ commit , getters }) {
      try {
        const response = await churchApi.getClientData(getters.slugOrganizacao);

        commit('CHURCH_SET_CLIENT_DATA', response.data.clientes);

        return Promise.resolve(response);
      } catch (error) {
        commit('CHURCH_SET_CLIENT_DATA', null);

        return Promise.reject(error);
      }
    },
  },

  mutations: {
    CHURCH_SET_DATA(state, church) {
      state.church = church;
    },

    CHURCH_SET_PAYMENT_METHODS(state, payload) {
      state.paymentMethods = payload;
    },

    CHURCH_SET_MENU_ITENS(state, payload) {
      state.menuItems = payload;
    },

    CHURCH_SET_MODULES(state, payload) {
      state.modules = payload;
    },

    CHURCH_SET_VISUAL(state, payload) {
      state.visual = payload;
    },

    CHURCH_SET_CLIENT_DATA(state, data) {
      state.client = data;
    },

    CHURCH_SET_BANNERS(state, payload) {
      state.banners = payload;
    },
  },
};
