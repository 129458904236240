export default {
  state: {
    publicationType: null,
  },
  getters: {
    publicationType: state => state.publicationType,
  },
  mutations: {
    SET_PUBLICATION_TYPE(state, payload) {
      state.publicationType = payload;
    },
  },
  actions: {
    setPublicationType({ commit }, payload) {
      commit('SET_PUBLICATION_TYPE', payload);
    },
  },
};
