import api from '@/api/celula';

export default {
  state: {
    celulas: [],
    celulasUser: [],
    celula: null,
    studiesFlag: null,
    studiesList: null,
    studieSingle: null,
  },

  mutations: {
    CELULAS_SET_LIST(state, data) {
      state.celulas = data;
    },

    CELULAS_SET_USER_LIST(state, data) {
      state.celulasUser = data;
    },

    CELULAS_SET_SINGLE(state, data) {
      state.celula = data;
    },

    CELULAS_SET_STUDIES_FLAG(state, data) {
      state.studiesFlag = data;
    },

    CELULAS_SET_STUDIES_LIST(state, data) {
      state.studiesList = data;
    },
    CELULAS_SET_STUDIE_SINGLE(state, data) {
      state.studieSingle = data;
    },
  },

  actions: {
    async fetchCelulas({ commit, getters }) {
      try {
        const res = await api.getCelulasList(getters.churchData.slug);

        commit('CELULAS_SET_LIST', res.data.celulas);

        return Promise.resolve(res);
      } catch (e) {
        commit('CELULAS_SET_LIST', null);

        return Promise.reject(e);
      }
    },

    async fetchUserCelulas({ commit, getters }) {
      try {
        const res = await api.getUserCelulasList(getters.churchData.slug);

        commit('CELULAS_SET_USER_LIST', res.data.celulas);

        return Promise.resolve(res);
      } catch (e) {
        commit('CELULAS_SET_USER_LIST', []);

        return Promise.reject(e);
      }
    },

    async showCelula({ commit, getters }, celulaId) {
      try {
        const res = await api.getCelula(getters.churchData.slug, celulaId);

        commit('CELULAS_SET_SINGLE', res.data.celula);

        return Promise.resolve(res);
      } catch (e) {
        commit('CELULAS_SET_SINGLE', null);

        return Promise.reject(e);
      }
    },

    async fetchStudiesFlag({ commit, getters }) {
      try {
        const res = await api.getStudies(getters.churchData.slug);

        commit('CELULAS_SET_STUDIES_FLAG', res.data.data.flagConteudoPublico);

        return Promise.resolve(res);
      } catch (e) {
        commit('CELULAS_SET_STUDIES_FLAG', null);

        return Promise.reject(e);
      }
    },

    async fetchStudiesList({ commit, getters }) {
      try {
        const res = await api.getStudiesList(getters.churchData.slug);

        commit('CELULAS_SET_STUDIES_LIST', res.data.estudos);

        return Promise.resolve(res);
      } catch (e) {
        commit('CELULAS_SET_STUDIES_LIST', null);

        return Promise.reject(e);
      }
    },

    async fetchStudieSingle({ commit, getters }, studieId) {
      try {
        const res = await api.getStudieSingle(getters.churchData.slug, studieId);

        commit('CELULAS_SET_STUDIE_SINGLE', res.data.estudo);

        return Promise.resolve(res);
      } catch (e) {
        commit('CELULAS_SET_STUDIE_SINGLE', null);

        return Promise.reject(e);
      }
    },
  },

  getters: {
    celulasGetList: (state) => state.celulas,
    celulasGetSingle: (state) => state.celula,
    celulasGetUserCelulas: (state) => state.celulasUser,
    celulasGetStudiesFlag: (state) => state.studiesFlag,
    celulasGetStudiesList: (state) => state.studiesList,
    celulasGetStudieSingle: (state) => state.studieSingle,
  },
};
