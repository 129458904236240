/* eslint-disable vue/max-len */
import { inpeaceAPI, inpeaceBFF } from '@/config/axios/index';

export default {
  eventPayment(payload) {
    return inpeaceAPI({
      method: 'POST',
      url: `/usuario/${payload.requestData.usuario}/evento/${payload.requestData.evento}/inscricao/`,
      data: payload.requestData,
    });
  },

  inpcPlayPayment(payload) {
    return inpeaceAPI({
      method: 'POST',
      url: '/usuario-plano-assinatura/',
      data: payload.requestData,
    });
  },

  givePayment(payload) {
    return inpeaceAPI({
      method: 'POST',
      url: '/pagamento/',
      data: payload.requestData,
    });
  },
  getPaymentFinalValue(metodoPagamento, motivos) {
    return inpeaceAPI({
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `/pagamento/calcular-taxas?metodoPagamento=${metodoPagamento}${motivos}`,
    });
  },
  getEventFinalValue(eventId, value, metodoPagamento) {
    return inpeaceAPI({
      method: 'GET',
      url: `/evento/${eventId}/calcular-taxas?valor=${value}&metodoPagamento=${metodoPagamento}`,
    });
  },
  creditCardInstallments(igreja , price,  flagJuros, flagAssumirTaxa, valorTaxas, parcelas){
    return inpeaceAPI({
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `/igreja/${igreja}/calcula-taxas-parcelamento-juros?valor=${price}&flagJuros=${flagJuros}&flagTaxasAdministrativas=${flagAssumirTaxa}&valorTaxas=${valorTaxas}&maxParcelas=${parcelas}`,
    });
  },
  getDefaultPaymentGateway() {
    return inpeaceAPI({
      method: 'GET',
      url: '/global-settings/default-payment-gateway',
    });
  },

  /**
   * @param {string} uuid
   * @param {Object} data
   * @param {string} data.holderName
   * @param {string} data.cardNumber
   * @param {string} data.expirationMonth
   * @param {string} data.expirationYear
   * @param {string} data.securityCode
   * @returns {string}
   */
  postPaymentZoop(uuid, data) {
    return   inpeaceBFF({
      method: 'POST',
      url: `/general/payment/church/${uuid}/card/token`,
      data,
    });
  },
};
