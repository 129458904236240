import { inpeaceAPI } from '@/config/axios/index';

export default {
  getVideosList(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/video/${churchSlug}`,
    });
  },
};
