import Vue from 'vue';
import VueGtag from 'vue-gtag';
import router from '@/router/index';

Vue.use(VueGtag, {
  includes: [
		{
			id: 'UA-141237939-3',
		},
	],
  config: {
    id: process.env.VUE_APP_ANALYTICS_KEY,
  },
  appName: 'Inpeace Web',
}, router);
