import { inpeaceAPI } from '@/config/axios/index';

export default {
  getAnchorPodcastData({ anchorCode, page }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/podcast/anchor/${anchorCode}/?page=${page}&limit=10`,
      data: null,
    });
  },
};
