import { inpeaceAPI } from '../config/axios/index';

export default {
  getChurchByCode(churchCode) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/codigo/${churchCode}`,
      data: null,
    }).then((response) => {
      if ('status' in response.data && !response.data.status) {
        throw response;
      }

      return Promise.resolve(response.data);
    }).catch((error) => Promise.reject(error.response));
  },

  getChurchByHostname(hostname) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/?hostname=${hostname}`,
      data: null,
    }).then((response) => {
      if (!response.data.clientes.length) {
        throw response;
      }

      return Promise.resolve(response.data.clientes[0]);
    }).catch((error) => Promise.reject(error.response));
  },

  getPaymentMethods(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${churchSlug}/metodo-pagamento/`,
      data: null,
    });
  },

  getMenuItems(churchId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja-app-menu?igreja=${churchId}`,
      data: null,
    });
  },

  getModules(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${churchSlug}/modulo/`,
      data: null,
    });
  },

  getVisual(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${churchSlug}/visual/`,
      data: null,
    });
  },

  getBanners(churchId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/banner/?igreja=${churchId}`,
    });
  },

  getClientData(slug) {
    return inpeaceAPI({
      method: 'GET',
      url: `cliente/?organizacao=${slug}`,
      data: null,
    });
  },

  getCongregations(churchId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/congregacao/?igreja=${churchId}`,
    });
  },
};
