module.exports = {
  CONTENT: {
    ABOUT_US: {
      BUTTON: {
        DIRECTIONS: 'Como chegar',
      },
      TITLE: {
        INTERNAL: {
          ABOUT: 'História',
          CHURCH: 'A Igreja',
          CONTACT: 'Contato',
          LOCATION: 'Localização',
          SOCIAL: 'Redes Sociais',
        },
        PAGE: {
          HOME: 'Sobre nós',
        },
      },
    },
    ACCESS: {
      BUTTON: {
        LOGIN: 'Entrar',
        NEXT: 'Próximo',
        RECOVER_PASSWORD: 'Esqueci minha senha',
        REGISTER: 'Cadastrar',
      },
      MESSAGE: {
        ERROR: {
          INACTIVE_ACCOUNT: 'Você já possuí conta, mas não conseguimos enviar o email de ativação, favor tentar novamente.',
        },
        SUCCESS: {
          INACTIVE_ACCOUNT: 'Você já possuí conta, mas ela não está ativa. Estamos enviando um email de ativação para você.',
          RECOVER_PASSWORD: 'Enviamos um email de recuperação de senha para você.',
        },
      },
      MODAL: {
        ACTIVE_ACCOUNT: {
          ACTION: 'Entendi',
          DESCRIPTION: 'Foi enviada a ativação da conta para o seu e-mail cadastrado.',
          TITLE: 'Ative sua <span class="title__featured">Conta InPeace</span>',
        },
        INPEACE_ACCOUNT: {
          ACTIVATED: {
            LINE_ONE: 'Verificamos que você já possui uma <span class="line__featured">Conta InPeace.</span>',
          },
          AGREE: {
            ACTION: 'Entendi',
            MESSAGE: 'Faça login ou recupere a sua senha.',
          },
          ENTRY: 'Olha, que legal!',
          INFO: {
            LINE_ONE: 'A Conta InPeace agora é única.',
            LINE_TWO: 'Com ela você tem acesso a todos os nossos aplicativos.',
          },
          UNACTIVATED: {
            LINE_ONE: 'Verificamos que você possui uma <span class="line__featured">Conta InPeace.</span>',
            LINE_TWO: 'Ative sua conta e complete o cadastro.',
          },
        },
        RECOVER_PASSWORD: {
          ACTION: 'Entendi',
          TEXT: 'Foi enviada a recuperação de senha para o seu e-mail cadastrado.',
        },
      },
      SUBTITLE: 'Insira seu e-mail para continuar',
      TITLE: {
        ACCESS: 'Boas vindas!',
        CLIENT_REGISTER: 'Completar Cadastro',
        INPEACE_REGISTER: 'Cadastrar',
        PAGE: 'Acessar',
      },
    },
    ALERT_MESSAGE: {
      TITLE: 'Erro',
    },
    CELULAS: {
      INDEX: {
        CELULAS: {
          AVAILABLE: '{groups} disponíveis',
          USER: '{groups} que faço parte',
        },
        EMPTY: {
          BUTTON: 'Voltar',
          SUBTITLE: 'Quando estiver disponível você poderá visualizar ou solicitar sua participação.',
          TITLE: 'Ainda não há {grupos} aqui',
        },
        FILTERS: {
          INPUT: 'Buscar por nome do grupo',
          SEARCH: 'Faça sua busca',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
            SEARCH: 'Não há resultados para essa busca',
            TERM: 'Que tal tentar com outro nome ou palavra-chave?',
          },
        },
      },
      MEETINGS: {
        INDEX: {
          BUTTON: 'VER ESTUDO',
          EMPTY: {
            SUBTITLE: 'Quando uma reunião for adicionada ficará disponível para sua visualização.',
            TITLE: 'Ainda não há reuniões aqui',
          },
          START_FINISH_TIME: '{start} às {finish}',
          TAG: 'VER TEMA',
          TITLE: 'Reuniões',
        },
        SINGLE: {
          START_FINISH_TIME: '{start} às {finish}',
          TITLE: 'Reuniões',
        },
      },
      MODULE_NAME: 'Grupos',
      SINGLE: {
        ACTIONS: {
          BUTTON: 'Participar',
          BUTTONS: {
            CANCEL: 'Cancelar',
            CONFIRM: 'Confirmar',
            MEETINGS: 'Reuniões',
            YES: 'Sim',
          },
          PARTICIPATE: 'Para visualizar reuniões e ter acesso a outras informações baixe o app',
          REQUEST: 'A sua solicitação de participação será enviara ao líder. Deseja confirmar?',
          TEXT: 'Acesse a sua conta ou cadastre-se para solicitar sua participação.',
        },
        INFO: {
          ADDRESS: 'Endereço',
          BUTTON: 'Como chegar?',
          FUNCTION: 'Líder de {group}',
          RESPONSIBLE: 'Responsável',
          REUNION: 'Reunião',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi encontrada uma célula correspondente.',
            REQUEST: 'Não foi possível enviar sua requisição.',
          },
          SUCCESS: 'Sua solicitação foi enviada com sucesso!',
        },
      },
      STUDIES: {
        MODULE_NAME: 'Estudos disponíveis',
      },
      SUCCESS: {
        BUTTON: 'Voltar',
        SUBTITLE: 'Agradecemos o seu interesse em participar.',
        TITLE: 'Sua solicitação foi enviada com sucesso',
      },
    },
    CHAT: {
      BUTTON: {
        ACCESS_LOGIN: 'Entrar / Cadastrar',
        ACCESS_NO_LOGIN: 'Participar do chat',
        CALL_ACCESS_MODAL: 'Entre para participar',
        DELETE_ALL: 'Apagar todas',
        DELETE_SINLGE: 'Apagar',
        MODAL_DELETE_CANCEL: 'Cancelar',
        MODAL_DELETE_CONFIRM: 'Confirmar',
      },
      FORM: {
        BUTTON: {
          SEND_MESSAGE: 'Enviar',
        },
        INPUT: {
          NEW_MESSAGE: 'Envie uma mensagem',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível enviar sua mensagem no momento.',
          },
        },
      },
      MESSAGES: {
        JOIN: 'entrou',
        MOMENT: {
          COMMIT_TO_JESUS: {
            BUTTON: {
              DISMISS: 'Agora não',
              RAISE_HAND: 'Levantar a mão',
            },
            TEXT: {
              COMMITED: {
                SUBTITLE: 'Vamos orar com você.',
                TITLE: 'Hoje, você está começando uma nova vida com Jesus!',
              },
              CREATED: {
                SUBTITLE: 'Levante a mão e nos avise',
                TITLE: 'Eu entrego a minha vida a Jesus.',
              },
            },
          },
        },
      },
      TEXT: {
        ACCESS_MODAL: 'Para participar entre na sua conta, faça seu cadastro ou insira o seu nome.',
        CONNECTING: 'Conectando...',
        MODAL_DELETE_ALL_USER_MESSAGES: 'Você confirma a remoção de todas as mensagens do usuário {username}?',
        MODAL_DELETE_SINGLE_MESSAGE: 'Você confirma a remoção da mensagem do usuário {username}?',
        NO_MESSAGES: 'Ainda não há mensagens',
      },
      TITLE: {
        ACCESS_MODAL: 'Nos diga seu nome',
        MODAL_DELETE_ALL_USER_MESSAGES: 'Excluir mensagens?',
        MODAL_DELETE_SINGLE_MESAGE: 'Excluir mensagem?',
      },
    },
    CHECKOUT: {
      BUTTON: {
        METHOD: {
          ACH: 'ACH',
          BOLETO: 'Boleto',
          CREDIT_CARD: 'Cartão de Crédito',
          MONEY: 'Dinheiro ou transferência',
          PAYPAL: 'PayPal',
          PICPAY: 'PicPay',
          PIX_MANUAL: 'Pix',
        },
      },
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Algo deu errado. Atualize a página e tente novamente.',
        },
        INFO: {
          BR_OPTIONS: 'Pagamentos via Pix e boleto estão disponíveis somente para endereços no Brasil. Se você está fora do Brasil, utilize outra forma de pagamento ou atualize o endereço cadastrado.',
        },
      },
      TEXT: {
        CREDIT_CARD: {
          MODAL: {
            BUTTON: {
              CANCEL: 'NÃ0',
              YES: 'SIM',
            },
            SUBTITLE: 'Caso precise utilizar novamente o cartão, será necessário recadastrá-lo.',
            TITLE: 'Deseja realmente excluir o cartão de final {number} ?',
          },
        },
        INSTALLMENTS: {
          SELECT: {
            WITH_INTEREST: 'com juros',
            WITHOUT_INTEREST: 'sem juros',
          },
          TITLE: 'Selecione a quantidade de parcelas desejada:',
        },
        PAYMENT: {
          PIX: {
            COPY_KEY: 'Copiar',
            EVENT: 'Se o pagamento não for confirmado, não se preocupe. O pedido será cancelado automaticamente.',
            HELP: 'O código é válido somente até às {hour} de {day}.',
          },
          PIX_MANUAL: {
            COPY_KEY: 'Copiar',
            KEY_TYPE: {
              CNPJ: 'CNPJ',
              CPF: 'CPF',
              EMAIL: 'E-mail',
              PHONE: 'Celular',
              RANDOM: 'Aleatória',
            },
            PIX_KEY_TEXT: 'Chave Pix',
          },
          PROCESSED_BY: 'Processado Por',
        },
        THANK_YOU: {
          DOWNLOAD_APP: 'Você ainda não tem o aplicativo {0}? Faça o download agora e siga tudo sobre {0}!',
        },
      },
      TITLE: {
        PAGE: {
          HOME: 'Pagamento',
          THANK_YOU: 'Obrigado',
        },
      },
    },
    DEVOTIONAL: {
      EMPTY: {
        BUTTON: 'Voltar ao início',
        SUBTITLE: 'Quando um conteúdo for criado você poderá visualizar as informações.',
        TITLE: 'Ainda não há publicações aqui',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filtrar por categoria',
          INPUT: 'Buscar por título ou palavra-chave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
            SEARCH_DESCRIPTION: 'Que tal tentar com outro título ou palavra-chave?',
            SEARCH_TITLE: 'Não há resultados para essa busca',
          },
        },
      },
      MODULE_NAME: 'Devocionais',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
            NOT_FOUND: 'O conteúdo não existe ou está indisponível.',
          },
        },
      },
    },
    EBD: {
      EMPTY: {
        BUTTON: 'Voltar ao início',
        SUBTITLE: 'Quando um conteúdo for criado você poderá visualizar as informações.',
        TITLE: 'Ainda não há publicações aqui',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filtrar por categoria',
          INPUT: 'Buscar por título ou palavra-chave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
            SEARCH_DESCRIPTION: 'Que tal tentar com outro título ou palavra-chave?',
            SEARCH_TITLE: 'Não há resultados para essa busca',
          },
        },
      },
      MODULE_NAME: 'Escola Bíblica Dominical',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
            NOT_FOUND: 'O conteúdo não existe ou está indisponível.',
          },
        },
      },
    },
    ERROR_PAGE: {
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
        },
      },
    },
    EVENT: {
      ALL: {
        BUTTON: {
          BACK_HOME: 'Voltar para o início',
        },
        EMPTY: {
          SUBTITLE: 'Você poderá se inscrever quando for adicionado um novo evento.',
          TITLE: 'Ainda não há eventos aqui',
        },
        MESSAGE: {
          ERROR: {
            NO_EVENTS: '{church} não tem eventos no momento.',
          },
        },
        TEXT: {
          EXCLUSIVE_CONTENT: 'Conteúdo exclusivo',
        },
        TITLE: {
          INTERNAL: {
            CHECK_OUR_EVENTS: 'Confira todos os nossos eventos e cursos',
          },
          PAGE: 'Eventos',
        },
      },
      CHECKOUT: {
        BUTTON: {
          PAYMENT: {
            CONFIRM: 'Confirmar',
          },
          THANK_YOU: {
            DOWNLOAD_BOLETO: 'Ver boleto',
            OPEN_PICPAY: 'Abrir PicPay',
            SUBSCRIVE_AGAIN: 'Deseja fazer outra inscrição?',
            VIEW_MORE: 'Ver mais eventos/cursos',
          },
        },
        TEXT: {
          PAYMENT: {
            EXCLUSIVE_CONTENT: 'Ingresso com conteúdo exclusivo',
            FEES_LABEL: '(com taxas)',
            METHODS: {
              BOLETO: 'Você receberá o boleto bancário por e-mail e sua inscrição será efetuada após a confirmação do pagamento.',
              DINHEIRO: {
                DAYS_TO_PAY: 'Você tem até {days} dias para efetuar o pagamento do ingresso.',
                RULE_MESSAGE: 'Entre em contato com o responsável pelo evento para mais informações.',
              },
              FREE: 'Este evento é gratuito, mas o ingresso ainda é necessário. Para mais informações, entre em contato com a Igreja.',
              PAYPAL: 'Na próxima tela você poderá acessar o PayPal e efetivar o pagamento. Seu ingresso só será válido após a confirmação do pagamento.',
              PICPAY: {
                RULE_MESSAGE: 'Confirme no botão abaixo, baixe ou acesse o seu aplicativo PicPay e conclua a sua inscrição.',
              },
              PIX: {
                RULE_MESSAGE: 'Confirme no botão abaixo para prosseguir com o seu pagamento.',
              },
            },
            TOTAL_PRICE: 'Total:',
            TICKET_PRICE: 'Valor do ingresso:',
            SERVICE_PRICE: 'Taxa de serviço:',
            PROCESS_PRICE: 'Taxa de processamento: ',
            USER: 'Utilizador',
          },
          THANK_YOU: {
            EXCLUSIVE_CONTENT: 'Ingresso com conteúdo exclusivo',
            FINAL_MESSAGE: 'Você pode acompanhar as informações de inscrição no aplicativo {name}.',
            METHODS: {
              BOLETO: {
                em_analise: 'Faça download do boleto bancário no link abaixo ou pelo seu e-mail. Sua inscrição será efetivada após a confirmação do pagamento.',
                pendente: 'Faça download do boleto bancário no link abaixo ou pelo seu e-mail. Sua inscrição será efetivada após a confirmação do pagamento.',
              },
              CREDIT_CARD: {
                aprovado: 'Sua inscrição foi realizada com sucesso.',
                em_aberto: 'Sua inscrição está sendo processada. Você receberá um e-mail com a confirmação e logo seus ingressos estarão disponíveis na plataforma.',
                em_analise: 'Sua inscrição está sendo processada. Você receberá um e-mail com a confirmação e logo seus ingressos estarão disponíveis na plataforma.',
                pendente: 'Sua inscrição está sendo processada. Você receberá um e-mail com a confirmação e logo seus ingressos estarão disponíveis na plataforma.',
              },
              DINHEIRO: {
                aguardando: 'Efetue o pagamento do ingresso. Você receberá um e-mail com a confirmação e logo seus ingressos estarão disponíveis na plataforma.',
              },
              FREE: {
                SUCCESS: 'Sua inscrição foi realizada com sucesso e seu ingresso foi enviado para o seu e-mail cadastrado.',
              },
              PICPAY: {
                aguardando: 'Abra o PicPay e finalize sua inscrição',
              },
              PIX: {
                pendente: 'Abra o aplicativo do seu banco, escaneie o Código QR ou cole a chave para efetuar o pagamento',
              },
            },
            PIX: 'Após efetuar o pagamento da inscrição você receberá um e-mail de confirmação e seus ingressos estarão disponíveis na plataforma.',
            TICKET_DATA: {
              ADDRESS: 'Local',
              BUYER: 'Comprador',
              DATE: 'Data',
              EVENT: 'Evento',
              GET_IN_TOUCH: 'Entre em contato para mais informações.',
              LOCATION: 'Local',
              TICKET: 'Ingresso',
              TICKETS: 'Ingressos',
              TIME: 'Hora',
              USER: 'Utilizador',
            },
            TITLE: 'Agradecemos a sua inscrição',
          },
        },
        TITLE: {
          INTERNAL: {
            PAYMENT: 'Pagamento',
            TICKETS: 'Ingressos',
          },
        },
      },
      GLOBAL: {
        TEXT: {
          DATE: {
            DIFFERENT: '{start} {monthStart} à {end} {monthEnd}',
            SAME: '{start} {monthEnd}',
          },
        },
      },
      QUESTIONS: {
        BUTTON: {
          NEXT: 'Continuar',
        },
        TEXT: {
          STEP: 'Ingresso <span class="numbers__number">{actual}/{quantity}</span>',
          TICKET_DATA: 'Digite o nome de quem usará o ingresso.',
        },
        TITLE: {
          INTERNAL: {
            IMPORTANT_INFO: 'Informação importante',
            TICKET_DATA: 'Dados do Ingresso',
            TICKET_TITLE: 'Ingresso {name}',
          },
        },
      },
      SINGLE: {
        BUTTON: {
          STATUS: {
            CLOSED: 'Indisponível',
            ONLINE: 'Acessar',
            REGISTER: 'Registrar',
            REGISTER_AGAIN: 'Registrar Novamente',
            UNAVAILABLE: 'Inscrições Indisponíveis',
          },
          TICKET_HAS_CONTENT: {
            FALSE: 'Ver meus ingressos',
            TRUE: 'Ver conteúdo',
          },
          VIEW_MORE: 'Ver mais eventos/cursos',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar o evento. Por favor, tente novamente.',
            NOT_FOUND: 'Este evento não está disponível ou o endereço está incorreto.',
          },
        },
        TEXT: {
          AGREE: 'Estou ciente dos termos',
          AVAILABLE_AT: 'Os ingressos estarão disponíveis a partir de {date}',
          DATE: {
            DIFFERENT: '{start} à {end}',
            SAME: '{start}',
          },
          EXCLUSIVE_CONTENT: 'Conteúdo exclusivo',
          EXCLUSIVE_CONTENT_INFO: 'Este evento conta com conteúdo exclusivo que estará disponível online.',
          INFO: {
            DATE: 'Data',
            LOCATION: 'Localização',
            SCHEDULE: 'Horário',
          },
          PAYMENT_METHODS: {
            BOLETO: 'Boleto',
            CREDIT_CARD: 'Cartão de Crédito',
            DINHEIRO: 'Dinheiro ou transferência',
            PAYPAL: 'PayPal',
            PICPAY: 'PicPay',
            PIX: 'Pix',
            PIX_MANUAL: 'Pix',
            TRANSFERENCIA: 'Transferência Bancária',
          },
          PRICE: {
            FREE: 'Gratuito',
            SINGLE: '{initial}',
            TWO: '{initial} à {final}',
          },
          SCHEDULE_TIME: '{start} às {end}',
          SHARE: '{0}',
        },
        TITLE: {
          CONTACT: 'Fale conosco em caso de dúvidas:',
          INTERNAL: {
            CHECK_IT: 'Confira também',
            COMPLEMENT_INSTALLMENTS: '{installments} vezes',
            INSTALLMENTS: 'Parcelamento:',
            INSTALLMENTS_OF: 'Até',
            PARTICIPATION_INFO: 'Informações de participação',
            PAYMENT_METHODS: {
              TITLE_PLURAL: 'Formas de Pagamento',
              TITLE_SINGULAR: 'Forma de Pagamento',
            },
            TICKET_FROM: 'Ingressos de',
          },
        },
      },
      TICKETS: {
        BUTTON: {
          NEXT: 'Continuar',
          READ_MORE: 'Ver mais',
        },
        TEXT: {
          EXCLUSIVE_CONTENT: 'Ingresso com conteúdo exclusivo',
          FEES: 'Taxas',
          FREE: 'Gratuito',
          PAYMENT_MESSAGE: 'Informações de pagamento em dinheiro ou transferência.',
          PRICE: 'Preço',
          QUANTITY: 'QTD',
          STATUS: {
            AVAILABLE_IN: 'Disponível a partir de {date}',
            AVAILABLE_UNTIL: '(Disponível até {date} ou esgotar)',
            EXPIRED: 'Expirado',
            SOLD_OUT: 'Esgotado',
            UNTIL: 'até {date}',
            USER_LIMIT: 'Você comprou o máximo permitido para este ingresso',
          },
          TOTAL_PRICE: 'Total:',
        },
        TITLE: {
          INTERNAL: {
            ATTENTION: 'Atenção',
            CHOOSE_EVENT_TICKETS: 'Escolha os ingressos para o evento',
            CHOOSE_TICKETS: 'Escolha os ingressos',
            PAYMENT_INFO: 'Informação de pagamento',
          },
          PAGE: 'Ingressos',
        },
      },
    },
    FOOTER: {
      ADDRESS: 'Endereço',
      ADMIN_ACCESS: 'Acesso Admin',
      CONTACT: 'Contato',
      DEVELOPED_BY: 'Desenvolvido por',
      FOLLOW_US: 'Siga-nos',
      LEGAL_NAME: 'Nome',
      TERMS: '<a href="{terms}" rel="noreferrer" class="link" target="_blank">Termos & Condições</a> and <a href="{privacy}" rel="noreferrer" class="link" target="_blank">Política de Privacidade</a>.',
    },
    FORM: {
      BUTTON: {
        CANCEL: 'Cancelar',
        DELETE: 'Excluir',
        EDIT: 'Editar',
        SAVE: 'Salvar',
        SHOW_CARD_LIST: 'Ver meus cartões',
        SHOW_MANUAL_CARD: '+ usar outro cartão',
      },
      LABEL: {
        ACCOUNT_NUMBER: 'Conta bancária',
        ACCOUNT_TYPE: 'Selecione o tipo de conta',
        ADDRESS: 'Endereço',
        AMOUNT: 'Quantidade',
        ANOTHER_CARD: {
          EVENT: 'Quer pagar com outro cartão?',
          GIVE: 'Quer doar com outro cartão?',
        },
        BIRTHDATE: 'Data de Nascimento',
        BIRTHDATE_HOLDER: 'Data de nascimento do titular',
        CARD_HOLDER: 'Títular do cartão',
        CHECKING_ACCOUNT: 'Conta corrente',
        CHILDREN: 'Filhos',
        CITY: 'Cidade',
        COMMENTS: 'Observações',
        COMPLEMENT: 'Complemento',
        CONFIRM_PASSWORD: 'Confirmar Senha',
        CONGREGATION: 'Congregação',
        COUNTRY: 'País',
        CPF: 'CPF',
        CPF_HOLDER: 'CPF do títular',
        CREATE_ACCOUNT: 'Deseja criar uma conta?',
        CREDIT_CARD_NUMBER: 'Número do cartão',
        CVV: 'CVV',
        DESCRIBE_HERE: 'O que gostaria de pedir?',
        DUE_DATE: 'Validade do cartão',
        EMAIL: 'E-mail',
        FEMALE: 'Feminino',
        FREQUENCY_IN_DAYS: 'Com qual frequência?',
        IMAGE: 'Adicione uma imagem',
        MALE: 'Masculino',
        MARITAL_DATE: 'Data de casamento',
        MARITAL_STATUS: 'Estado Civil',
        MOTIVE: 'Qual o motivo do seu pedido?',
        NAME: 'Nome',
        NEIGHBORHOOD: 'Bairro',
        NO: 'NÃO',
        NO_LOWERCASE: 'Não',
        NUMBER: 'Número',
        NUMBER_OF_CHILDREN: 'Quantidade de filhos',
        OCUPATION: 'Ocupação',
        PASSWORD: 'Senha',
        PHONE: 'Telefone',
        PRAYER_NAME: 'Qual o seu nome?',
        RECURRENCY: 'Deseja doar com recorrência?',
        ROUTING_NUMBER: 'Conta de roteamento',
        SAVE_CARD: 'Salvar cartão?',
        SAVINGS_ACCOUNT: 'Conta poupança',
        SELECT_CARD: 'Escolha o cartão que deseja utilizar',
        SEX: 'Sexo',
        SEX_MEN: 'Masculino',
        SEX_WOMEN: 'Feminino',
        STATE: 'Estado',
        STREET: 'Nome da rua',
        SURNAME: 'Sobrenome',
        VALUE: 'Preço',
        YES: 'SIM',
        YES_LOWERCASE: 'Sim',
        YOU_ARE_CARD_HOLDER: 'Você é o títular?',
        ZIPCODE: 'CEP',
        ZIPCODE_FOR_ALL: 'Zipcode',
      },
      MARITAL_STATUS: {
        CIVIL_MARIED: 'Casado civil',
        CIVIL_RELIGIOUS: 'Casado civil e religioso',
        DIVORCED: 'Divorciado(a)',
        LIVE_TOGETHER: 'Mora junto',
        MARIED: 'Casado(a)',
        SINGLE: 'Solteiro(a)',
        STABLE: 'União estável',
        WIDOWER: 'Viúvo(a)',
      },
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Ocorreu um erro ao tentar enviar os dados. Tente novamente.',
        },
      },
      PLACEHOLDER: {
        ACCEPTANCE_TERM_FIRST: 'Estou ciente e concordo com a',
        ACCEPTANCE_TERM_SECOND: 'e',
        ACCOUNT_NUMBER: 'Número da conta bancária',
        ACCOUNT_TYPE: 'Tipo de conta',
        ADDRESS: 'Informe sua rua',
        AMOUNT: 'Quantidade',
        BIRTHDATE: 'DD/MM/AAAA',
        BIRTHDATE_HOLDER: 'DD/MM/AAAA',
        CARD_HOLDER: 'Nome impresso no cartão',
        CITY: 'Insira sua cidade',
        CONFIRM_PASSWORD: 'Insira a senha novamente',
        CONGREGATION: 'Selecione sua congregação',
        COMMENTS: 'Alergia, cuidados especiais, tipo sanguíneo, etc.',
        COMPLEMENT: 'Bloco, apt, ponto de referência',
        COUNTRY: 'Selecione seu país',
        CPF: 'Digite o CPF do titular',
        CPF_HOLDER: 'CPF do títular',
        CREATE_ACCOUNT: 'Deseja criar uma conta?',
        CREDIT_CARD_NUMBER: 'Digite o número do cartão',
        CVV: 'Cód. segurança',
        DESCRIBE_HERE: 'Insira aqui a mensagem',
        DUE_DATE: 'MM/AA',
        DUE_DATE_SIX_DIGITS: 'MM/AAAA',
        EMAIL: 'Insira seu e-mail',
        ENTER_NAME: 'Insira seu nome',
        FEMALE: 'Feminino',
        FREQUENCY_IN_DAYS: 'Frequência em dias',
        IMAGE: 'Adicionar',
        MALE: 'Masculino',
        MARITAL_STATUS: 'Selecione seu estado civil',
        MOTIVE: 'Motivo',
        NAME: 'ex: Maria',
        NEIGHBORHOOD: 'Insira seu bairro',
        NO: 'Não',
        NUMBER: 'Informe o número',
        OCUPATION: 'Selecione sua ocupacão',
        PASSWORD: 'Insira sua senha',
        PHONE: 'Telefone',
        PRIVACY_POLICY: 'política de privacidade',
        RECURRENCY: 'Pagar Recorrente?',
        ROUTING_NUMBER: 'Número do roteamento',
        SAVE_CARD: 'Salvar cartão?',
        SELECT_CARD: 'Escolha o cartão',
        SEX: 'Sexo',
        STATE: 'Selecione seu estado',
        SURNAME: 'ex: da Silva',
        TERMS_OF_SERVICE: 'termos de serviços',
        VALUE: 'Preço',
        YES: 'Sim',
      },
      VALIDATION: {
        CARD_TOKEN_FAIL: 'Alguma informação do cartão está incorreta. Por favor verifique.',
        CPF: 'CPF inválido.',
        PHONE: 'Preencha o telefone no formato: +55 DDD 9XXXX-YYYY',
        REQUIRED: 'Esse campo é obrigatório.',
        ZIPCODE: 'CEP Inválido',
      },
    },
    GIVE: {
      CHECKOUT: {
        BUTTON: {
          PAYMENT: {
            CONFIRM: 'Confirmar',
          },
          THANK_YOU: {
            DONATE_AGAIN: 'Deseja fazer outra doação?',
            DOWNLOAD_BOLETO: 'Ver boleto',
            OPEN_PICPAY: 'Abrir PicPay',
            VIEW_MORE: 'Ver motivos de doação',
          },
        },
        FORM: {
          ACH: {
            TITLE: 'Adicione as informações:',
          },
          VALIDATION: {
            MIN_VALUE: 'O valor é obrigatório',
            PICPAY: {
              MAX_VALUE: 'O valor máximo com PicPay é R$ 4.999,99.<br>Você pode fazer várias doações com esse limite.',
            },
          },
        },
        MESSAGE: {
          GENERIC_ERROR: 'Algo deu errado. Atualize a página e tente novamente.',
        },
        TEXT: {
          PAYMENT: {
            CHOOSE_AMOUNT: 'Com quanto você deseja ajudar',
            FEES: 'Colaborar com {0} de taxas de operação.',
            METHODS: {
              BOLETO: 'Você receberá o boleto bancário por e-mail e sua doação será efetuada após a confirmação do pagamento.',
              PICPAY: {
                RULE_MESSAGE: 'Confirme no botão abaixo, baixe ou acesse o seu aplicativo PicPay e conclua a sua doação.',
              },
              PIX: {
                RULE_MESSAGE: 'Confirme no botão abaixo para prosseguir com o seu pagamento.',
              },
              PIX_MANUAL: 'Copie a chave do Pix abaixo para efetuar a doação no aplicativo do seu banco.',
            },
          },
          THANK_YOU: {
            ACH: {
              aprovado: 'Sua doação foi recebida com sucesso pela {igreja}',
              nao_aprovado: 'Sua doação para {0} foi enviada e será confirmada pelo seu banco. Você receberá um e-mail com mais informações.',
            },
            BOLETO: {
              em_analise: 'Faça download do boleto bancário no link abaixo ou pelo seu e-mail. Sua doação será efetivada após a confirmação do pagamento.',
              pendente: 'Faça download do boleto bancário no link abaixo ou pelo seu e-mail. Sua doação será efetivada após a confirmação do pagamento.',
            },
            CREDIT_CARD: {
              aprovado: 'Sua doação foi recebida com sucesso pela {0}',
              em_aberto: 'Sua doação para {0} está em análise. Você receberá um e-mail com mais informações.',
              em_analise: 'Sua doação para {0} está em análise. Você receberá um e-mail com mais informações.',
              pendente: 'Sua doação para {0} está em análise. Você receberá um e-mail com mais informações.',
            },
            PICPAY: {
              aguardando: 'Abra o PicPay e finalize sua doação para',
            },
            PIX: {
              pendente: 'Abra o aplicativo do seu banco, escaneie a imagem ou cole o Código QR para efetuar o pagamento.',
            },
            TITLE: 'Agradecemos sua doação',
          },
        },
        TITLE: {
          INTERNAL: {
            CHECKOUT: 'Você está ajudando {0} com {1}',
          },
        },
      },
      HOME: {
        BUTTON: {
          NEXT: 'Continuar',
          NEW_REASON: 'Adicionar motivo',
          TOTAL: 'Total:',
        },
        MESSAGE: {
          ERROR: {
            GENERIC_ERROR: 'Algo deu errado. Atualize a página e tente novamente.',
            NO_GIVE: 'Não há opções de doação no momento.',
            NO_MODULE: 'Neste momento, esta igreja não está aceitando doações através da internet.',
            MIN_VALUE: 'Valor da doação precisa ser maior que {minValue}',
          },
        },
        TEXT: {
          CHOOSE_AMOUNT: 'Quanto você deseja doar?',
          CHOOSE_METHOD: 'Qual o motivo de doação?',
          CHOOSE_OPTION: 'Selecione o motivo',
          EDIT: 'Editar valor',
        },
        TITLE: {
          INTERNAL: {
            GIVE: 'Doação',
          },
          PAGE: 'Doações',
        },
      },
      RECURRENCY: {
        BUTTON: {
          NEXT: 'Continuar',
        },
        TEXT: {
          DAYS: 'dias',
          INFO: 'Você será cobrado no intervalo escolhido.',
          OBS1: '* A recorrência só é permitida para um motivo. Ao adicionar mais, esta será desativada.',
          OBS2: '* A opção de ativar recorrência estará disponível apenas para motivos autorizados pela igreja.',
        },
        TITLE: {
          INTERNAL: {
            RECURRENCY: 'Recorrência',
          },
          PAGE: 'Recorrência da Doação',
        },
      },
    },
    GLOBAL: {
      ERROR: {
        GENERIC_ERROR: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
      },
      MODAL: {
        BUTTON: {
          CANCEL: 'Cancelar',
          OKAY: 'Ok',
        },
      },
      OFFLINE: {
        MESSAGE: 'Você está sem internet. Verifique sua conexão com a internet para continuar usando o aplicativo',
      },
      TEXT: {
        COPY_RIGHTS: 'All Rights Reserved',
      },
    },
    HEADER: {
      ACCESS_TEXT: 'Olá, entre na sua conta ou faça seu cadastro.',
      LINKS: {
        MORE: 'Mais',
      },
      LOGIN: 'Entrar',
      MENU: {
        HOME: 'Início',
        INPEACE_PLAY: 'Assinatura',
        INSCRIPTIONS: 'Inscrições',
        LOGOUT: 'Sair',
        PROFILE: 'Minha conta',
      },
      NOTIFICATIONS: {
        LAST_UPDATE: '{day} às {hour}',
        TITLE: 'Notificações',
        MODAL: {
          TEXT: 'Para acessar mais informações, será necessário trocar de igreja. Você deseja trocar agora?',
          BUTTON: 'Trocar',
        },
      },
      REGISTER: 'Cadastrar',
      SALUTATION: 'Olá',
    },
    HOME: {
      BANNER: {
        TITLE: 'Boas vindas',
      },
      CTA: {
        SUB_TITLE: 'E nos leve para qualquer lugar',
        TITLE: 'Baixe nosso app',
        TITLE_MOBILE: 'Baixe nosso app e nos leve para qualquer lugar',
      },
      EVENTS: {
        BUTTON: 'Ver próximos eventos',
        DATE: {
          DAY: {
            DIFFERENT: '{start} {monthStart} à {end} {monthEnd}',
            SAME: '{start} {monthEnd}',
          },
        },
        SUBTITLE: 'Próximos',
        TEXT: 'Os eventos ajudam na missão de fortalecer nossa fé todo dia e a nos reencontrarmos com Deus.',
        TITLE: 'Nossos Eventos',
      },
      GIVING: {
        BUTTON: 'Fazer uma doação',
        TEXT: 'A doação é um modo de agradecer a Deus por todas as bênçãos na nossa vida. E também é como plantamos a semente em busca de alguma graça específica.',
        TITLE: 'Doações',
      },
      MESSAGE: {
        ERROR: {
          GENERIC: 'Ocorreu um erro ao buscar os dados. Atualize a página por favor.',
        },
      },
      MODULES: {
        ABOUT: 'Sobre nós',
        EVENTS: 'Eventos',
        GIVE: 'Doação',
        LIVE: 'Programação',
        SUBSCRIPTION: 'Play',
      },
      PRAYER: {
        BUTTON: 'Fazer um pedido',
        TEXT: 'A oração sincera é o caminho mais puro para conversar com Deus. Abra seu coração, compartilhe seu pedido de oração e ore pelos outros no nosso mural virtual.',
        TITLE: 'Mural de Orações',
      },
      SCHEDULE: {
        BUTTON: 'Ver transmissões ao vivo',
        CARD: {
          VIEW_LESS: 'Ver menos',
          VIEW_MORE: 'Ver mais',
        },
        DAYS: {
          FRIDAY: 'Sexta-Feira',
          MONDAY: 'Segunda-Feira',
          SATURDAY: 'Sábado',
          SUNDAY: 'Domingo',
          THURSDAY: 'Quinta-Feira',
          TUESDAY: 'Terça-Feira',
          WEDNESDAY: 'Quarta-Feira',
        },
        SUBTITLE: 'Programação',
        TEXT: 'Confira nossa agenda presencial e também acompanhe as transmissões ao vivo de onde estiver usando a internet. Veja a programação a seguir:',
        TITLE: 'Com você em qualquer lugar',
      },
      TITLE: {
        PAGE: {
          HOME: 'Início',
        },
      },
    },
    INPEACE_PLAY: {
      BROWSE: {
        MESSAGE: {
          NO_CONTENT: 'Ainda não existe nenhum conteúdo para exibir',
        },
      },
      BUTTON: {
        ADD_MY_LIST: 'Minha lista',
      },
      CHECKOUT: {
        BUTTON: {
          GO_TO_CONTENT: 'Assista aos vídeos',
          PAYMENT: {
            CONFIRM: 'Confirmar',
          },
          THANK_YOU: {
            DOWNLOAD_BOLETO: 'Ver boleto',
            OPEN_PICPAY: 'Abrir PicPay',
            SUBSCRIVE_AGAIN: 'Deseja fazer outra inscrição?',
            VIEW_MORE: 'Ver mais eventos/cursos',
          },
        },
        TEXT: {
          PAYMENT: {
            METHODS: {
              BOLETO: 'Você receberá o boleto bancário por e-mail e sua assinatura será efetivada após a confirmação do pagamento.',
              FREE: 'Essa assinatura é gratuita, você terá acesso a todos os conteúdos incluídos nela sem qualquer custo.',
              PICPAY: {
                RULE_MESSAGE: 'Confirme no botão abaixo, baixe ou acesse o seu aplicativo PicPay e conclua a sua doação.',
              },
            },
            PAYMENT: 'Pagamento',
            PLAN: 'Plano',
            PRICE: 'Preço',
            SUBSCRIPTION_FEE: '+ {value} (taxa de contratação)',
            TOTAL: 'Total',
          },
          THANK_YOU: {
            DATA: {
              PLAN: 'Plano',
              STATUS: 'Situação',
              SUBSCRIBED: 'Assinatura',
            },
            METHODS: {
              BOLETO: {
                em_analise: 'Você pode acessar o boleto bancário no link abaixo, mas também o receberá por e-mail e sua assinatura será efetivado após a confirmação do pagamento.',
                pendente: 'Você pode acessar o boleto bancário no link abaixo, mas também o receberá por e-mail e sua assinatura será efetivado após a confirmação do pagamento.',
              },
              CREDIT_CARD: {
                aprovado: 'Sua assinatura foi realizada com sucesso.',
                em_aberto: 'Sua assinatura está em análise. Você receberá um e-mail com a confirmação e logo seus ingressos estarão disponíveis na plataforma.',
                em_analise: 'Sua assinatura está em análise. Você receberá um e-mail com a confirmação e logo seus ingressos estarão disponíveis na plataforma.',
                pendente: 'Sua assinatura está em análise. Você receberá um e-mail com a confirmação e logo seus ingressos estarão disponíveis na plataforma.',
              },
              FREE: {
                SUCCESS: 'Sua assinatura foi realizada com sucesso.',
              },
              PICPAY: {
                aguardando: 'Abra o PicPay e finalize sua doação para',
              },
            },
            TITLE: 'Obrigado',
          },
        },
      },
      COMMON: {
        COLLECTIONS: 'Coleções',
        CONTENT_POSTER: {
          COMING_SOON: 'Em breve',
        },
        HOME: 'Início',
        MY_LIST: 'Minha Lista',
        QUERY: 'RESULTADO DA BUSCA:',
        RELATED_CONTENT: {
          TITLE: 'RELACIONADOS',
        },
        TAG: 'Tag: ',
        VIDEOS: 'Vídeos',
      },
      CONTENT_ACTIONS: {
        BUTTON: {
          SUBSCRIBE_NOW: 'Assine agora',
          WATCH: 'Assistir',
        },
      },
      HOME: {
        CLOSED_CONTENT: {
          BUTTON: {
            SUBSCRIBE_NOW: 'Assine agora',
          },
        },
      },
      MODULE_NAME: 'Play',
      PLANS: {
        MESSAGE: {
          ERROR: 'Houve um erro ao obter os dados',
          NO_CONTENT: 'Não existem planos disponíveis no momento',
        },
        MY_PLANS: {
          PLAN: 'Plano',
          SUBSCRIBED_AT: 'Data da assinatura',
          SUBSCRIPTION_CODE: 'Código da assinatura',
          TITLE: 'Meu plano',
        },
        PLANS_LIST: {
          BUTTON_NEXT: 'Assinar agora',
          FEES: 'Taxas',
          PRICE: 'Preço',
          TITLE: 'Escolha seu plano',
        },
        TITLE: 'Planos',
      },
      SEARCH: {
        MESSAGE: {
          INVALID_SEARCH: 'Não foi possível encontrar nenhum resultado para sua busca',
          NO_CONTENT: 'Não foi possível encontrar nenhum resultado para sua busca',
          REQUEST_ERROR: 'Houve um erro ao buscar os dados',
        },
      },
      SINGLE: {
        MESSAGE: {
          NO_COLLECTION_CONTENT: 'Ainda não existem conteúdos para serem exibidos',
          NO_COLLECTION_ID: 'Houve um erro, por favor tente novamente',
          NO_CONTENT: 'O conteúdo que você estava buscando não foi encontrado',
          REQUEST_ERROR: 'Houve um erro ao tentar buscar os dados, por favor tente novamente',
        },
        TEXT: {
          TAGS: 'Tags',
          VIDEOS: 'Vídeos',
        },
      },
      WATCH: {
        MESSAGE: {
          COMING_SOON: 'Conteúdo indisponível',
          NO_CONTENT: 'Conteúdo não encontrado ou indisponível',
          REQUEST_ERROR: 'Houve um erro ao obter os dados, por favor tente novamente',
        },
      },
    },
    LIVE: {
      BUTTON: {
        BIBLE: 'Bíblia',
        DONATE_NOW: 'Fazer doação',
        GIVE: 'Doação',
        LIVE_CHAT: 'Chat',
        NOTE: 'Anotações',
        UPCOMING_STREAMS: 'Próximas transmissões',
      },
      MESSAGE: {
        ERROR: {
          GENERIC: 'Ocorreu um erro ao buscar o evento. Por favor, tente novamente.',
          NO_MODULE: '{0} não possuí transmissão ao vivo.',
        },
      },
      TEXT: {
        GIVE: {
          REDIRECT: 'Você seguirá para tela de doações',
          REDIRECT_MESSAGE: 'Para não interromper a transmissão, vá para a opção Doação na página inicial a qualquer momento.',
        },
        NO_WORSHIP_LIVE: 'Em breve teremos nossa primeira transmissão ao vivo',
        NO_WORSHIP_SCHEDULE: 'Ainda não há transmissões agendadas',
        NOT_BROADCASTED: '{0} ainda não fez uma transmissão.',
        SHARE: {
          LIVE: '{0}',
          UPCOMING: '{0} - {1}',
        },
        TAG_STATUS: {
          LAST: 'Última transmissão',
          LIVE: 'Ao Vivo',
        },
      },
      TITLE: {
        PAGE: {
          HOME: 'Programação',
        },
      },
    },
    LOGIN: {
      BUTTON: {
        LOGIN: 'Login',
        LOST_PASSWORD: 'Esqueci a minha senha',
        REGISTER: 'AINDA NÃO TEM CONTA? CADASTRE-SE',
      },
      FORM: {
        MESSAGE: {
          ERROR: 'Nome de usuário ou senha inválidos.',
        },
      },
      SUBTITLE: 'Entre na sua conta',
      TITLE: {
        INTERNAL: {
          LOGIN: 'Login',
          MUST_LOGIN: 'Você deve estar logado para continuar.',
        },
        PAGE: {
          HOME: 'Login',
        },
      },
    },
    MEMBER_CARD: {
      CARD: {
        AVATAR: 'Foto de {memberFullName}.',
        BAPTISM_DATE: 'Batismo',
        BIRTH_DATE: 'Nascimento',
        CONGREGATION: 'Congregação',
        CPF: 'CPF',
        FEMALE: 'Feminino',
        FOOTER: 'Em caso de dúvida, entre em contato',
        FULL_NAME: '{firstName} {lastName}',
        GENDER: 'Sexo',
        LINKED: 'Vinculado à',
        LOGO: 'Logo da igreja {churchName}',
        MALE: 'Masculino',
        MARITAL_STATUS: 'Estado Civil',
        NAME: 'Nome',
        PHONE: 'Telefone',
        POSITION: 'Atividade',
        QR_CODE: 'Código QR de {memberFullName}.',
        RESPONSIBLE: 'Responsável',
        WIDOWED: 'Viúvo(a)',
      },
      PRINT: {
        ACTION: 'Imprimir',
        ERROR_MESSAGE: 'Não foi possível obter os dados de carteirinhas.',
        NOT_FOUND_CARD: 'Não foi encontrada nenhuma carteirinha para este e-mail.',
      },
    },
    MINISTRIES: {
      CARD: {
        COORDINATION: 'Coordenação',
      },
      EMPTY: {
        BUTTON: 'Voltar ao início',
        SUBTITLE: 'Quando um ministério for criado você poderá visualizar as informações.',
        TITLE: 'Ainda não há ministérios aqui',
      },
      INDEX: {
        FILTERS: {
          INPUT: 'Buscar por nome ou palavra-chave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
            SEARCH_DESCRIPTION: 'Que tal tentar com outro título ou palavra-chave?',
            SEARCH_TITLE: 'Não há resultados para essa busca',
          },
        },
      },
      MODULE_NAME: 'Ministérios',
      SINGLE: {
        INFO: {
          PROJECT: 'Projetos',
          RESPONSIBLE: 'Responsável',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
            NOT_FOUND: 'O conteúdo não existe ou está indisponível.',
          },
        },
      },
    },
    NEWS: {
      EMPTY: {
        BUTTON: 'Voltar ao início',
        SUBTITLE: 'Quando um conteúdo for criado você poderá visualizar as informações.',
        TITLE: 'Ainda não há publicações aqui',
      },
      INDEX: {
        FILTERS: {
          INPUT: 'Buscar por título ou palavra-chave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
            SEARCH_DESCRIPTION: 'Que tal tentar com outro título ou palavra-chave?',
            SEARCH_TITLE: 'Não há resultados para essa busca',
          },
        },
      },
      MODULE_NAME: 'Notícias',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
            NOT_FOUND: 'O conteúdo não existe ou está indisponível.',
          },
        },
      },
    },
    ORGANIZATION_HEADER: {
      BAR: {
        CHANGE: 'Alterar',
      },
      MODAL: {
        CLIENT_INFO: {
          BACK: 'Cancelar',
          CONFIRM: 'Confirmar',
        },
        CLIENTS: {
          NO_CLIENTS: 'Não conseguimos localizar igrejas nessa organização.',
          NO_RESULT: 'Não encontramos nenhuma igreja para sua busca',
          PLACEHOLDER: 'Buscar igreja por nome ou endereço',
          SELECT_CLIENT: 'Selecione uma igreja',
        },
        LOCATION: {
          ALLOW: 'Permitir',
          ENTRY: 'Permita a sua localização para encontrarmos as igrejas mais próximas de você',
          NOT_NOW: 'Agora não',
        },
      },
    },
    PLAYER: {
      AUDIO: {
        CONTROLS: {
          NEXT: 'Próxima',
          PAUSE: 'Parar',
          PLAY: 'Iniciar',
          PREV: 'Anterior',
          REPEAT: 'Repetir',
          SHUFFLE: 'Aleatório',
        },
      },
    },
    PODCAST: {
      EMPTY: {
        BUTTON: 'Voltar ao início',
        SUBTITLE: 'Quando um conteúdo for criado você poderá visualizar as informações.',
        TITLE: 'Ainda não há podcasts aqui',
      },
      INDEX: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
            SEARCH_DESCRIPTION: 'Que tal tentar com outro título ou palavra-chave?',
            SEARCH_TITLE: 'Não há resultados para essa busca',
          },
          VIEW_MORE: 'Ver mais',
        },
        TEXT: {
          EPISODES: 'Episódios',
          PUBLISHED: 'Publicado em:',
          QUEUE: 'Fila',
          SHARE: 'COMPARTILHAR',
        },
      },
      MODULE_NAME: 'Podcast',
    },
    PRAISE: {
      CHORD: {
        TONE: 'Tom',
      },
      CONTROLS: {
        AUTOMATIC_SCROLLING: 'Rolagem automática',
        FONT: 'Fonte',
      },
      ERROR: {
        ERROR_MESSAGE: 'Não foi possível buscar as informações desta música.',
      },
      SCORE: {
        PAGE: 'Pagina {pageNumber} da partitura',
      },
      SHOW_TYPES: {
        CIPHER: 'Cifra',
        LYRIC: 'Letra',
        SCORE: 'Partitura',
      },
    },
    PRAYER: {
      DELETE: {
        BUTTON: 'Excluir pedido',
        CANCEL: 'Voltar ao início',
        SUBTITLE: 'Ao optar por excluir, essa ação será irreversível.',
        SUCCESS: {
          BUTTON: 'Fazer um pedido',
          BUTTON_RETURN: 'Voltar ao início',
          SUBTITLE: 'Deseja fazer um novo pedido?',
          TITLE: 'O seu pedido foi excluído com sucesso',
        },
        TITLE: '  Você realmente quer excluir seu pedido?',
      },
      INDEX: {
        BUTTONS: {
          REQUEST: 'Fazer meu pedido',
          SEND: 'Confirmar',
          SEND_MODAL: 'Enviar',
        },
        EMPTY: {
          BUTTON: 'Fazer um pedido',
          SUBTITLE: 'Ainda não há pedidos',
          TEXT: 'Quando um pedido for publicado no mural ele aparecerá aqui.',
          TITLE: 'Mural de orações',
        },
        FILTERS: {
          DROPDOWN: 'Filtrar por motivo',
          INPUT: 'Buscar por nome do autor ou mensagem',
        },
        MESSAGE: {
          BACK_TOP: 'Voltar ao topo',
          EMPTY: 'Ainda não há orações.',
          ERROR: {
            GENERIC: 'Nenhum conteúdo encontrado.',
            SEARCH: 'Não há resultados para essa busca',
            SEARCH_MESSAGE: 'Que tal tentar com outro nome ou palavra-chave?',
          },
          LOGIN: 'É necessário realizar o login para completar essa ação.',
          REQUEST: 'Fazer um pedido',
          SEND_MESSAGE: 'Deixe seu apoio neste pedido',
          VIEW_MORE: 'Ver mais',
        },
        TITLE: 'Mural de orações',
      },
      MODULE_NAME: 'Mural de orações',
      NEW: {
        ERROR: 'Não foi possivel realizar o pedido de oração, tente novamente',
        PUBLISH: 'Gostaria de deixar o seu pedido publicado no mural?',
        CALL: 'Deseja receber uma ligação de algum membro?',
        VISIT: 'Deseja receber a visita de algum membro?',
        SELECT: 'Escolha o motivo',
        SUBTITLE: 'Preencha os campos abaixo e nos envie o seu pedido de oração',
        SUCCESS: {
          BUTTON: 'Fazer outro pedido',
          BUTTON_RETURN: 'Voltar',
          SUBTITLE: 'O seu pedido foi enviado com sucesso',
          THANK_YOU: 'Agradecemos pelo envio do seu pedido',
          TITLE: 'Fazer pedido',
        },
        TITLE: 'Fazer Pedido',
      },
      SINGLE: {
        MESSAGE: {
          EMPTY: 'Ainda não há mensagens.',
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
            NOT_FOUND: 'Nenhum resultado encontrado.',
          },
          PRAY: 'Orando',
          SEND: 'Enviar mensagem',
          TITLE: 'Mensagens',
        },
      },
    },
    PUBLICATION: {
      EMPTY: {
        BUTTON: 'Voltar ao início',
        SUBTITLE: 'Quando um conteúdo for criado você poderá visualizar as informações.',
        TITLE: 'Ainda não há publicações aqui',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filtrar por categoria',
          INPUT: 'Buscar por título ou palavra-chave',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
            SEARCH_DESCRIPTION: 'Que tal tentar com outro título ou palavra-chave?',
            SEARCH_TITLE: 'Não há resultados para essa busca',
          },
        },
        TEXT: {
          SELECT_TYPE: 'Selecione o tipo de publicação que você deseja acessar:',
        },
      },
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
            NOT_FOUND: 'O conteúdo não existe ou está indisponível.',
          },
        },
      },
      MODULE_NAME: 'Publicações',
    },
    PUBLICATION_TYPE: {
      EMPTY: {
        BUTTON: 'Voltar ao início',
        SUBTITLE: 'Quando novos tipos forem adicionados, você poderá visualizar aqui.',
        TITLE: 'Ainda não há tipos de publicações cadastrados',
      },
    },
    RECAPTCHA: {
      TEXT: 'Captcha inválido. Por favor tente novamente.',
    },
    REGISTER: {
      BUTTON: {
        SEND: 'Confirmar Cadastro',
      },
      PASSWORD_MESSAGEM: {
        CONFIRMED: 'As senhas precisam ser iguais',
      },
      PASSWORD_REQUIREMENTS: {
        REQUIREMENT_1: 'Mínimo 8 caracteres',
        REQUIREMENT_2: 'Letra maiúscula e minúscula',
        REQUIREMENT_3: 'Número',
        REQUIREMENT_4: 'Caractere especial (Ex: !@#$%*)',
        TITLE: 'Senha deve conter:',
      },
      SUBTITLE: 'Faça seu cadastro',
      TITLE: {
        INTERNAL: {
          FILL_INFO: 'Preencha as informações para se cadastrar',
          REGISTER: 'Cadastrar',
        },
        PAGE: {
          HOME: 'Cadastrar',
        },
      },
      TOOLTIP: {
        PASSWORD_HIDE: 'Ocultar senha',
        PASSWORD_SHOW: 'Mostrar senha',
      },
    },
    REQUEST_LOGIN: {
      BUTTONS: {
        CANCEL: 'Cancelar',
        CONFIRM: 'Entrar / Cadastrar',
      },
      MESSAGE: {
        LOGIN: 'Acesse a sua conta ou cadastre-se para continuar.',
      },
    },
    RESET_PASSWORD: {
      BUTTON: {
        SEND: 'Recuperar',
      },
      FORM: {
        MESSAGE: {
          ERROR: {
            GENERIC_ERROR: 'Ocorreu um erro ao tentar enviar os dados. Tente novamente.',
            NOT_FOUND: 'E-mail não encontrado.',
          },
          SUCCESS: 'Nova senha enviada para o seu e-mail.',
        },
      },
      TITLE: {
        INTERNAL: {
          ENTER_EMAIL: 'Digite seu e-mail para recuperar sua senha',
          RECOVER_PASSWORD: 'Recuperar Senha',
        },
        PAGE: {
          HOME: 'Recuperar Senha',
        },
      },
    },
    USER: {
      ACCOUNT: {
        CHURCH: {
          CHANGE: 'Alterar',
        },
        PROFILE: {
          LINKS: {
            CREDIT_CARD: {
              SUBTITLE: 'Adicione ou exclua cartões',
              TITLE: 'Cartões',
            },
            DELETE_USER: 'Excluir minha conta',
            EVENTS_INSCRIPTIONS: {
              SUBTITLE: 'Visualize as informações de ingressos',
              TITLE: 'Inscrições',
            },
            LOGOUT: 'Sair',
            MY_ACCOUNT: {
              LABEL_INCOMPLETE: 'INCOMPLETO',
              SUBTITLE: 'Confirme, adicione ou altere seus dados cadastrados',
              TITLE: 'Dados cadastrais',
            },
            NOTES: {
              SUBTITLE: 'Visualize, crie ou exclua suas anotações',
              TITLE: 'Anotações',
            },
            PRAYER: {
              SUBTITLE: 'Visualize, compartilhe ou exclua seus pedidos',
              TITLE: 'Pedidos do mural',
            },
            SUBSCRIPTIONS: {
              TITLE: 'Play',
            },
          },
          TITLE: {
            ENTRY: 'Minha conta',
            PAGE: 'Minha conta',
          },
        },
        UPDATE: {
          BUTTON: {
            SEND: 'Completar meu cadastro',
          },
          TITLE: {
            INTERNAL: {
              FINISH_REGISTRATION: 'Finalize seu cadastro para continuar.',
              UPDATE: 'Minha conta',
            },
            PAGE: 'Minha conta',
          },
        },
        UPDATE_PASSWORD: {
          BACK: 'Voltar',
          INPUTS: {
            LABEL: {
              NEW_PASSWORD: 'Nova senha',
              PASSWORD: 'Senha atual',
              PASSWORD_CONFIRMATION: 'Confirmar nova senha',
            },
            PLACEHOLDER: {
              NEW_PASSWORD: 'Digite sua nova senha',
              PASSWORD: 'Digite sua senha atual',
              PASSWORD_CONFIRMATION: 'Confirme sua nova senha',
            },
          },
          INVALID: 'Essa não é sua senha atual. Confira e tente novamente',
          SUCCESS: {
            SUBTITLE: 'Em seu próximo acesso, utilize sua nova senha para entrar.',
            TITLE: 'Sua senha foi alterada com sucesso',
          },
          TITLE: 'Alterar Senha',
        },
      },
      CREDIT_CARD: {
        DELETE: {
          BUTTON: 'Excluir Cartão',
          CANCEL: 'Cancelar',
          SUBTITLE: 'Para voltar a utilizar esse cartão, você precisará adicioná-lo de novo.',
          TITLE: ' Você realmente quer excluir o cartão final {number} ?',
        },
        NEW_CARD: {
          BUTTON: 'Cadastrar',
          SUBTITLE: 'Informe os dados do cartão',
          TITLE: 'Adicionar Cartão',
          ERROR: 'Informe um número de cartão de crédito válido para concluir o pagamento. Certifique-se de que todos os detalhes estejam corretos e que o cartão ainda esteja dentro do prazo de validade.',
        },
        NOT_FOUND: {
          BUTTON: 'Adicionar cartão',
          SUBTITLE: 'Adicione um cartão para começar a fazer transações.',
          TITLE: 'Ainda não há cartões aqui',
        },
        TITLE: {
          INTERNAL: 'Confira os cartões cadastrados',
          PAGE: 'Cartões',
        },
      },
      DELETE_USER: {
        BUTTON: 'Excluir minha conta',
        COMPLEMENT: 'Além disso, você também perderá de forma irreversível o seu histórico de dados.',
        CONFIRM: {
          BUTTON: 'Excluir minha conta',
          DESCRIPTION: 'Informe a sua senha de acesso para concluir o seu pedido de exclusão de conta:',
          ERROR: 'Essa não é sua senha atual. Confira e tente novamente',
          INPUT: 'Senha',
          INPUT_PLACEHOLDER: 'Digite sua senha',
        },
        DESCRIPTION: 'Ao excluir a sua conta, você terá que criar uma nova, caso queira voltar a usar todas as funcionalidades do aplicativo.',
        MODULE_NAME: 'Excluir minha conta',
        SUCCESS: {
          BUTTON: 'Entendi',
          COMPLEMENT: 'Além disso, caso deseje acessar novamente todas as funcionalidades do aplicativo, basta realizar um novo cadastro.',
          DESCRIPTION: 'Você receberá em seu email as informações sobre a exclusão da sua conta.',
          TITLE: 'A exclusão da sua conta foi solicitada.',
        },
        TITLE: 'Você realmente deseja excluir a sua conta?',
      },
      EVENT: {
        SUBSCRIBED: {
          BTN: {
            VIEW_TICKET: 'Ver ingresso',
          },
          NOT_FOUND: {
            BUTTON: 'Ver eventos',
            SUBTITLE: 'Seus ingressos vão ficar disponíveis para visualização quando você se inscrever em algum evento.',
            TITLE: 'Ainda não há inscrições aqui',
          },
          TEXT: {
            PAGE_MESSAGE: 'Selecione uma inscrição para conferir os detalhes',
          },
          TITLE: {
            INTERNAL: 'Inscrições',
            PAGE: 'Minhas inscrições',
          },
        },
        TICKETS: {
          BTN: {
            COMING_SOON: 'Disponível em breve',
            EXPIRED: 'Conteúdo expirado',
            VIEW_CONTENT: 'Ver conteúdo',
          },
          CONTENT: {
            ERROR: {
              NO_CONTENT: 'Nenhum conteúdo encontrado',
            },
            SINGLE: {
              TEXT: {
                CHAT: 'Chat',
                NOT_FOUND: 'Conteúdo não encontrado ou indisponível no momento.',
              },
            },
            TEXT: {
              Chat: 'Chat',
              CHECK_THE_CONTENT: 'Confira os detalhes do conteúdo',
              NO_CONTENT: 'Não existem conteúdos para serem exibidos neste momento.',
            },
            TITLE: {
              INTERNAL: 'Selecione o conteúdo que deseja visualizar',
              PAGE: 'Conteúdos',
            },
          },
          ERROR: {
            TICKETS_NOT_FOUND: 'Não foi encontrado nenhum ingresso para esse evento',
          },
          TEXT: {
            EXCLUSIVE_CONTENT: 'Ingresso com conteúdo exclusivo',
            PAGE_MESSAGE: 'Confira aqui as informações do ingresso',
            START_FINISH_TIME: '{start} às {finish}',
          },
          TICKET_FIELD: {
            BUYER: 'Comprador',
            DATE: 'Data',
            EVENT_NAME: 'Nome do evento',
            TICKET: 'Ingresso',
            TICKETS: 'Ingressos',
            TIME: 'Horário',
            USER: 'Utilizador',
          },
          TITLE: {
            INTERNAL: 'Meus ingressos',
            PAGE: 'Meus ingressos',
          },
        },
      },
      NOTES: {
        BUTTON: {
          BACK: 'Voltar para lista',
          BACK_TOP: 'Voltar ao topo',
          CANCEL: 'Cancelar',
          DELETE: 'Excluir',
          DELETE_NOTE: 'Excluir anotação',
          NEW: 'Nova anotação',
          SAVE: 'Salvar',
          SAVE_NOTE: 'Salvar anotação',
          VIEW_MORE: 'Ver mais',
        },
        DATE: {
          MESSAGE: 'Última alteração feita em {date} às {hour}',
        },
        DELETE: {
          DESCRIPTION: 'Ao optar por excluir, essa ação será irreversível',
          MODAL: {
            BUTTON_OK: 'OK',
            DESCRIPTION: 'Por favor, tente novamente.',
            TITLE: 'Ocorreu um erro ao tentar excluir essa anotação',
          },
          TITLE: ' Você realmente quer excluir esta anotação?',
        },
        EMPTY: {
          DESCRIPTION: 'Crie aqui a sua primeira anotação',
          TITLE: 'Ainda não há anotações aqui',
        },
        LIVE: {
          BUTTON: 'Entrar / Cadastrar',
          DESCRIPTION: 'Para fazer anotações, entre na sua conta ou faça seu cadastro.',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Não foi possível carregar as informações.',
          },
        },
        MODAL: {
          BUTTON_CANCEL: 'Cancelar',
          BUTTON_CONFIRM: 'Entendi',
          BUTTON_CONTINUE: 'Continuar editando',
          BUTTON_DELETE: 'Excluir anotação',
          BUTTON_NEW: 'Nova anotação',
          BUTTON_OK: 'OK',
          DESCRIPTION_NOT_CONTENT: 'Ao sair, sua anotação será excluída por não ter conteúdo.',
          DESCRIPTION_NOT_TITLE: 'Para salvar a sua anotação é necessário criar um título.',
          DESCRIPTION_SAVE_NOTE: 'Agora você pode optar por criar uma nova anotação ou continuar editando esta anotação',
          TITLE_NOT_CONTENT: 'Deseja realmente sair da anotação?',
          TITLE_NOT_TITLE: 'Anotação sem título',
          TITLE_SAVE_NOTE: 'Sua anotação foi salva com sucesso',
        },
        PLACEHOLDER: {
          TEXT: 'Comece a escrever…',
          TITLE: 'Título da anotação',
        },
        TITLE: {
          PAGE: 'Anotações',
          TEXT: 'Confira todas as anotações já feitas',
        },
      },
      PAYMENT: {
        STATUS: {
          BUTTON: {
            BACK_HOME: 'Voltar para o início',
          },
          TEXT: {
            STATUS: {
              CANCELED: 'O seu pagamento de <span class="text--featured">{value}</span> para a <span class="text--featured">{church}</span> foi cancelado.',
              NOT_FOUND: 'Não existe nenhum registro de pagamento para o código informado.',
              REFUND: 'O seu pagamento de <span class="text--featured">{value}</span> para a <span class="text--featured">{church}</span> foi reembolsado.',
              REVIEW: 'O seu pagamento de <span class="text--featured">{value}</span> para a <span class="text--featured">{church}</span> está em análise.',
              SUCCESS: '<span class="text--featured">{church}</span> recebeu com sucesso o valor de <span class="text--featured">{value}</span> destinado a <span class="text--featured">{destination}</span>.',
              WAITING: 'O seu pagamento de <span class="text--featured">{value}</span> para a <span class="text--featured">{church}</span> está aguardando pagamento.',
            },
            THANK_YOU: 'Obrigado',
          },
          TITLE: {
            PAGE: 'Status do pagamento',
          },
        },
      },
      PRAYER: {
        INDEX: {
          EMPTY: {
            BUTTON: 'Fazer um pedido',
            SUBTITLE: 'Ainda não há pedidos',
            TEXT: 'Quando você fizer um pedido no mural ele aparecerá aqui. ',
            TITLE: 'Pedidos do mural',
          },
          MESSAGE: {
            BACK_TOP: 'Voltar ao topo',
            ERROR: {
              GENERIC: 'Nenhum conteúdo encontrado.',
              SEARCH: 'Não há resultados para essa busca',
              SEARCH_MESSAGE: 'Que tal tentar com outro nome ou palavra-chave?',
            },
            LOGIN: 'É necessário realizar o login para completar essa ação.',
            SEND_MESSAGE: 'Deixe seu apoio neste pedido',
            VIEW_MORE: 'Ver mais',
          },
        },
        TITLE: {
          INTERNAL: 'Confira aqui todos os seus pedidos',
          PAGE: 'Pedidos do Mural',
        },
      },
      USER_DATA: {
        BUTTON: {
          EDIT: 'Editar',
          NEXT: 'Salvar',
          ADD: 'Adicionar',
        },
        MESSAGE: {
          ERROR: 'Não foi possivel atualizar as informações, tente novamente.',
          SUCCESS: 'Seus dados foram atualizados com sucesso.',
        },
        TITLE: {
          INTERNAL: 'SEUS DADOS',
          PAGE: 'Atualzar dados de usuário',
        },
        CHANGE_CHURCH: {
          ALERT: '<b>ATENÇÃO:</b> Seu cadastro ficará vinculado à igreja selecionada.',
          MODAL: {
            TITLE: 'Você está mudando seu cadastro para ',
            SUBTITLE: 'Sua conta será vinculada à nova igreja selecionada. <p>Deseja confirmar ?</p>',
            BACK: 'Agora não',
          },
        },
        CHILDREN: {
          TITLE: 'Cadastro de filiação',
        },
        ADDRESS: {
          TITLE: 'Cadastro de endereço',
        },
      },
    },
    VIDEOS: {
      INDEX: {
        EMPTY: {
          BUTTON: 'Voltar ao início',
          SUBTITLE: 'Quando um conteúdo for criado você poderá visualizar as informações.',
          TITLE: 'Ainda não há {videos} aqui',
        },
        FILTER: 'Buscar por nome ou data',
        MESSAGE: {
          DATE: 'Publicado em:',
          ERROR: {
            GENERIC: 'Nenhum conteúdo encontrado.',
            SEARCH: 'Não há resultados para essa busca',
            SEARCH_MESSAGE: 'Que tal tentar com outro título ou palavra-chave?',
          },
        },
        TITLE: 'Vídeos',
      },
      MODULE_NAME: 'Vídeos',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Ocorreu um erro ao buscar os dados. Por favor, tente novamente.',
            NOT_FOUND: 'O conteúdo não existe ou está indisponível.',
          },
        },
        PLAYLIST: 'Playlist',
      },
    },
    BIBLE: {
      MODULE_NAME: 'Bíblia',
    },
    VOLUTS: {
      TITLE: 'Voluts',
      DESCRIPTION: 'Clique no botão abaixo para acessar a Voluts',
      BUTTON: 'Acessar',
    },
    MY_KIDS: {
      TITLE: 'MyKids',
      DESCRIPTION: 'Clique no botão abaixo para acessar a MyKids',
      BUTTON: 'Acessar',
    },
  },
  VALIDATION: {
    birthdate: 'Data de nascimento inválida',
    cpf: 'Número de documento inválido',
    date: 'Formato de data inválido',
  },
};
