import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Vee Validate
import validationPtBR from 'vee-validate/dist/locale/pt_BR.json';
import validationEn from 'vee-validate/dist/locale/en.json';
import validationEs from 'vee-validate/dist/locale/es.json';

// Strings
import stringPtBR from './string/pt_BR';
import stringEnUS from './string/en_US';
import stringEs from './string/es';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: (window.navigator.userLanguage || window.navigator.language).substring(0, 2),
  fallbackLocale: 'en',
  messages: {
    pt: {
      ...stringPtBR.CONTENT,
      validation: {
        ...stringPtBR.VALIDATION,
        ...validationPtBR.messages,
      },
    },
    en: {
      ...stringEnUS.CONTENT,
      validation: {
        ...stringEnUS.VALIDATION,
        ...validationEn.messages,
      },
    },
    es: {
      ...stringEs.CONTENT,
      validation: {
        ...stringEs.VALIDATION,
        ...validationEs.messages,
      },
    },
  },
});

export default i18n;
