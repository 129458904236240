import podcastApi from '@/api/podcast';

export default {
  state: {
    podcasts: null,
    playing: null,
    page: 1,
    loading: false,
    endList: false,
  },
  getters: {
    podcastList: state => state.podcasts,
    podcastPlaying: state => state.playing,
    podcastLoading: state => state.loading,
    podcastEndList: state => state.endList,
  },
  mutations: {
    PODCAST_SET_DATA(state, data) {
      state.podcasts = data;
    },
    PODCAST_SET_PLAYING(state, data) {
      state.playing = data;
    },
    GET_MORE_ANCHOR_DATA(state, payload) {
      if (payload.length < 10) {
        state.endList = true;
      }

      payload.forEach(element => {
        state.podcasts.push(element);
      });
    },
    NEXT_PAGE(state) {
      state.page++;
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading;
    },
  },
  actions: {
    async podcastGetAnchorData({ commit }, anchorCode) {
      try {
        const response = await podcastApi.getAnchorPodcastData({ anchorCode, page: 1 });

        commit('PODCAST_SET_DATA', response.data);
        Promise.resolve(response);
      } catch (error) {
        commit('PODCAST_SET_DATA', null);
        Promise.reject(error);
      }
    },
    async getMoreAnchorData({ commit, state }, anchorCode) {
      commit('NEXT_PAGE');
      commit('TOGGLE_LOADING');

      try {
        const response = await podcastApi.getAnchorPodcastData({ anchorCode, page: state.page });

        commit('GET_MORE_ANCHOR_DATA', response.data);
        Promise.resolve(Response);
      } catch (error) {
        Promise.reject(error);
      }

      commit('TOGGLE_LOADING');
    },

    podcastSetPlaying({ commit }, {
      background = null,
      title,
      description = null,
      position,
      published_at,
      audio_file,
    }) {
      commit('PODCAST_SET_PLAYING', {
        background,
        title,
        description,
        position,
        published_at,
        audio_file,
      });
    },
  },
};
