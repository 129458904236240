import { inpeaceAPI } from '@/config/axios/index';

export default {
  getSubjects(churchSlug) {
    return inpeaceAPI({
      method: 'GET',
      url: `${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/pagamento-motivo/?doacao=true`,
    });
  },
};
