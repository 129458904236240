import checkoutAPI from '@/api/checkout';

const getCheckoutDefault = () => ({
  type: null, // 'event', 'give', 'inpeace_play'
  method: null, // Método do checkout
  paymentData: null, // Retorno do pagamento
  unregisteredUserData: null,
  installments: null, //Valor das parcelas calculado
  finalValueInstallments: null,
  challenge: false, // 3ds challenge
  challengeComplete: false, // 3ds challenge
});

export default {
  state: getCheckoutDefault(),

  getters: {
    checkoutType: (state) => state.type,
    checkoutMethod: (state) => state.method,
    checkoutPaymentData: (state) => state.paymentData,
    checkoutUnregisteredUser: (state) => state.unregisteredUserData,
    checkoutIsUnregistered: (state, getters) => !getters.userLoggedIn
      && !!state.unregisteredUserData,
    checkoutInstallments: (state) => state.installments,
    installmentsFinalValue: (state) => state.finalValueInstallments,
    showChallenge: (state) => state.challenge,
    isChallengeComplete: (state) => state.challengeComplete,
  },

  actions: {
    checkoutType({ commit }, type) {
      commit('CHECKOUT_SET_TYPE', type);
    },

    checkoutSetMethod({ commit }, id) {
      commit('CHECKOUT_SET_METHOD', id);
    },

    checkoutReset({ commit }) {
      commit('CHECKOUT_RESET_DATA');
    },

    setInstallmentsFinalValue({ commit }, finalValue) {
      commit('CHECKOUT_SET_INSTALLMENTS_FINAL_VALUE',  finalValue);
    },

    setChallengModal({ commit }, status) {
      commit('CHECKOUT_SET_CHALLENGE', status);
    },

    setChallengeComplete({ commit }, status) {
      commit('CHECKOUT_SET_CHALLENGE_COMPLETE', status);
    },

    async checkoutPostEventRegistration({ commit }, paymentData) {
      try {
        const response = await checkoutAPI.eventPayment(
          {
            requestData: paymentData,
          },
        );

        commit('CHECKOUT_SET_PAYMENT', response.data);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async checkoutPostInpcPlaySubscription({ commit }, paymentData) {
      try {
        const response = await checkoutAPI.inpcPlayPayment(
          {
            requestData: paymentData,
          },
        );

        commit('CHECKOUT_SET_PAYMENT', response.data);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async checkoutCalculateInstallments({ commit, getters }, { price, feesValue }) {
      try {
        const response = await checkoutAPI.creditCardInstallments(
          getters.churchData.slug,
          price,
          getters.eventSingle.flagJuros,
          !getters.eventSingle.flagAssumirTaxasServico,
          feesValue,
          getters.eventSingle.qtdeMaxParcelas,
        );

        commit('CHECKOUT_SET_INSTALLMENTS', response.data.data);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async checkoutPostGive({ commit }, paymentData) {
      try {
        const response = await checkoutAPI.givePayment(
          {
            requestData: paymentData,
          },
        );

        commit('CHECKOUT_SET_PAYMENT', response.data);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async checkoutSetUnregisteredUser({ commit }, data) {
      await commit('CHECKOUT_SET_UNREGISTERED_USER', data);
    },

    async checkoutClearUnregisteredUser({ commit }) {
      await commit('CHECKOUT_SET_UNREGISTERED_USER', null);
    },
  },

  mutations: {
    CHECKOUT_SET_TYPE(state, type) {
      state.type = type;
    },

    CHECKOUT_SET_ITEM(state, item) {
      state.item = item;
    },

    CHECKOUT_SET_METHOD(state, id) {
      state.method = id;
    },

    CHECKOUT_SET_ITEM_AMOUNT(state, amount) {
      state.itemAmount = amount;
    },

    CHECKOUT_SET_PAYMENT(state, payload) {
      state.paymentData = payload;
    },

    CHECKOUT_SET_UNREGISTERED_USER(state, data) {
      state.unregisteredUserData = data;
    },

    CHECKOUT_SET_INSTALLMENTS(state, data) {
      state.installments = data;
    },

    CHECKOUT_SET_INSTALLMENTS_FINAL_VALUE(state, payload) {
      state.finalValueInstallments = payload;
    },

    CHECKOUT_RESET_DATA(state) {
      Object.assign(state, getCheckoutDefault());
    },

    CHECKOUT_SET_CHALLENGE(state, payload){
      state.challenge = payload;
    },

    CHECKOUT_SET_CHALLENGE_COMPLETE(state, payload){
      state.challengeComplete = payload;
    },
  },
};
