import praiseAPI from '@/api/praise';

// Checkout default data
const getPraiseDefault = () => ({
  author: null,
  code: null,
  group: null,
  id: null,
  letter: null,
  musicalScore: null,
  rhythm: null,
  showType: null,
  subtitle: null,
  theme: null,
  title: null,
  tone: null,
});

const getPraiseConfigsDefault = () => ({
  fontZoom: 1,
  chordTranspose: 0,
});

export default {
  state: {
    praise: getPraiseDefault(),
    praiseConfigs: getPraiseConfigsDefault(),
  },

  getters: {
    praiseAuthor: ({ praise }) => praise.author,
    praiseCode: ({ praise }) => praise.code,
    praiseGroup: ({ praise }) => praise.group,
    praiseId: ({ praise }) => praise.id,
    praiseLetter: ({ praise }) => praise.letter,
    praiseMusicalScore: ({ praise }) => praise.musicalScore,
    praiseRhythm: ({ praise }) => praise.rhythm,
    praiseShowType: ({ praise }) => praise.showType,
    praiseSubtitle: ({ praise }) => praise.subtitle,
    praiseTheme: ({ praise }) => praise.theme,
    praiseTitle: ({ praise }) => praise.title,
    praiseTone: ({ praise }) => praise.tone,

    praiseChordTranspose: ({ praiseConfigs }) => praiseConfigs.chordTranspose,
    praiseFontZoom: ({ praiseConfigs }) => praiseConfigs.fontZoom,

    praiseHasCipher: ({ praise: { letter } }) => !!letter && letter.some((row) => row.some((snippet) => snippet.chord !== '')),
    praiseHasLyric: ({ praise: { letter } }) => !!letter,
    praiseHasScore: ({ praise: { musicalScore } }) => !!musicalScore && musicalScore.length > 0,
  },

  actions: {
    async praiseFetch({ commit }, id) {
      try {
        const response = await praiseAPI.praiseGet(id);

        const {
          autor,
          codigo,
          grupo,
          arquivos,
          ritmo,
          subtitulo,
          tema,
          titulo,
          tom,
          conteudo,
        } = response.data;

        const musicalScore = arquivos;

        // Regex para selecionar o conteúdo do que estiver entre colchetes
        const regexpChord = /\[([^\\[^\]])*\]/g;
        // Regex para selecionar os colchetes
        const regexpBracket = /[\\[\]]/g;
        // Regex para selecionar o que estiver entre o começo da linha e o primeiro [,
        // Ou entre um ] e [
        // Ou entre um ] e o final da linha
        // Ou seja, tudo que não esta entre colchetes
        const regexpSnippet = /(\[([^\\[^\]])*\][A-Z^-ž\s\\,\\;\d\\(\\)]*)|([A-Z^-ž\s\\,\\;\d\\(\\)]*)/g;

        // Verifica se tem conteúdo e faz um split gerando um array de linhas
        const rows = conteudo ? conteudo.split('\n') : null;

        // Percorre todas as linhas separando elas em pequenos trechos
        // Em cada trecho tem o acorde que precisa ser tocado
        // E a letra a ser cantada, podendo qualquer um ser null, mas não ambos
        const letter = conteudo ? rows.map((row) => {
          const snippets = row.match(regexpSnippet);

          return snippets.map((snip) => ({
            snippet: snip.replace(regexpChord, ''),
            chord: snip.match(regexpChord)
              ? snip.match(regexpChord)[0].replace(regexpBracket, '')
              : '',
          }));
        }) : null;

        let showType;

        if (letter) {
          const hasChord = letter.some((row) => row.some((snippet) => snippet.chord !== ''));

          showType = hasChord ? 'cipher' : 'lyric';
        } else if (musicalScore && musicalScore.length > 0) {
          showType = 'score';
        } else {
          throw new Error();
        }

        const praise = {
          author: autor,
          code: codigo,
          group: grupo,
          id,
          letter,
          musicalScore,
          rhythm: ritmo,
          showType,
          subtitle: subtitulo,
          theme: tema,
          title: titulo,
          tone: tom,
        };

        commit('SET_PRAISE', praise);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    praiseChangeShowType({ commit }, type) {
      commit('SET_PRAISE_SHOW_TYPE', type);
    },
    praiseZoomInFont({ commit }) {
      commit('SET_PRAISE_FONT_ZOOM', 0.03);
    },
    praiseZoomOutFont({ commit }) {
      commit('SET_PRAISE_FONT_ZOOM', -0.05);
    },
    praiseIncreaseTone({ commit }) {
      commit('SET_PRAISE_TONE', 1);
    },
    praiseDecreaseTone({ commit }) {
      commit('SET_PRAISE_TONE', -1);
    },
    praiseResetTone({ commit }) {
      commit('SET_PRAISE_TONE', 0);
    },
  },

  mutations: {
    SET_PRAISE(state, payload) {
      state.praise = payload;
    },
    SET_PRAISE_SHOW_TYPE(state, payload) {
      state.praise.showType = payload;
    },
    SET_PRAISE_FONT_ZOOM(state, payload) {
      const zoom = state.praiseConfigs.fontZoom + payload;

      if (zoom < 1.5 && zoom > 0.7) state.praiseConfigs.fontZoom = zoom;
    },
    SET_PRAISE_TONE(state, payload) {
      state.praiseConfigs.chordTranspose = payload
        ? state.praiseConfigs.chordTranspose + payload
        : payload;
    },
  },
};
