import Vue from 'vue';
import Vuex from 'vuex';

// Persist state
import createPersistedState from 'vuex-persistedstate';

import church from './modules/churchModule';
import event from './modules/eventModule';
import give from './modules/giveModule';
import user from './modules/userModule';
import checkout from './modules/checkoutModule';
import app from './modules/appModule';
import chat from './modules/chatModule';
import inpcPlayModule from './modules/inpcPlayModule';
import celula from './modules/celulaModule';
import memberCard from './modules/memberCardModule';
import praise from './modules/praiseModule';
import podcast from './modules/podcastModule';
import videos from './modules/videosModule';
import prayer from './modules/prayerModule';
import organization from './modules/organizationModule';
import note from './modules/noteModule';
import ministry from './modules/ministry';
import publication from './modules/publicationModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    church,
    event,
    give,
    user,
    checkout,
    app,
    chat,
    inpcPlayModule,
    celula,
    memberCard,
    praise,
    podcast,
    videos,
    prayer,
    organization,
    note,
    ministry,
    publication,
  },
  plugins: [createPersistedState({
    key: 'inpeace',
    storage: window.sessionStorage,
  })],
});
