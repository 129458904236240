// Default data
const userDefault = () => ({
  name: null,
  avatar: null,
  email: null,
  id: null,
  role: null,
  autenticated: false,
});

const connectionDefault = () => ({
  channel: null,
});

const interactionMessagesDefault = () => ({
  moment: [],
});

const formatDefault = () => ({
  embed: false,
  showManagement: false,
  limitMessage: true,
});

export default {
  state: {
    user: userDefault(),
    connection: connectionDefault(),
    format: formatDefault(),
    interaction: interactionMessagesDefault(),
  },

  getters: {
    chatUserData: (state) => state.user,
    chatConnectionData: (state) => state.connection,
    chatFormatData: (state) => state.format,
    chatInteractionMomentData: (state) => state.interaction.moment,
  },

  actions: {
    chatSetUserNotLogged({ commit }, username) {
      commit('CHAT_SET_USER_DATA', {
        name: username,
        avatar: null,
        email: null,
        id: `${(Number(String(Math.random()).slice(2)) + Date.now() + Math.round(performance.now())).toString(36)}`,
        role: null,
        autenticated: false,
      });
    },

    chatSetUserLogged({ commit }, user) {
      commit('CHAT_SET_USER_DATA', {
        name: user.name,
        avatar: user.avatar,
        email: user.email,
        id: user.id,
        role: user.role,
        autenticated: true,
      });
    },

    chatResetUser({ commit }) {
      commit('CHAT_RESET_USER_DATA');
    },

    chatSetConnection({ commit }, { channel }) {
      commit('CHAT_SET_CONNECTION_DATA', {
        channel,
      });
    },

    chatResetConnection({ commit }) {
      commit('CHAT_RESET_USER_DATA');
    },

    chatSetFormat({ commit }, payload) {
      commit('CHAT_SET_FORMAT_DATA', {
        embed: 'embed' in payload ? payload.embed : false,
        showManagement: 'showManagement' in payload ? payload.showManagement : false,
        limitMessage: 'limitMessage' in payload ? payload.limitMessage : true,
      });
    },

    chatResetFormat({ commit }) {
      commit('CHAT_RESET_FORMAT_DATA');
    },

    chatSetNewMoment({ commit }, payload) {
      commit('CHAT_SET_NEW_MOMENT_DATA', {
        data: payload,
        interactions: 0,
      });
    },

    chatSetNewMomentInteraction({ commit, getters }, payload) {
      const momentInteractionCopy = Object.assign(getters.chatInteractionMomentData);

      const index = momentInteractionCopy.findIndex(
        (element) => element.data.id === payload.message_related_to,
      );

      // If the moment is not found finish the execution.
      if (index === -1) {
        return;
      }

      momentInteractionCopy[index].interactions += 1;

      commit('CHAT_SET_INTERACTION_MOMENT_DATA', momentInteractionCopy);
    },

    chatResetMoments({ commit }) {
      commit('CHAT_RESET_MOMENT_DATA', []);
    },
  },

  mutations: {
    CHAT_SET_USER_DATA(state, payload) {
      state.user = payload;
    },

    CHAT_RESET_USER_DATA(state) {
      Object.assign(state.user, userDefault());
    },

    CHAT_SET_CONNECTION_DATA(state, payload) {
      state.connection = payload;
    },

    CHAT_RESET_CONNECTION_DATA(state) {
      Object.assign(state.connection, connectionDefault());
    },

    CHAT_SET_FORMAT_DATA(state, payload) {
      state.format = payload;
    },

    CHAT_RESET_FORMAT_DATA(state) {
      Object.assign(state.format, formatDefault());
    },

    CHAT_SET_NEW_MOMENT_DATA(state, payload) {
      state.interaction.moment.push(payload);
    },

    CHAT_SET_INTERACTION_MOMENT_DATA(state, payload) {
      state.interaction.moment = payload;
    },

    CHAT_RESET_MOMENT_DATA(state, payload) {
      state.interaction.moment = payload;
    },
  },
};
