import { inpeaceAPI } from '@/config/axios/index';
import { countryStateAPI, viaCepAPI, zippopotamAPI } from '../config/axios';

export default {
  // InPeace 360 routes
  postUserLogin(data) {
    return inpeaceAPI({
      method: 'POST',
      url: '/security/login_check',
      data,
    });
  },

  putUserPassword(data) {
    return inpeaceAPI({
      method: 'POST',
      url: '/user/alterar-senha',
      data,
    });
  },

  getInPeaceAccount() {
    return inpeaceAPI({
      method: 'GET',
      url: '/me',
    });
  },

  getClientAccount({ userId, client }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/usuario?cliente=${client}`,
    });
  },

  getClient360Account({ userId, slug }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/usuario?organizacao=${slug}`,
    });
  },

  getUserDocuments({ userId, client }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/documento?cliente=${client}`,
    });
  },

  getUser360Documents({ userId, slug }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/documento?organizacao=${slug}`,
    });
  },

  getUserRole({ userId, client }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/role?cliente=${client}`,
    });
  },

  getUser360Role({ userId, slug }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/role?organizacao=${slug}`,
    });
  },

  getEmailInPeaceInfo(email) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/?email=${encodeURIComponent(email)}`,
    });
  },

  getEmailClientInfo({ client, email }) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${client}/usuario/?email=${encodeURIComponent(email)}`,
    });
  },

  postDeleteUser({ userId, churchId }) {
    return inpeaceAPI({
      method: 'POST',
      url: `/user/${userId}/pedido-exclusao?igreja=${churchId}`,
    });
  },

  // Normal
  putUser(data) {
    return inpeaceAPI({
      method: 'PUT',
      url: '/usuario/',
      data,
    });
  },

  postRegisterUser({ data, igreja }) {
    return inpeaceAPI({
      method: 'POST',
      url: `/igreja/${igreja}/usuario/`,
      data,
    });
  },

  postResetPassword(data) {
    return inpeaceAPI({
      method: 'POST',
      url: '/security/recuperar-senha',
      data,
    });
  },

  getAuthToken(payload) {
    return inpeaceAPI({
      method: 'POST',
      url: '/security/token/refresh',
      data: {
        refreshToken: payload.data,
      },
    });
  },

  getPayment(data) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario/${data.params.userId}/pagamento/?pedido=${data.params.paymentCode}`,
    });
  },

  getCards(data) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario/${data.churchSlug}/${data.userId}/cartoes`,
    });
  },

  postCreditCard(data, churchId) {
    return inpeaceAPI({
      method: 'POST',
      url: `/usuario-cartao-gateway-pagamento/?igreja=${churchId}`,
      data,
    });
  },

  deleteCard(cardId) {
    return inpeaceAPI({
      method: 'DELETE',
      url: `usuario-cartao-gateway-pagamento/${cardId}`,
    });
  },

  getData(churchSlug, userId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario/${churchSlug}/${userId}`,
    });
  },

  postEmailValidationRequest({ clientId, email }) {
    return inpeaceAPI({
      method: 'POST',
      url: `/security/solicitar-verificacao-email/?igreja=${clientId}&email=${encodeURIComponent(email)}`,
      data: null,
    });
  },

  getUserCustomField(clientId, churchId) {
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${clientId}/campo-personalizado?igreja=${churchId}`,
    });
  },

  /**
   *
   * Event
   */
  getEventsSubscribed(data) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario/${data.params.userId}/evento/`,
    });
  },

  getEventTickets(data) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario/${data.params.userId}/evento/${data.params.eventId}/inscricao/`,
    });
  },

  getEventTicketContent(data) {
    return inpeaceAPI({
      method: 'GET',
      url: `/usuario/${data.params.userId}/evento/${data.params.eventId}/inscricao/${data.params.ticketId}/conteudo/`,
    });
  },

  getNotifications(churchSlug, limit) {
    return inpeaceAPI({
      method: 'GET',
      url: `/igreja/${churchSlug}/notificacao/?page=1&limit=${limit}`,
    });
  },

  getUserPrayers(churchId){
    return inpeaceAPI({
      method: 'GET',
      url: `/user/oracao?igreja=${churchId}`,
    });
  },

  changeUserChurch(data){
    return inpeaceAPI({
      method: 'POST',
      url: '/usuario/troca-igreja',
      data: data,
    });
  },

  setUserAddress(data, userId){
    return inpeaceAPI({
      method: 'POST',
      url: `/usuario/${userId}/endereco`,
      data: data,
    });
  },

  putUserAddress(data, userId, addressId) {
    return inpeaceAPI({
      method: 'PUT',
      url: `/usuario/${userId}/endereco/${addressId}`,
      data: data,
    });
  },

  getUserAddress(userId, churchId){
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/endereco?cliente=${churchId}`,
    });
  },

  deleteUserAddress(userId,addressId) {
    return inpeaceAPI({
      method: 'DELETE',
      url: `/usuario/${userId}/endereco/${addressId}`,
    });
  },

  setUserChild(data, userId, churchId){
    return inpeaceAPI({
      method: 'POST',
      url: `/usuario/${userId}/crianca?igreja=${churchId}`,
      data: data,
    });
  },

  putUserChild(data, userId, childId) {
    return inpeaceAPI({
      method: 'PUT',
      url: `/usuario/${userId}/crianca/${childId}`,
      data: data,
    });
  },

  getUserChildren(userId, churchId){
    return inpeaceAPI({
      method: 'GET',
      url: `/user/${userId}/crianca?cliente=${churchId}`,
    });
  },

  deleteUserChild(userId,childId) {
    return inpeaceAPI({
      method: 'DELETE',
      url: `/usuario/${userId}/crianca/${childId}`,
    });
  },

  getCountries() {
    return inpeaceAPI({
      method: 'GET',
      url: '/utils/pais/',
    });
  },

  getOcupations(){
    return inpeaceAPI({
      method: 'GET',
      url: '/utils/ocupacao/',
    });
  },

  getMaritalStatus(){
    return inpeaceAPI({
      method: 'GET',
      url: '/utils/estado-civil/',
    });
  },

  getAddressViaCep(cep){
    return viaCepAPI({
      method: 'GET',
      url: `${cep}/json/`,
    });
  },

  getAddressZippopotam(country,zipcode){
    return zippopotamAPI({
      method: 'GET',
      url: `${country}/${zipcode}`,
    });
  },

  getStates(country){
    return countryStateAPI({
      method: 'GET',
      url: `${country}/states/`,
    });
  },
};

// {
//   "logradouro": "R. Des. Mario da Silva Nunes",
//   "numero": "120",
//   "complemento": "Sala 102",
//   "bairro": "Enseada do Suá",
//   "cidade": "Vitória",
//   "estado": "ES",
//   "codigoPostal": "29050-240",
//   "latitude": -20.316457,
//   "longitude": -40.2915037,
//   "pais": "1",
//   "categoria": "1",
//   "igreja": "1"
// }
