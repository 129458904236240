export default {
  state: {
    appLoading: [],
    messageBag: [],
    staffRoles: [
      'ROLE_ADMIN',
      'ROLE_ADMIN_CONTENT',
      'ROLE_SUPER_ADMIN',
    ],
    gatewayId: {
      cardconnect: 1,
      wirecard: 2,
      picpay: 4,
      stripe: 5,
      zoop: 8,
    },
  },

  getters: {
    /** Search for a load in an specific context
     * If has some GLOBAL context in stack, then this will return false
     */
    appLoading: (state) => (context) => !!state.appLoading.find(
      (loadState) => loadState.context === 'GLOBAL' || loadState.context === context,
    ),
    appStaffRoles: (state) => state.staffRoles,
    appGatewayId: (state) => state.gatewayId,
    appMessageBag: (state) => (context) => state.messageBag.find(
      (message) => message.context === context,
    ),
    appScreenSize: (state) => state.screen,
  },

  actions: {
    /**
     * Receives the load state of a context
     * The context is an strings and the global value is 'GLOBAL'
     */
    appSetLoadingState({ commit }, { context, status = false }) {
      commit('APP_SET_LOADING_STATE', {
        /**
         * You can pass 'GLOBAL' to start de loading in global level
         * or can pass any value to set the loading in places that the value is passed
         * */
        context,
        status,
      });
    },

    /**
     * Reset de loading state
     */
    appResetLoadingState({ commit }) {
      commit('APP_RESET_LOADING_STATE');
    },

    appSetMessageBag({ commit }, {
      context, message, type = null, helper = null,
    }) {
      commit('APP_SET_MESSAGE_BAG', {
        context,
        type,
        helper,
        message,
      });
    },

    appRemoveMessageBag({ commit }, { context, all = false }) {
      commit('APP_RESET_MESSAGE_BAG', { context, all });
    },

    appSetScreenSize({ commit }, size) {
      commit('APP_SET_SCREEN_SIZE', size);
    },
  },

  mutations: {
    APP_SET_LOADING_STATE(state, { context, status }) {
      if (!status) {
        const index = state.appLoading.findIndex(
          (loadingState) => loadingState.context === context,
        );

        if (index === -1) {
          return;
        }

        state.appLoading.splice(index, 1);
      } else {
        state.appLoading.push({
          context,
          status,
        });
      }
    },

    APP_RESET_LOADING_STATE(state) {
      state.appLoading = [];
    },

    APP_SET_SCREEN_SIZE(state, payload) {
      state.screen = payload;
    },

    APP_SET_MESSAGE_BAG(state, payload) {
      state.messageBag.push(payload);
    },

    APP_RESET_MESSAGE_BAG(state, data) {
      if (data.all) {
        state.messageBag = state.messageBag.filter((message) => message.context !== data.context);
      } else {
        const index = state.messageBag.findIndex(
          (loadingState) => loadingState.context === data.context,
        );

        if (index === -1) {
          return;
        }

        state.messageBag.splice(index, 1);
      }
    },
  },
};
