import axios from 'axios';

// Checkout default data
const eventCheckoutDefault = () => ({
  event: null,
  tickets: null,
  value: null,
});
const eventSingleDefault = () => ({
  data: null,
  boughtTickets: null,
});
const nullDefault = () => null;

export default {
  state: {
    data: {
      all: nullDefault(),
      single: eventSingleDefault(),
    },
    requestError: nullDefault(),
    checkout: eventCheckoutDefault(),
  },

  getters: {
    events: (state) => state.data.all,
    eventSingle: (state) => state.data.single.data,
    eventBoughtTickets: (state) => state.data.single.boughtTickets,
    eventCheckout: (state) => state.checkout,
    eventRequestError: (state) => state.requestError,
    eventGetFinalValue: (state) => state.checkout.value,
  },

  actions: {
    eventGetAll({ commit }, churchSlug) {
      return axios.get(`${process.env.VUE_APP_API_V1}/igreja/${churchSlug}/evento/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        commit('EVENT_SET_ALL', response.data.eventos);

        return response;
      }).catch((error) => {
        commit('EVENT_RESET_ALL');

        return error.response;
      });
    },

    eventGetSingle({ commit, getters }, eventId) {
      return axios.get(`${process.env.VUE_APP_API_V1}/igreja/${getters.churchData.slug}/evento/${eventId}/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        commit('EVENT_SET_SINGLE', response.data);

        return response;
      }).catch((error) => {
        commit('EVENT_RESET_SINGLE');

        return error.response;
      });
    },

    eventGetBoughtTickets({ commit, getters }, eventId) {
      return axios.get(
        `${process.env.VUE_APP_API_V1}/usuario/${getters.userInpeaceAccount.id}/evento/${eventId}/inscricao/`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getters.userToken}`,
          },
        },
      ).then((response) => {
        commit('EVENT_SET_SINGLE_BOUGHT_TICKETS', response.data.inscricoes);

        return response;
      }).catch((error) => {
        commit('EVENT_RESET_SINGLE_BOUGHT_TICKETS');

        return error.response;
      });
    },

    // Event
    eventSetCheckoutEvent({ commit }, event) {
      commit('EVENT_SET_CHECKOUT_EVENT', event);
    },

    eventResetCheckoutEvent({ commit }) {
      commit('EVENT_RESET_SINGLE');
    },

    // Tickets
    eventSetCheckoutTickets({ commit }, tickets) {
      commit('EVENT_SET_CHECKOUT_TICKETS', tickets);
    },

    eventUpdateCheckoutTicket({ commit }, payload) {
      commit('EVENT_UPDATE_CHECKOUT_TICKET', payload);
    },

    eventResetCheckoutTickets({ commit }) {
      commit('EVENT_RESET_CHECKOUT_TICKETS');
    },

    // Value
    eventSetCheckoutValue({ commit }, value) {
      commit('EVENT_SET_CHECKOUT_VALUE', value);
    },

    // Global
    eventResetCheckout({ commit }) {
      commit('EVENT_RESET_CHECKOUT');
    },
  },

  mutations: {
    // All
    EVENT_SET_ALL(state, events) {
      state.data.all = events;
    },

    EVENT_RESET_ALL(state) {
      state.data.all = null;
    },

    // Single
    EVENT_SET_SINGLE(state, payload) {
      state.data.single.data = payload;
      state.data.single.boughtTickets = null;
    },

    EVENT_SET_SINGLE_BOUGHT_TICKETS(state, payload) {
      state.data.single.boughtTickets = payload;
    },

    EVENT_RESET_SINGLE_BOUGHT_TICKETS(state) {
      state.data.single.boughtTickets = null;
    },

    EVENT_RESET_SINGLE(state) {
      state.data.single = Object.assign(eventSingleDefault());
    },

    // Checkout
    EVENT_SET_CHECKOUT_TICKETS(state, payload) {
      state.checkout.tickets = payload;
    },

    EVENT_UPDATE_CHECKOUT_TICKET(state, payload) {
      state.checkout.tickets[payload.position] = payload.ticket;
    },

    EVENT_SET_CHECKOUT_VALUE(state, payload) {
      state.checkout.value = payload;
    },

    EVENT_SET_CHECKOUT_EVENT(state, payload) {
      state.checkout.event = payload;
    },

    EVENT_RESET_CHECKOUT_TICKETS(state) {
      state.checkout.tickets = nullDefault();
    },

    // Global
    EVENT_RESET_CHECKOUT(state) {
      state.checkout = Object.assign(eventCheckoutDefault());
    },

    // Error
    EVENT_SET_REQUEST_ERROR(state, error) {
      state.requestError = error;
    },

    EVENT_RESET_REQUEST_ERROR(state) {
      state.requestError = nullDefault();
    },
  },
};
