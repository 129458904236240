module.exports = {
  CONTENT: {
    ABOUT_US: {
      BUTTON: {
        DIRECTIONS: 'How to get there',
      },
      TITLE: {
        INTERNAL: {
          ABOUT: 'About us',
          CHURCH: 'The Church',
          CONTACT: 'Contact',
          LOCATION: 'Location',
          SOCIAL: 'Social Media',
        },
        PAGE: {
          HOME: 'About Us',
        },
      },
    },
    ACCESS: {
      BUTTON: {
        LOGIN: 'Login',
        NEXT: 'Next',
        RECOVER_PASSWORD: 'I forgot my password',
        REGISTER: 'Register',
      },
      MESSAGE: {
        ERROR: {
          INACTIVE_ACCOUNT: 'You already have an account, but we were unable to send the activation email, please try again.',
        },
        SUCCESS: {
          INACTIVE_ACCOUNT: 'You already have an account, but it is not active. We are sending you an activation email.',
          RECOVER_PASSWORD: 'We have sent you a password recovery email.',
        },
      },
      MODAL: {
        ACTIVE_ACCOUNT: {
          ACTION: 'I understand',
          DESCRIPTION: 'Account activation has been sent to your registered email.',
          TITLE: 'Activate your <span class="title__featured">InPeace Account</span>',
        },
        INPEACE_ACCOUNT: {
          ACTIVATED: {
            LINE_ONE: 'We verified that you already have an <span class="line__featured">InPeace Account.</span>',
          },
          AGREE: {
            ACTION: 'I understand',
            MESSAGE: 'Log in or recover your password.',
          },
          ENTRY: 'Look how nice it is',
          INFO: {
            LINE_ONE: 'The InPeace Account is now unique.',
            LINE_TWO: 'With it you have access to all of our applications.',
          },
          UNACTIVATED: {
            LINE_ONE: 'We verified that you already have an <span class="line__featured">InPeace Account.</span>',
            LINE_TWO: 'Activate your account and complete the registration.',
          },
        },
        RECOVER_PASSWORD: {
          ACTION: 'I understand',
          TEXT: 'Password recovery was sent to your registered email.',
        },
      },
      SUBTITLE: 'Insert your email to continue',
      TITLE: {
        ACCESS: 'Welcome!',
        CLIENT_REGISTER: 'Complete Registration',
        INPEACE_REGISTER: 'Register',
        PAGE: 'Access',
      },
    },
    ALERT_MESSAGE: {
      TITLE: 'Error',
    },
    CELULAS: {
      INDEX: {
        CELULAS: {
          AVAILABLE: 'Available {groups}',
          USER: '{groups} I participate in',
        },
        EMPTY: {
          BUTTON: 'Return',
          SUBTITLE: 'Quando estiver disponível você poderá visualizar ou solicitar sua participação.',
          TITLE: 'Ainda não há {grupos} aqui',
        },
        FILTERS: {
          INPUT: 'Search by group name',
          SEARCH: 'Do your search',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
            SEARCH: 'There are no results for this search',
            TERM: 'How about trying with another name or keyword?',
          },
        },
      },
      MEETINGS: {
        INDEX: {
          BUTTON: 'SEE STUDY',
          EMPTY: {
            SUBTITLE: 'When a meeting is added it will be available for you to view.',
            TITLE: 'There are no meetings here yet',
          },
          START_FINISH_TIME: '{start} to {finish}',
          TAG: 'VER THEME',
          TITLE: 'Meetings',
        },
        SINGLE: {
          START_FINISH_TIME: '{start} to {finish}',
          TITLE: 'Discussion',
        },
      },
      MODULE_NAME: 'Groups',
      SINGLE: {
        ACTIONS: {
          BUTTON: 'Participate',
          BUTTONS: {
            CANCEL: 'Cancel',
            CONFIRM: 'Confirm',
            MEETINGS: 'Meetings',
            YES: 'Yes',
          },
          PARTICIPATE: 'To view meetings and have access to other information download the app',
          REQUEST: 'Your request to participate will be sent to the leader. Do you want to confirm?',
          TEXT: 'Access your account or register to request your participation.',
        },
        INFO: {
          ADDRESS: 'Address',
          BUTTON: 'How to get there?',
          FUNCTION: '{group} leader',
          RESPONSIBLE: 'Responsible',
          REUNION: 'Meeting',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'No matching group was found.',
            REQUEST: 'We were unable to submit your request.',
          },
          SUCCESS: 'Your request has been sent successfully!',
        },
      },
      STUDIES: {
        MODULE_NAME: 'Available studies',
      },
      SUCCESS: {
        BUTTON: 'Return',
        SUBTITLE: 'We appreciate your interest in participating.',
        TITLE: 'Your request has been sent successfully',
      },
    },
    CHAT: {
      BUTTON: {
        ACCESS_LOGIN: 'Enter / Register',
        ACCESS_NO_LOGIN: 'Enter in the chat',
        CALL_ACCESS_MODAL: 'Sign in to participate',
        DELETE_ALL: 'Delete all',
        DELETE_SINLGE: 'Delete this',
        MODAL_DELETE_CANCEL: 'Cancel',
        MODAL_DELETE_CONFIRM: 'Okay',
      },
      FORM: {
        BUTTON: {
          SEND_MESSAGE: 'Send',
        },
        INPUT: {
          NEW_MESSAGE: 'Send a message',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'We were unable to send your message at this time.',
          },
        },
      },
      MESSAGES: {
        JOIN: 'joined',
        MOMENT: {
          COMMIT_TO_JESUS: {
            BUTTON: {
              DISMISS: 'Not now',
              RAISE_HAND: 'Raise the hand',
            },
            TEXT: {
              COMMITED: {
                SUBTITLE: 'We will pray with you.',
                TITLE: 'Today, you are starting a new life with Jesus!',
              },
              CREATED: {
                SUBTITLE: 'Raise your hand and let us know',
                TITLE: 'I commit my life to Jesus.',
              },
            },
          },
        },
      },
      TEXT: {
        ACCESS_MODAL: 'You must enter your name to access the chat',
        CONNECTING: 'Connecting...',
        MODAL_DELETE_ALL_USER_MESSAGES: 'Do you confirm deleting all the messages for user {username}?',
        MODAL_DELETE_SINGLE_MESSAGE: 'Do you confirm deleting this message for user {username}?',
        NO_MESSAGES: 'No messages yet',
      },
      TITLE: {
        ACCESS_MODAL: 'Tell us your name',
        MODAL_DELETE_ALL_USER_MESSAGES: 'Delete messages?',
        MODAL_DELETE_SINGLE_MESAGE: 'Delete message?',
      },
    },
    CHECKOUT: {
      BUTTON: {
        METHOD: {
          ACH: 'ACH',
          BOLETO: 'Bank Slip',
          CREDIT_CARD: 'Credit Card',
          MONEY: 'Cash or transfer',
          PAYPAL: 'PayPal',
          PICPAY: 'PicPay',
          PIX_MANUAL: 'Pix',
        },
      },
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Something went wrong. Please refresh the page and try again.',
        },
        INFO: {
          BR_OPTIONS: 'Payments via Pix and bank slip are only available to addresses in Brazil. If you are outside Brazil, use another payment method or update your registered address.',
        },
      },
      TEXT: {
        CREDIT_CARD: {
          MODAL: {
            BUTTON: {
              CANCEL: 'N0',
              YES: 'YES',
            },
            SUBTITLE: 'If you need to use the card again, you will need to re-register it.',
            TITLE: ' Do you really want to delete credit card ending with {number} ?',
          },
        },
        INSTALLMENTS: {
          SELECT: {
            WITH_INTEREST: 'with interest',
            WITHOUT_INTEREST: 'without interest',
          },
          TITLE: 'Select the installment plan:',
        },
        PAYMENT: {
          PIX: {
            COPY_KEY: 'Copy',
            EVENT: 'If the payment is not confirmed, don\'t worry. The order will be canceled automatically.',
            HELP: 'The code is only valid until {hour} on {day}.',
          },
          PIX_MANUAL: {
            COPY_KEY: 'Copy',
            KEY_TYPE: {
              CNPJ: 'CNPJ',
              CPF: 'CPF',
              EMAIL: 'Email',
              PHONE: 'Cell phone',
              RANDOM: 'Random',
            },
            PIX_KEY_TEXT: 'Pix key',
          },
          PROCESSED_BY: 'Processed By',
        },
        THANK_YOU: {
          DOWNLOAD_APP: 'Don\'t have the {0} App yet? Download now and follow all about {0}!',
        },
      },
      TITLE: {
        PAGE: {
          HOME: 'Checkout',
          THANK_YOU: 'Thank You',
        },
      },
    },
    DEVOTIONAL: {
      EMPTY: {
        BUTTON: 'Back to start',
        SUBTITLE: 'When content is published, you can view it here.',
        TITLE: 'No posts here yet',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filter by category',
          INPUT: 'Search by title or keyword',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
            SEARCH_DESCRIPTION: 'How about trying another title or keyword?',
            SEARCH_TITLE: 'There are no results for this search',
          },
        },
      },
      MODULE_NAME: 'Devotional',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'An error occurred while fetching the data.  Please try again.',
            NOT_FOUND: 'Content does not exist or is unavailable.',
          },
        },
      },
    },
    EBD: {
      EMPTY: {
        BUTTON: 'Back to start',
        SUBTITLE: 'When content is published, you can view it here.',
        TITLE: 'No posts here yet',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filter by category',
          INPUT: 'Search by title or keyword',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
            SEARCH_DESCRIPTION: 'How about trying another title or keyword?',
            SEARCH_TITLE: 'There are no results for this search',
          },
        },
      },
      MODULE_NAME: 'Sunday School',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'An error occurred while fetching the data.  Please try again.',
            NOT_FOUND: 'Content does not exist or is unavailable.',
          },
        },
      },
    },
    ERROR_PAGE: {
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'Something went wrong while fetching the data. Please try again.',
        },
      },
    },
    EVENT: {
      ALL: {
        BUTTON: {
          BACK_HOME: 'Back to home',
        },
        EMPTY: {
          SUBTITLE: 'You will be able to register when a new event is added.',
          TITLE: 'No events here yet',
        },
        MESSAGE: {
          ERROR: {
            NO_EVENTS: '{church} has no events at this time.',
          },
        },
        TEXT: {
          EXCLUSIVE_CONTENT: 'Exclusive content',
        },
        TITLE: {
          INTERNAL: {
            CHECK_OUR_EVENTS: 'Check out all our events and courses',
          },
          PAGE: 'All Events',
        },
      },
      CHECKOUT: {
        BUTTON: {
          PAYMENT: {
            CONFIRM: 'Confirm',
          },
          THANK_YOU: {
            DOWNLOAD_BOLETO: 'Download Invoice',
            OPEN_PICPAY: 'Open PicPay',
            SUBSCRIVE_AGAIN: 'Do you want to make another registration?',
            VIEW_MORE: 'See more event options',
          },
        },
        TEXT: {
          PAYMENT: {
            EXCLUSIVE_CONTENT: 'Ticket with exclusive content',
            FEES_LABEL: '(with fees)',
            METHODS: {
              BOLETO: 'You will receive the bank slip by email and your registration will be effective after payment confirmation.',
              DINHEIRO: {
                DAYS_TO_PAY: 'You have {days} amount of days to make payment for ticket.',
                RULE_MESSAGE: 'Contact someone responsible for the even for more information.',
              },
              FREE: 'This event is free, but the ticket is still required. For more information you can contact the Church.',
              PAYPAL: 'Na próxima tela você poderá acessar o PayPal e efetivar o pagamento. Seu ingresso só será válido após a confirmação do pagamento.',
              PICPAY: {
                RULE_MESSAGE: 'See more by clicking on the button below, download or access picpay app and finalize your registration.',
              },
              PIX: {
                RULE_MESSAGE: 'Confirm using the button below to proceed with your payment.',
              },
            },
            TOTAL_PRICE: 'Amount:',
            TICKET_PRICE: 'Ticket price:',
            SERVICE_PRICE: 'Service fee:',
            PROCESS_PRICE: 'Processing fee:',
            USER: 'User',
          },
          THANK_YOU: {
            EXCLUSIVE_CONTENT: 'Ticket with exclusive content',
            FINAL_MESSAGE: 'You can follow your subscription information in the {name} app.',
            METHODS: {
              BOLETO: {
                em_analise: 'You can access the bank slip at the link below but you will also receive it by email and your registration will be effective after payment confirmation.',
                pendente: 'You can access the bank slip at the link below but you will also receive it by email and your registration will be effective after payment confirmation.',
              },
              CREDIT_CARD: {
                aprovado: 'Your registration has been successfully made',
                em_aberto: 'Registration is being processed. You will receive a confirmation email and soon your tickets will be available on our platform.',
                em_analise: 'Registration is being processed. You will receive a confirmation email and soon your tickets will be available on our platform.',
                pendente: 'Registration is being processed. You will receive a confirmation email and soon your tickets will be available on our platform.',
              },
              DINHEIRO: {
                aguardando: 'Pay the entrance fee. You will receive a confirmation email and soon your tickets will be available on our platform.',
              },
              FREE: {
                SUCCESS: 'Your registration was successfully made, and your ticket was sent to your registered email.',
              },
              PICPAY: {
                aguardando: 'Open picpay to finalize transaction',
              },
              PIX: {
                pendente: 'Open your bank app, scan the QR code or paste the key to make the payment.',
              },
            },
            PIX: 'After paying the registration fee, you will receive a confirmation email and your tickets will be available on the platform.',
            TICKET_DATA: {
              ADDRESS: 'Address',
              BUYER: 'Buyer',
              DATE: 'Date',
              EVENT: 'Event name',
              GET_IN_TOUCH: 'Get in touch for more information.',
              LOCATION: 'Location',
              TICKET: 'Ticket',
              TICKETS: 'Tickets',
              TIME: 'Time',
              USER: 'User',
            },
            TITLE: 'Thank you for your registration',
          },
        },
        TITLE: {
          INTERNAL: {
            PAYMENT: 'Payment',
            TICKETS: 'Tickets',
          },
        },
      },
      GLOBAL: {
        TEXT: {
          DATE: {
            DIFFERENT: '{start} {monthStart} to {end} {monthEnd}',
            SAME: '{start} {monthEnd}',
          },
        },
      },
      QUESTIONS: {
        BUTTON: {
          NEXT: 'Next',
        },
        TEXT: {
          STEP: 'Ticket <span class="numbers__number">{actual}/{quantity}</span>',
          TICKET_DATA: 'Enter the name of who will use the ticket.',
        },
        TITLE: {
          INTERNAL: {
            IMPORTANT_INFO: 'Important informations',
            TICKET_DATA: 'Ticket Date',
            TICKET_TITLE: 'Ticket {name}',
          },
        },
      },
      SINGLE: {
        BUTTON: {
          STATUS: {
            CLOSED: 'Unavailable',
            ONLINE: 'Access',
            REGISTER: 'Register',
            REGISTER_AGAIN: 'Register Again',
            UNAVAILABLE: 'Inscription Unavailable',
          },
          TICKET_HAS_CONTENT: {
            FALSE: 'My tickets',
            TRUE: 'View content',
          },
          VIEW_MORE: 'See more events / courses',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Something went wrong while fetching the event. Please try again.',
            NOT_FOUND: 'This event is not available or the address is incorrect.',
          },
        },
        TEXT: {
          AGREE: 'I agree to the terms',
          AVAILABLE_AT: 'Tickets will be available from {date}',
          DATE: {
            DIFFERENT: '{start} to {end}',
            SAME: '{start}',
          },
          EXCLUSIVE_CONTENT: 'Exclusive content',
          EXCLUSIVE_CONTENT_INFO: 'This event has exclusive content that will be available online.',
          INFO: {
            DATE: 'Date',
            LOCATION: 'Location',
            SCHEDULE: 'Schedule',
          },
          PAYMENT_METHODS: {
            BOLETO: 'Bank Slip',
            CREDIT_CARD: 'Credit card',
            DINHEIRO: 'Cash or transfer',
            PAYPAL: 'PayPal',
            PICPAY: 'PicPay',
            PIX: 'Pix',
            PIX_MANUAL: 'Pix',
            TRANSFERENCIA: 'Money Transfer',
          },
          PRICE: {
            FREE: 'Free',
            SINGLE: '{initial}',
            TWO: '{initial} to {final}',
          },
          SCHEDULE_TIME: '{start} to {end}',
          SHARE: '{0}',
        },
        TITLE: {
          CONTACT: 'Please contact us if you have any questions:',
          INTERNAL: {
            CHECK_IT: 'Check this out as well',
            COMPLEMENT_INSTALLMENTS: '{installments} amount',
            INSTALLMENTS: 'Payments of:',
            INSTALLMENTS_OF: '',
            PARTICIPATION_INFO: 'Participation Information',
            PAYMENT_METHODS: {
              TITLE_PLURAL: 'Forms of Payment',
              TITLE_SINGULAR: 'Form of Payment',
            },
            TICKET_FROM: 'Tickets from',
          },
        },
      },
      TICKETS: {
        BUTTON: {
          NEXT: 'Next',
          READ_MORE: 'See',
        },
        TEXT: {
          EXCLUSIVE_CONTENT: 'Ticket with exclusive content',
          FEES: 'Fees',
          FREE: 'Free',
          PAYMENT_MESSAGE: 'Cash or transfer payment information.',
          PRICE: 'Price',
          QUANTITY: 'QTD',
          STATUS: {
            AVAILABLE_IN: 'Available from {date}',
            AVAILABLE_UNTIL: '(Available until {date} or sold out)',
            EXPIRED: 'Expired',
            SOLD_OUT: 'Sold Out',
            UNTIL: 'until {date}',
            USER_LIMIT: 'You have purchased the maximum allowed for this ticket.',
          },
          TOTAL_PRICE: 'Amount:',
        },
        TITLE: {
          INTERNAL: {
            ATTENTION: 'Attention',
            CHOOSE_EVENT_TICKETS: 'Choose tickets for the event',
            CHOOSE_TICKETS: 'Choose Tickets',
            PAYMENT_INFO: 'Payment Information',
          },
          PAGE: 'Tickets',
        },
      },
    },
    FOOTER: {
      ADDRESS: 'Address',
      ADMIN_ACCESS: 'Admin Access',
      CONTACT: 'Contact',
      DEVELOPED_BY: 'Developed by',
      FOLLOW_US: 'Follow us',
      LEGAL_NAME: 'Legal Name of Organization',
      TERMS: '<a href="{terms}" rel="noreferrer" class="link" target="_blank">Terms & Conditions</a> and <a href="{privacy}" rel="noreferrer" class="link" target="_blank">Privacy Policy</a>.',
    },
    FORM: {
      BUTTON: {
        CANCEL: 'Cancel',
        DELETE: 'Delete',
        EDIT: 'Edit',
        SAVE: 'Save',
        SHOW_CARD_LIST: 'Show saved cards',
        SHOW_MANUAL_CARD: '+ use another card',
      },
      LABEL: {
        ACCOUNT_NUMBER: 'Bank account',
        ACCOUNT_TYPE: 'Select the account type',
        ADDRESS: 'Address',
        AMOUNT: 'Amount',
        ANOTHER_CARD: {
          EVENT: 'Want to pay with another card?',
          GIVE: 'Want to donate with another card?',
        },
        BIRTHDATE: 'Birthdate',
        BIRTHDATE_HOLDER: 'Holder birthdate',
        CARD_HOLDER: 'Card holder',
        CHECKING_ACCOUNT: 'Checking Account',
        CHILDREN: 'Children',
        CITY: 'City',
        COMMENTS: 'Comments',
        COMPLEMENT: 'Complement',
        CONFIRM_PASSWORD: 'Confirm Password',
        CONGREGATION: 'Congragation',
        COUNTRY: 'Country',
        CPF: 'CPF',
        CPF_HOLDER: 'Holder CPF',
        CREATE_ACCOUNT: 'Do you want to create an account?',
        CREDIT_CARD_NUMBER: 'Credit card number',
        CVV: 'CVV',
        DESCRIBE_HERE: 'What would you like to request?',
        DUE_DATE: 'Expiration date',
        EMAIL: 'Email',
        FEMALE: 'Female',
        FREQUENCY_IN_DAYS: 'Transaction frequency?',
        IMAGE: 'Add an image',
        MALE: 'Male',
        MARITAL_DATE: 'Wedding date',
        MARITAL_STATUS: 'Marital status',
        MOTIVE: 'What is the reason for your request?',
        NAME: 'Name',
        NEIGHBORHOOD: 'Neighborhood',
        NO: 'NO',
        NO_LOWERCASE: 'No',
        NUMBER: 'Number',
        NUMBER_OF_CHILDREN: 'Number of Children',
        OCUPATION: 'Ocupation',
        PASSWORD: 'Password',
        PHONE: 'Phone',
        PRAYER_NAME: 'What is your name?',
        RECURRENCY: 'Reoccurring transaction?',
        ROUTING_NUMBER: 'Routing account',
        SAVE_CARD: 'Save Credit ?',
        SAVINGS_ACCOUNT: 'Savings Account',
        SELECT_CARD: 'Choose the card you want to use',
        SEX: 'Sex',
        SEX_MEN: 'Male',
        SEX_WOMEN: 'Female',
        STATE: 'State',
        STREET: 'Name of the street',
        SURNAME: 'Surname',
        VALUE: 'Price',
        YES: 'YES',
        YES_LOWERCASE: 'Yes',
        YOU_ARE_CARD_HOLDER: 'Are you the cardholder?',
        ZIPCODE: 'Zipcode',
        ZIPCODE_FOR_ALL: 'Zipcode',
      },
      MARITAL_STATUS: {
        CIVIL_MARIED: 'Civil Married',
        CIVIL_RELIGIOUS: 'Civil and religious married',
        DIVORCED: 'Divorced',
        LIVE_TOGETHER: 'Lives together',
        MARIED: 'Married',
        SINGLE: 'Single',
        STABLE: 'Stable union',
        WIDOWER: 'Widower',
      },
      MESSAGE: {
        ERROR: {
          GENERIC_ERROR: 'An error occurred while trying to send the data. Try again.',
        },
      },
      PLACEHOLDER: {
        ACCEPTANCE_TERM_FIRST: 'I am aware and agree with the',
        ACCEPTANCE_TERM_SECOND: 'and',
        ACCOUNT_NUMBER: 'Account Number',
        ACCOUNT_TYPE: 'Account Type',
        ADDRESS: 'Inform your street',
        AMOUNT: 'Amount',
        BIRTHDATE: 'MM/DD/YYYY',
        BIRTHDATE_HOLDER: 'MM/DD/YYYY',
        CARD_HOLDER: 'Name printed on card',
        CITY: 'Enter your city',
        COMMENTS: 'Allergies, special care, blood type, etc.',
        COMPLEMENT: 'Block, Apt, point of reference',
        CONFIRM_PASSWORD: 'Enter the password again',
        CONGREGATION: 'Select your congragation',
        COUNTRY: 'Select a country',
        CPF: 'Enter the holder\'s CPF',
        CPF_HOLDER: 'Holder CPF',
        CREATE_ACCOUNT: 'Do you want to create an account?',
        CREDIT_CARD_NUMBER: 'Enter the card number',
        CVV: 'Security Code',
        DESCRIBE_HERE: 'Enter message here',
        DUE_DATE: 'MM/AA',
        DUE_DATE_SIX_DIGITS: 'MM/AAAA',
        EMAIL: 'Enter your email',
        ENTER_NAME: 'Enter your name',
        FEMALE: 'Female',
        FREQUENCY_IN_DAYS: 'Frequency in days',
        IMAGE: 'Add',
        MALE: 'Male',
        MARITAL_STATUS: 'Select your marital status',
        MOTIVE: 'Motive',
        NAME: 'eg: Mary',
        NEIGHBORHOOD: 'Enter your neighborhood',
        NO: 'No',
        NUMBER: 'Inform the number',
        OCUPATION: 'Select your occupation',
        PASSWORD: 'Enter your password',
        PHONE: 'Phone',
        PRIVACY_POLICY: 'privacy policy',
        RECURRENCY: 'Pay Recurrently?',
        ROUTING_NUMBER: 'Routing number',
        SAVE_CARD: 'Save Card ?',
        SEX: 'Sex',
        STATE: 'Enter your state',
        SURNAME: 'eg: Smith',
        TERMS_OF_SERVICE: 'terms of service',
        VALUE: 'Price',
        YES: 'Yes',
      },
      VALIDATION: {
        CARD_TOKEN_FAIL: 'Some card data is invalid, please check.',
        CPF: 'Invalid CPF number.',
        PHONE: 'Please check the phone number.',
        REQUIRED: 'This field is required.',
        ZIPCODE: 'Invalid zipcode',
      },
    },
    GIVE: {
      CHECKOUT: {
        BUTTON: {
          PAYMENT: {
            CONFIRM: 'Confirm',
          },
          THANK_YOU: {
            DONATE_AGAIN: 'Do you want to make another donation?',
            DOWNLOAD_BOLETO: 'Download Invoice',
            OPEN_PICPAY: 'Open PicPay',
            VIEW_MORE: 'See more donation options',
          },
        },
        FORM: {
          ACH: {
            TITLE: 'Add banking information:',
          },
          VALIDATION: {
            MIN_VALUE: 'The value is required.',
            PICPAY: {
              MAX_VALUE: 'The maximum amount with PicPay is R$ 4,999.99. <br> You can make multiple donations with this limit.',
            },
          },
        },
        MESSAGE: {
          GENERIC_ERROR: 'Something went wrong. Please refresh the page and try again.',
        },
        TEXT: {
          PAYMENT: {
            CHOOSE_AMOUNT: 'How much do you want to help',
            FEES: 'Collaborate with {0} transaction fees',
            METHODS: {
              BOLETO: 'You will receive the bank slip by email and your donation will be effective after payment confirmation.',
              PICPAY: {
                RULE_MESSAGE: 'Confirm by pressing button below, download or access Picpay App and finalize transaction.',
              },
              PIX: {
                RULE_MESSAGE: 'Confirm by pressing button below, to finalize your transaction.',
              },
              PIX_MANUAL: 'Copy the pix key below to donate through your bank app.',
            },
          },
          THANK_YOU: {
            ACH: {
              aprovado: 'Your donation has been successfully received by {0}',
              nao_aprovado: 'Your donation to {0} has been sent and will be confirmed by your bank. You will receive an email with more information.',
            },
            BOLETO: {
              em_analise: 'You can access the bank slip at the link below but you will also receive it by email and your donation will be effective after payment confirmation.',
              pendente: 'You can access the bank slip at the link below but you will also receive it by email and your donation will be effective after payment confirmation.',
            },
            CREDIT_CARD: {
              aprovado: 'Your donation has been successfully received by {0}',
              em_aberto: 'Your donation towards {0} is processing. You will receive an email with more information.',
              em_analise: 'Your donation towards {0} is processing. You will receive an email with more information.',
              pendente: 'Your donation towards {0} is processing. You will receive an email with more information.',
            },
            PICPAY: {
              aguardando: 'Open picpay to finalize transaction',
            },
            PIX: {
              pendente: 'Open your bank app, scan the image or paste the QR Code to make the payment.',
            },
            TITLE: 'Thank you for your donation',
          },
        },
        TITLE: {
          INTERNAL: {
            CHECKOUT: 'You are helping {0} with {1}',
          },
        },
      },
      HOME: {
        BUTTON: {
          NEXT: 'Continue',
          NEW_REASON: 'Additional donation',
          TOTAL: 'Total:',
        },
        MESSAGE: {
          ERROR: {
            GENERIC_ERROR: 'Something went wrong. Please refresh the page and try again.',
            NO_GIVE: 'There is no giving options at this moment.',
            NO_MODULE: 'At this time this church is not accepting donations through the internet.',
            MIN_VALUE: 'Donation amount must be greater than {minValue}',
          },
        },
        TEXT: {
          CHOOSE_AMOUNT: 'How much do you want to donate?',
          CHOOSE_METHOD: 'What is the reason for donating?',
          CHOOSE_OPTION: 'Select the reason',
          EDIT: 'Edit Value',
        },
        TITLE: {
          INTERNAL: {
            GIVE: 'Give',
          },
          PAGE: 'Give',
        },
      },
      RECURRENCY: {
        BUTTON: {
          NEXT: 'Next',
        },
        TEXT: {
          DAYS: 'days',
          INFO: 'You will be charged in:',
          OBS1: '* Recurring payments are allowed for one option. If you add more, it will be disabled.',
          OBS2: '* The to activate recurrence option will be available only for reasons chosen by the church.',
        },
        TITLE: {
          INTERNAL: {
            RECURRENCY: 'Recurrency',
          },
          PAGE: 'Give Recurrency',
        },
      },
    },
    GLOBAL: {
      ERROR: {
        GENERIC_ERROR: 'Something went wrong while fetching the data. Please try again.',
      },
      MODAL: {
        BUTTON: {
          CANCEL: 'Cancel',
          OKAY: 'Okay',
        },
      },
      OFFLINE: {
        MESSAGE: 'You are offline. Check your internet connection to continue using the app',
      },
      TEXT: {
        COPY_RIGHTS: 'All Rights Reserved',
      },
    },
    HEADER: {
      ACCESS_TEXT: 'Hello, log in to your account or sign up.',
      LINKS: {
        MORE: 'More',
      },
      LOGIN: 'Log in',
      MENU: {
        BACK: 'Return',
        HOME: 'Home',
        INPEACE_PLAY: 'Play',
        INSCRIPTIONS: 'Inscriptions',
        LOGOUT: 'Logout',
        PROFILE: 'My account',
      },
      NOTIFICATIONS: {
        LAST_UPDATE: '{day} at {hour}',
        TITLE: 'Notifications',
        MODAL: {
          TEXT: 'To access more information, you will need to change the church. Do you want to make the change now?',
          BUTTON: 'To change',
        },
      },
      REGISTER: 'Sign up',
      SALUTATION: 'Hello',
    },
    HOME: {
      BANNER: {
        TITLE: 'Welcome',
      },
      CTA: {
        SUB_TITLE: 'And take us anywhere',
        TITLE: 'Download our app',
        TITLE_MOBILE: 'Download our app and take us anywhere',
      },
      EVENTS: {
        BUTTON: 'See upcoming events',
        DATE: {
          DAY: {
            DIFFERENT: '{start} {monthStart} to {end} {monthEnd}',
            SAME: '{start} {monthEnd}',
          },
        },
        SUBTITLE: 'Upcoming',
        TEXT: 'The events help in the mission to strengthen our faith every day and to reconnect with God.',
        TITLE: 'Our Events',
      },
      GIVING: {
        BUTTON: 'Make a donation',
        TEXT: 'Giving is a way of thanking God for all the blessings in our lives. And it\'s also how we plant the seed in search of some specific grace.',
        TITLE: 'Giving',
      },
      MESSAGE: {
        ERROR: {
          GENERIC: 'Something went wrong while fetching the data. Please refresh the page.',
        },
      },
      MODULES: {
        ABOUT: 'About Us',
        EVENTS: 'Events',
        GIVE: 'Give',
        LIVE: 'Schedule',
        SUBSCRIPTION: 'Play',
      },
      PRAYER: {
        BUTTON: 'Make a prayer request',
        TEXT: 'Sincere prayer is the purest way to converse with God. Open your heart, share your prayer request and pray for others on our virtual wall.',
        TITLE: 'Prayer Wall',
      },
      SCHEDULE: {
        BUTTON: 'See live streams',
        CARD: {
          VIEW_LESS: 'View less',
          VIEW_MORE: 'View more',
        },
        DAYS: {
          FRIDAY: 'Friday',
          MONDAY: 'Monday',
          SATURDAY: 'Saturday',
          SUNDAY: 'Sunday',
          THURSDAY: 'Thursday',
          TUESDAY: 'Tuesday',
          WEDNESDAY: 'Wednesday',
        },
        SUBTITLE: 'Schedule',
        TEXT: 'Check out our in-person agenda and also follow the live stream from wherever you are using the internet. See the schedule below:',
        TITLE: 'With you anywhere',
      },
      TITLE: {
        PAGE: {
          HOME: 'Home',
        },
      },
    },
    INPEACE_PLAY: {
      BROWSE: {
        MESSAGE: {
          NO_CONTENT: 'There is no content to display yet',
        },
      },
      BUTTON: {
        ADD_MY_LIST: 'My list',
      },
      CHECKOUT: {
        BUTTON: {
          GO_TO_CONTENT: 'Watch the videos',
          PAYMENT: {
            CONFIRM: 'Confirm',
          },
          THANK_YOU: {
            DOWNLOAD_BOLETO: 'See payment slip',
            OPEN_PICPAY: 'Open PicPay',
            VIEW_MORE: 'See more events / courses',
          },
        },
        TEXT: {
          PAYMENT: {
            METHODS: {
              BOLETO: 'You will receive the bank slip by e-mail and your subscription will be effective after payment confirmation.',
              FREE: 'This subscription is free, you will have access to all the contents included in it at no cost.',
              PICPAY: {
                RULE_MESSAGE: 'Confirm the button below, download or access your PicPay app and complete your donation.',
              },
              PIX: {
                RULE_MESSAGE: 'Confirm using the button below to proceed with your payment.',
              },
            },
            PAYMENT: 'Payment',
            PLAN: 'Plan',
            PRICE: 'Price',
            SUBSCRIPTION_FEE: '+ {value} (hiring fee)',
            TOTAL: 'Total',
          },
          THANK_YOU: {
            DATA: {
              PLAN: 'Plan',
              STATUS: 'Situation',
              SUBSCRIBED: 'Signature',
            },
            METHODS: {
              BOLETO: {
                em_analise: 'You can access the bank slip at the link below, but you will also receive it by email and your subscription will be effective after payment confirmation.',
                pendente: 'You can access the bank slip at the link below, but you will also receive it by email and your subscription will be effective after payment confirmation.',
              },
              CREDIT_CARD: {
                aprovado: 'Your subscription was successful.',
                em_aberto: 'Registration is being processed. You will receive a confirmation email and soon your tickets will be available on our platform.',
                em_analise: 'Registration is being processed. You will receive a confirmation email and soon your tickets will be available on our platform.',
                pendente: 'Registration is being processed. You will receive a confirmation email and soon your tickets will be available on our platform.',
              },
              FREE: {
                SUCCESS: 'Your subscription was successful.',
              },
              PICPAY: {
                aguardando: 'Click below to open PicPay and complete your subscription.',
              },
            },
            TITLE: 'Thanks',
          },
        },
      },
      COMMON: {
        COLLECTIONS: 'Collections',
        CONTENT_POSTER: {
          COMING_SOON: 'Coming soon',
        },
        HOME: 'Home',
        MY_LIST: 'My list',
        QUERY: 'SEARCH RESULT:',
        RELATED_CONTENT: {
          TITLE: 'RELATED',
        },
        TAG: 'Tag: ',
        VIDEOS: 'Videos',
      },
      CONTENT_ACTIONS: {
        BUTTON: {
          SUBSCRIBE_NOW: 'Subscribe now',
          WATCH: 'Watch',
        },
      },
      HOME: {
        CLOSED_CONTENT: {
          BUTTON: {
            SUBSCRIBE_NOW: 'Subscribe now',
          },
        },
      },
      MODULE_NAME: 'Play',
      PLANS: {
        MESSAGE: {
          ERROR: 'There was an error searching the information',
          NO_CONTENT: 'There are no plans available at the moment',
        },
        MY_PLANS: {
          PLAN: 'Plan',
          SUBSCRIBED_AT: 'Signature date',
          SUBSCRIPTION_CODE: 'Signature code',
          TITLE: 'My plan',
        },
        PLANS_LIST: {
          BUTTON_NEXT: 'Subscribe now',
          FEES: 'Fees',
          PRICE: 'Price',
          TITLE: 'Choose your plan',
        },
        TITLE: 'Plans',
      },
      SEARCH: {
        MESSAGE: {
          INVALID_SEARCH: 'We couldn\'t find any results for your search',
          NO_CONTENT: 'We couldn\'t find any results for your search',
          REQUEST_ERROR: 'There was an error searching the information',
        },
      },
      SINGLE: {
        MESSAGE: {
          NO_COLLECTION_CONTENT: 'There is no content to display yet',
          NO_COLLECTION_ID: 'There was an error, please try again',
          NO_CONTENT: 'The content you were looking for was not found',
          REQUEST_ERROR: 'There was an error while trying to search information, please try again',
        },
        TEXT: {
          TAGS: 'Tags',
          VIDEOS: 'Videos',
        },
      },
      WATCH: {
        MESSAGE: {
          COMING_SOON: 'Content unavailable',
          NO_CONTENT: 'Content not found or unavailable',
          REQUEST_ERROR: 'There was an error searching the information, please try again',
        },
      },
    },
    LIVE: {
      BUTTON: {
        BIBLE: 'Bible',
        DONATE_NOW: 'Give now',
        GIVE: 'Givings',
        LIVE_CHAT: 'Chat',
        NOTE: 'Notes',
        UPCOMING_STREAMS: 'Upcoming live stream',
      },
      MESSAGE: {
        ERROR: {
          GENERIC: 'Something went wrong. Please refresh the page and try again.',
          NO_MODULE: '{0} has no live stream.',
        },
      },
      TEXT: {
        GIVE: {
          REDIRECT: 'You will proceed to the giving page',
          REDIRECT_MESSAGE: 'In order not to interrupt the live stream, go to the giving option on the homepage at any time',
        },
        NO_WORSHIP_LIVE: 'Soon we will have our first live stream',
        NO_WORSHIP_SCHEDULE: 'No live streams scheduled yet',
        NOT_BROADCASTED: '{0} hasn\'t broadcasted live yet.',
        SHARE: {
          LIVE: '{0}',
          UPCOMING: '{0} - {1}',
        },
        TAG_STATUS: {
          LAST: 'Last Streaming',
          LIVE: 'We are Live',
        },
      },
      TITLE: {
        PAGE: {
          HOME: 'Programing',
        },
      },
    },
    LOGIN: {
      BUTTON: {
        LOGIN: 'Login',
        LOST_PASSWORD: 'I forgot my password',
        REGISTER: 'STILL DOES NOT HAVE A ACCOUNT? REGISTER NOW',
      },
      FORM: {
        MESSAGE: {
          ERROR: 'Username or password is invalid.',
        },
      },
      SUBTITLE: 'Login to your account',
      TITLE: {
        INTERNAL: {
          LOGIN: 'Login',
          MUST_LOGIN: 'You must be logged in to proceed',
        },
        PAGE: {
          HOME: 'Login',
        },
      },
    },
    MEMBER_CARD: {
      CARD: {
        AVATAR: '{memberFullName} photo.',
        BAPTISM_DATE: 'Date of Baptism',
        BIRTH_DATE: 'Date of Birth',
        CONGREGATION: 'Congregation',
        CPF: 'ID Number',
        FEMALE: 'Female',
        FOOTER: 'If in doubt, please contact us',
        FULL_NAME: '{firstName} {lastName}',
        GENDER: 'Sex',
        LINKED: 'Linked to',
        LOGO: '{churchName} church logo',
        MALE: 'Male',
        MARITAL_STATUS: 'Marital status',
        NAME: 'Name',
        PHONE: 'Phone number',
        POSITION: 'Activity',
        QR_CODE: '{memberFullName} QR code',
        RESPONSIBLE: 'Pastor',
        WIDOWED: 'Widowed',
      },
      PRINT: {
        ACTION: 'Print ',
        ERROR_MESSAGE: 'No card was found for this email.',
        NOT_FOUND_CARD: 'It was not possible to obtain the card details.',
      },
    },
    MINISTRIES: {
      CARD: {
        COORDINATION: 'Coordination',
      },
      EMPTY: {
        BUTTON: 'Back to start',
        SUBTITLE: 'When a ministry is created you will be able to view the information.',
        TITLE: 'There are no ministries here yet',
      },
      INDEX: {
        FILTERS: {
          INPUT: 'Search by title or keyword',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
            SEARCH_DESCRIPTION: 'How about trying another title or keyword?',
            SEARCH_TITLE: 'There are no results for this search',
          },
        },
      },
      MODULE_NAME: 'Coordination',
      SINGLE: {
        INFO: {
          PROJECT: 'Social projects',
          RESPONSIBLE: 'Responsible',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'An error occurred while fetching the data. Please try again',
            NOT_FOUND: 'Content does not exist or is unavailable.',
          },
        },
      },
    },
    NEWS: {
      EMPTY: {
        BUTTON: 'Back to start',
        SUBTITLE: 'When content is published, you can view it here.',
        TITLE: 'No posts here yet',
      },
      INDEX: {
        FILTERS: {
          INPUT: 'Search by title or keyword',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
            SEARCH_DESCRIPTION: 'How about trying another title or keyword?',
            SEARCH_TITLE: 'There are no results for this search.',
          },
        },
      },
      MODULE_NAME: 'News',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'An error occurred while fetching the data. Please try again',
            NOT_FOUND: 'Content does not exist or is unavailable.',
          },
        },
      },
    },
    ORGANIZATION_HEADER: {
      BAR: {
        CHANGE: 'Change',
      },
      MODAL: {
        CLIENT_INFO: {
          BACK: 'Cancel',
          CONFIRM: 'Confirm',
        },
        CLIENTS: {
          NO_CLIENTS: 'We were unable to locate churches in that organization.',
          NO_RESULT: 'We have not found any churches for your search',
          PLACEHOLDER: 'Search church by name or address',
          SELECT_CLIENT: 'Select a church',
        },
        LOCATION: {
          ALLOW: 'Allow location',
          ENTRY: 'Allow your location to find the churches closest to you',
          NOT_NOW: 'Not now',
        },
      },
    },
    PLAYER: {
      AUDIO: {
        CONTROLS: {
          NEXT: 'Next',
          PAUSE: 'Stop',
          PLAY: 'Play',
          PREV: 'Previous',
          REPEAT: 'Repeat',
          SHUFFLE: 'Random',
        },
      },
    },
    PODCAST: {
      EMPTY: {
        BUTTON: 'Back to start',
        SUBTITLE: 'When content is published, you can view it here.',
        TITLE: 'No podcasts here yet',
      },
      INDEX: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
            SEARCH_DESCRIPTION: 'How about trying another title or keyword?',
            SEARCH_TITLE: 'There are no results for this search',
          },
          VIEW_MORE: 'View more',
        },
        TEXT: {
          EPISODES: 'Episodes',
          PUBLISHED: 'Published:',
          QUEUE: 'Queue',
          SHARE: 'SHARE',
        },
      },
      MODULE_NAME: 'Podcast',
    },
    PRAISE: {
      CHORD: {
        TONE: 'Tone',
      },
      CONTROLS: {
        AUTOMATIC_SCROLLING: 'Automatic scrolling',
        FONT: 'Font',
      },
      ERROR: {
        ERROR_MESSAGE: 'Couldn\'t find information for this song.',
      },
      SCORE: {
        PAGE: 'Page {pageNumber} of Sheet Music',
      },
      SHOW_TYPES: {
        CIPHER: 'Cipher',
        LYRIC: 'Words',
        SCORE: '',
      },
    },
    PRAYER: {
      DELETE: {
        BUTTON: 'Delete request',
        CANCEL: 'Return to the beginning',
        SUBTITLE: 'Deleting a request cannot be reversed.',
        SUCCESS: {
          BUTTON: 'Make a prayer request',
          BUTTON_RETURN: 'Return to the beginning',
          SUBTITLE: 'Do you want to place a new order?',
          TITLE: 'Your order has been successfully deleted',
        },
        TITLE: 'Do you really want to delete your request?',
      },
      INDEX: {
        BUTTONS: {
          REQUEST: 'Place my order',
          SEND: 'Confirm',
          SEND_MODAL: 'Send',
        },
        EMPTY: {
          BUTTON: 'Make a request',
          SUBTITLE: 'There are no requests posted',
          TEXT: 'When a request is posted on the wall it will appear here.',
          TITLE: 'Prayer request',
        },
        FILTERS: {
          DROPDOWN: 'Filter by reason',
          INPUT: 'Search by author name or message',
        },
        MESSAGE: {
          BACK_TOP: 'Back to the top',
          EMPTY: 'There are still no prayers.',
          ERROR: {
            GENERIC: 'No content found.',
            SEARCH: 'There are no results for this search',
            SEARCH_MESSAGE: 'How about trying with another name or keyword?',
          },
          REQUEST: 'Make a request',
          SEND_MESSAGE: 'Leave your support in this request',
          VIEW_MORE: 'View more',
        },
        TITLE: 'Prayer request',
      },
      MODULE_NAME: 'Prayer',
      NEW: {
        ERROR: 'It was not possible to carry out the prayer request, try again',
        PUBLISH: 'Would you like to have your request posted on the wall?',
        CALL: 'Do you want to receive a call from a member?',
        VISIT: 'Do you want to receive a visit from a member?',
        SELECT: 'Choose the reason',
        SUBTITLE: 'Fill in the fields below and send us your prayer request.',
        SUCCESS: {
          BUTTON: 'Make another request',
          BUTTON_RETURN: 'Return',
          SUBTITLE: 'Your request was sent successfully',
          THANK_YOU: 'Thank you for submitting your request',
          TITLE: 'Place an Order',
        },
        TITLE: 'Request',
      },
      SINGLE: {
        MESSAGE: {
          EMPTY: 'There are no messages yet.',
          ERROR: {
            GENERIC: 'An error occurred while fetching the data. Please try again',
            NOT_FOUND: 'No results found.',
          },
          PRAY: 'Praying',
          SEND: 'Send message',
          TITLE: 'Messages',
        },
      },
    },
    PUBLICATION: {
      EMPTY: {
        BUTTON: 'Back to start',
        SUBTITLE: 'When content is published, you can view it here.',
        TITLE: 'No posts here yet',
      },
      INDEX: {
        FILTERS: {
          DROPDOWN: 'Filter by category',
          INPUT: 'Search by title or keyword',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
            SEARCH_DESCRIPTION: 'How about trying another title or keyword?',
            SEARCH_TITLE: 'There are no results for this search',
          },
        },
        TEXT: {
          SELECT_TYPE: 'Select the type of publication you want to access:',
        },
      },
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'An error occurred while fetching the data.  Please try again.',
            NOT_FOUND: 'Content does not exist or is unavailable.',
          },
        },
      },
      MODULE_NAME: 'Publications',
    },
    PUBLICATION_TYPE: {
      EMPTY: {
        BUTTON: 'Back to Home',
        SUBTITLE: 'When new ones are added, you can view them here.',
        TITLE: 'There are no types of publications registered yet',
      },
    },
    RECAPTCHA: {
      TEXT: 'Invalid captcha. Please try again.',
    },
    REGISTER: {
      BUTTON: {
        SEND: 'Confirm Register',
      },
      PASSWORD_MESSAGEM: {
        CONFIRMED: 'Passwords must be the same',
      },
      PASSWORD_REQUIREMENTS: {
        REQUIREMENT_1: 'Minimum 8 characters ',
        REQUIREMENT_2: 'Uppercase and lowercase letter',
        REQUIREMENT_3: 'Number',
        REQUIREMENT_4: 'Special Characteristics (Ex: !@#$%*)',
        TITLE: 'Password Requirements:',
      },
      SUBTITLE: 'Register',
      TITLE: {
        INTERNAL: {
          FILL_INFO: 'Fill in the information to register',
          REGISTER: 'Register',
        },
        PAGE: {
          HOME: 'Register',
        },
      },
      TOOLTIP: {
        PASSWORD_HIDE: 'Password hide',
        PASSWORD_SHOW: 'Password show',
      },
    },
    REQUEST_LOGIN: {
      BUTTONS: {
        CANCEL: 'Cancel',
        CONFIRM: 'Login / Register',
      },
      MESSAGE: {
        LOGIN: 'Login to your account or register to continue.',
      },
    },
    RESET_PASSWORD: {
      BUTTON: {
        SEND: 'Recover',
      },
      FORM: {
        MESSAGE: {
          ERROR: {
            GENERIC_ERROR: 'An error occurred while trying to send the data. Try again.',
            NOT_FOUND: 'Email not found.',
          },
          SUCCESS: 'New password sent to your email.',
        },
      },
      TITLE: {
        INTERNAL: {
          ENTER_EMAIL: 'Enter your email to recover your password',
          RECOVER_PASSWORD: 'Recover Password',
        },
        PAGE: {
          HOME: 'Lost Password',
        },
      },
    },
    USER: {
      ACCOUNT: {
        CHURCH: {
          CHANGE: 'Change',
        },
        PROFILE: {
          LINKS: {
            CREDIT_CARD: {
              SUBTITLE: 'Add or delete cards',
              TITLE: 'Cards',
            },
            DELETE_USER: 'Delete my account',
            EVENTS_INSCRIPTIONS: {
              SUBTITLE: 'View ticket information',
              TITLE: 'Registrations',
            },
            LOGOUT: 'Exit',
            MY_ACCOUNT: {
              LABEL_INCOMPLETE: 'INCOMPLETE',
              SUBTITLE: 'Confirm, add or change your registered data',
              TITLE: 'Registration data',
            },
            NOTES: {
              SUBTITLE: 'View, create or delete your notes',
              TITLE: 'Notes',
            },
            PRAYER: {
              SUBTITLE: 'View, share or delete your orders',
              TITLE: 'Wall Orders',
            },
            SUBSCRIPTIONS: {
              TITLE: 'Play',
            },
          },
          TITLE: {
            ENTRY: 'My account',
            PAGE: 'My account',
          },
        },
        UPDATE: {
          BUTTON: {
            SEND: 'Finish my registration',
          },
          TITLE: {
            INTERNAL: {
              FINISH_REGISTRATION: 'Finish your registration to proceed',
              UPDATE: 'My Account',
            },
            PAGE: 'My Account',
          },
        },
        UPDATE_PASSWORD: {
          BACK: 'Return',
          INPUTS: {
            LABEL: {
              NEW_PASSWORD: 'New password',
              PASSWORD: 'Current password',
              PASSWORD_CONFIRMATION: 'Confirm new password',
            },
            PLACEHOLDER: {
              NEW_PASSWORD: 'Enter new password',
              PASSWORD: 'Enter current password',
              PASSWORD_CONFIRMATION: 'Confirm your new password ',
            },
          },
          INVALID: 'Your password is incorrect. Please check password and try again.',
          SUCCESS: {
            SUBTITLE: 'Use your new password to enter on your next access.',
            TITLE: 'Your password was successfully changed',
          },
          TITLE: 'Change password',
        },
      },
      CREDIT_CARD: {
        DELETE: {
          BUTTON: 'Delete Card',
          CANCEL: 'Cancel',
          SUBTITLE: 'In order to use this card, you will need to add card again.',
          TITLE: 'Do you want to delete card ending in {number} ?',
        },
        NEW_CARD: {
          BUTTON: 'Register',
          SUBTITLE: 'Card Information',
          TITLE: 'Add Card',
          ERROR: 'Please provide a valid credit card number to complete the payment. Make sure all the details are correct, and that the card is still within its expiration date.',
        },
        NOT_FOUND: {
          BUTTON: 'Add card',
          SUBTITLE: 'Add a card to start transaction.',
          TITLE: 'No cards registered ',
        },
        TITLE: {
          INTERNAL: 'Check the registered cards',
          PAGE: 'Cards',
        },
      },
      DELETE_USER: {
        BUTTON: 'Delete my account',
        COMPLEMENT: 'Furthermore, you will also irreversibly lose your data history.',
        CONFIRM: {
          BUTTON: 'Delete my account',
          DESCRIPTION: 'Please enter your password to complete your account cancelation request:',
          ERROR: 'Your password is incorrect. Please check password and try again.',
          INPUT: 'Password',
          INPUT_PLACEHOLDER: 'Type your password',
        },
        DESCRIPTION: 'When you delete your account, you will have to create a new one if you want to use all the features of the application again.',
        MODULE_NAME: 'Delete my account',
        SUCCESS: {
          BUTTON: 'I understand',
          COMPLEMENT: 'In addition, if you want to return to access all the features of the application, you must register again.',
          DESCRIPTION: 'You will receive information about deleting your account in your email.',
          TITLE: 'Deletion of your account has been requested.',
        },
        TITLE: 'Do you really want to delete your account?',
      },
      EVENT: {
        SUBSCRIBED: {
          BTN: {
            VIEW_TICKET: 'View ticket',
          },
          NOT_FOUND: {
            BUTTON: 'See events',
            SUBTITLE: 'Ticket will be available after registering in event.',
            TITLE: 'No subscription available',
          },
          TEXT: {
            PAGE_MESSAGE: 'Select an subscriptions to see the details',
          },
          TITLE: {
            INTERNAL: 'Subscriptions',
            PAGE: 'My subscriptions',
          },
        },
        TICKETS: {
          BTN: {
            COMING_SOON: 'Available soon',
            EXPIRED: 'Expired Content',
            VIEW_CONTENT: 'See content',
          },
          CONTENT: {
            ERROR: {
              NO_CONTENT: 'No content found',
            },
            SINGLE: {
              TEXT: {
                CHAT: 'Chat',
                NOT_FOUND: 'Content not available or cannot be found.',
              },
            },
            TEXT: {
              CHAT: 'Chat',
              CHECK_THE_CONTENT: 'See content information',
              NO_CONTENT: 'There is no content to display at this moment.',
            },
            TITLE: {
              INTERNAL: 'Select the content you want to view',
              PAGE: 'Contents',
            },
          },
          ERROR: {
            TICKETS_NOT_FOUND: 'No tickets found for this event',
          },
          TEXT: {
            EXCLUSIVE_CONTENT: 'Ticket with exclusive content',
            PAGE_MESSAGE: 'Ticket Information',
            START_FINISH_TIME: '{start} to {finish}',
          },
          TICKET_FIELD: {
            BUYER: 'Buyer',
            DATE: 'Date',
            EVENT_NAME: 'Event name',
            TICKET: 'Ticket',
            TICKETS: 'Tickets',
            TIME: 'Schedule',
            USER: 'Username',
          },
          TITLE: {
            INTERNAL: 'My tickets',
            PAGE: 'My tickets',
          },
        },
      },
      NOTES: {
        BUTTON: {
          BACK: 'Back to list',
          BACK_TOP: 'Back to the top',
          CANCEL: 'Cancel',
          DELETE: 'Delete',
          DELETE_NOTE: 'Delete note',
          NEW: 'New note',
          SAVE: 'Save',
          SAVE_NOTE: 'Save note',
          VIEW_MORE: 'View more',
        },
        DATE: {
          MESSAGE: 'Last change made on {date} at {hour}',
        },
        DELETE: {
          DESCRIPTION: 'By choosing to delete, this action will be irreversible.',
          MODAL: {
            BUTTON_OK: 'OK',
            DESCRIPTION: 'Please, try again.',
            TITLE: 'An error occurred while trying to delete this annotation',
          },
          TITLE: 'Are you sure you want to delete this note?',
        },
        EMPTY: {
          DESCRIPTION: 'Create your first note here',
          TITLE: 'There are not any notes here yet',
        },
        LIVE: {
          BUTTON: 'Login / Register',
          DESCRIPTION: 'To take notes, log into your account or register.',
        },
        MESSAGE: {
          ERROR: {
            GENERIC: 'Unable to load the information.',
          },
        },
        MODAL: {
          BUTTON_CANCEL: 'Cancel',
          BUTTON_CONFIRM: 'Understand',
          BUTTON_CONTINUE: 'Continue editing',
          BUTTON_DELETE: 'Delete note',
          BUTTON_NEW: 'New note',
          BUTTON_OK: 'OK',
          DESCRIPTION_NOT_CONTENT: 'When you exit, your note will be deleted since it has no content.',
          DESCRIPTION_NOT_TITLE: 'To save your notes it is neccessary to create a title.',
          DESCRIPTION_SAVE_NOTE: 'You can now choose to create a new note or continue editing this note',
          TITLE_NOT_CONTENT: 'Do you really want to abandon the note?',
          TITLE_NOT_TITLE: 'Note without a title',
          TITLE_SAVE_NOTE: 'Your note has been saved successfully',
        },
        PLACEHOLDER: {
          TEXT: 'Start writing…',
          TITLE: 'Note title',
        },
        TITLE: {
          PAGE: 'Notes',
          TEXT: 'Check all the notes you have already made',
        },
      },
      PAYMENT: {
        STATUS: {
          BUTTON: {
            BACK_HOME: 'Back to home',
          },
          TEXT: {
            STATUS: {
              CANCELED: 'Your <span class="text--featured">{value}</span> payment for the <span class="text--featured">{church}</span> has been canceled.',
              NOT_FOUND: 'There is no payment record for the code entered.',
              REFUND: 'Your <span class="text--featured">{value}</span> payment to <span class="text--featured">{church}</span> has been refunded.',
              REVIEW: 'Your <span class="text--featured">{value}</span> payment to <span class="text--featured">{church}</span> is under review.',
              SUCCESS: '<span class="text--featured">{church}</span> has successfully received <span class="text--featured">{value}</span> for the <span class="text--featured">{destination}</span>.',
              WAITING: 'Your <span class="text--featured">{value}</span> payment to <span class="text--featured">{church}</span> is awaiting payment.',
            },
            THANK_YOU: 'Thank You',
          },
          TITLE: {
            PAGE: 'Payment Status',
          },
        },
      },
      PRAYER: {
        INDEX: {
          EMPTY: {
            BUTTON: 'Make a request',
            SUBTITLE: 'No prayer requests yet',
            TEXT: 'When you make a request on the wall it will appear here',
          },
          MESSAGE: {
            BACK_TOP: 'Back to the top',
            // EMPTY: 'There are still no prayers.',
            ERROR: {
              GENERIC: 'No content found.',
              SEARCH: 'There are no results for this search',
              SEARCH_MESSAGE: 'How about trying with another name or keyword?',
            },
            REQUEST: 'Make a request',
            SEND_MESSAGE: 'Leave your support in this request',
            VIEW_MORE: 'View more',
          },
        },
        TITLE: {
          INTERNAL: 'Check all your requests here',
          PAGE: 'Prayer wall',
        },
      },
      USER_DATA: {
        BUTTON: {
          EDIT: 'Edit',
          NEXT: 'Save changes',
          ADD: 'Add',
        },
        MESSAGE: {
          ERROR: 'It was not possible to update the information, please try again.',
          SUCCESS: 'Your personal information has been successfully updated.',
        },
        TITLE: {
          INTERNAL: 'YOUR PERSONAL INFORMATION',
          PAGE: 'Updade user data',
        },
        CHANGE_CHURCH: {
          ALERT: '<b>WARNING:</b> Your registration will be linked to the selected church.',
          MODAL: {
            TITLE: 'You are changing your registration to ',
            SUBTITLE: 'Your account will be canceled the new selected church. <p>Want to confirm ?</p>',
            BACK: 'Not now',
          },
        },
        CHILDREN: {
          TITLE: 'Child Registration',
        },
        ADDRESS: {
          TITLE: 'Address Registration',
        },
      },
    },
    VIDEOS: {
      INDEX: {
        EMPTY: {
          BUTTON: 'Back to start',
          SUBTITLE: 'When content is created, you can view it here.',
          TITLE: 'No {videos} here yet',
        },
        FILTER: 'Search by name or date',
        MESSAGE: {
          DATE: 'Published:',
          ERROR: {
            GENERIC: 'No content found.',
            SEARCH: 'There are no results for this search',
            SEARCH_MESSAGE: 'How about trying another title or keyword?',
          },
        },
        TITLE: 'Videos',
      },
      MODULE_NAME: 'VIDEOS',
      SINGLE: {
        MESSAGE: {
          ERROR: {
            GENERIC: 'An error occurred while fetching the data. Please try again',
            NOT_FOUND: 'Content does not exist or is unavailable.',
          },
        },
        PLAYLIST: 'Playlist',
      },
    },
    BIBLE: {
      MODULE_NAME: 'Bible',
    },
    VOLUTS: {
      TITLE: 'Voluts',
      DESCRIPTION: 'Click the button below to access Voluts',
      BUTTON: 'Access',
    },
    MY_KIDS: {
      TITLE: 'MyKids',
      DESCRIPTION: 'Click the button below to access MyKids',
      BUTTON: 'Access',
    },
  },
  VALIDATION: {
    birthdate: 'Invalid date of birth',
    cpf: 'Invalid document number',
    date: 'Invalid date format',
  },
};
