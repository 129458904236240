import axios from 'axios';

// --- Config

// Common
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

// --- Instances

// InPeace API V1
const inpeaceAPI = axios.create({
  baseURL: process.env.VUE_APP_API_V1,
  // timeout: 30000,
});

// InPeace API BFF
const inpeaceBFF = axios.create({
  baseURL: `${process.env.VUE_APP_ADMIN_URL}/bff`,
  // timeout: 30000,
});

// ViaCep API
const viaCepAPI = axios.create({
  baseURL: 'https://viacep.com.br/ws/',
});

// ViaCep API
const zippopotamAPI = axios.create({
  baseURL: 'http://api.zippopotam.us/',
});

// CountryState API
const countryStateAPI = axios.create({
  baseURL: 'https://api.countrystatecity.in/v1/countries/',
  headers: {
    'X-CSCAPI-KEY': process.env.VUE_APP_COUNTRY_CITY_API_KEY,
  },
});

// Realtime API
const realtimeAPI = axios.create({
  baseURL: process.env.VUE_APP_PUSHER_API,
  headers: {
    common: {
      Authorization: `Bearer ${process.env.VUE_APP_PUSHER_API_BEARER}`,
    },
  },
});

// --- Export
export {
  inpeaceAPI,
  inpeaceBFF,
  realtimeAPI,
  viaCepAPI,
  countryStateAPI,
  zippopotamAPI,
};
